import { useRoutes } from "react-router-dom";

// ==============================|| ROUTES ||============================== //

import LandingPageRoutes from "./login_route.js";
import DataRoutes from "./data_routes.js";
import SundriesRoutes from "./sundries_service_routes.js";
import StreakRoutes from "./streak_service_routes.js";
import PurchaseRoutes from "./purchase_service_routes.js";
import RedeemRoutes from "./redeem_service_routes.js";
import PointRoutes from "./point_service_routes.js";
import DashboardRoutes from "./dashboard_service_routes.js";
import AuthRoutes from "./auth_service_routes.js";
import ChallengesRoutes from "./challenge_service_routes.js";
import GameRoutes from "./games_routes.js";

// ==============================|| ROUTING RENDER ||============================== //

export default function Routes() {
    return useRoutes([
        LandingPageRoutes,
        DataRoutes,
        AuthRoutes,
        ChallengesRoutes,
        DashboardRoutes,
        PointRoutes,
        RedeemRoutes,
        PurchaseRoutes,
        StreakRoutes,
        SundriesRoutes,
        GameRoutes,
    ]);
}
