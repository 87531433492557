import { useState, useEffect } from "react";
import classes from "../../Challenge.module.css";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import AUTH_CONTEXT from "../../../../store";
import Card from "../../../../components/Card/Card";
import Loader from "../../../../components/Loader/Loader";
import Button from "../../../../components/Button/Button";
import { BASE_PATH_TO_SOLO_CHALLENGE_MILESTONES_LIST } from "../../../../base_routes_paths/challenge_paths";
import { SoloChallengeMilestoneServices } from "../../../../services/challenge_services";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";

const UpdateSoloChallengeRewards = () => {
    const params = useParams();
    const navigate = useNavigate();
    const { token } = AUTH_CONTEXT();
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoaderLoading, setIsLoaderLoading] = useState(false);
    const [level, setLevel] = useState("");
    const [rewards, setRewards] = useState([]);

    const FetchResponseGetChallengeDetailsByID = async () => {
        try {
            let response = await SoloChallengeMilestoneServices.getAllSoloChallengeMilestone(token, navigate, params.ID);
            if (response.status === 207) {
                return toast
                    .error(response.error, {
                        position: "bottom-left",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                    })
            }
            setIsDataFetched(true);
            setIsLoaderLoading(false);
            return response.data;
        } catch (e) {
            setIsDataFetched(false);
            console.log(e.message);
            alert(e.message);
        }
    };

    useEffect(() => {
        async function FetchData() {
            try {
                if (!isDataFetched) {
                    let result = await FetchResponseGetChallengeDetailsByID();
                    setLevel(result.Level);
                    setRewards(result.Rewards);
                }
            } catch (error) {
                console.log(error.message);
            }
        }

        if (params.ID) {
            setIsLoaderLoading(true);
            FetchData();
        } else {
            return;
        }
    }, []);

    const handleFormChangeRewards = (index, event) => {
        let data = [...rewards];
        if (event.target.name === "IsActive") {
            data[index][event.target.name] = event.target.value === "true";
        } else {
            data[index][event.target.name] = parseInt(event.target.value);
        }
        setRewards(data);
    };

    const addFieldsRewards = (event) => {
        event.preventDefault();
        let newfield = { CutOffNumber: "", Amount: "", IsActive: true };
        setRewards([...rewards, newfield]);
    };

    const removeFieldsRewards = (index) => {
        let data = [...rewards];
        data.splice(index, 1);
        setRewards(data);
    };

    const SubmitHandler = (e) => {
        e.preventDefault();
        setIsLoading(true);

        const Data = {
            _id: params.ID,
            Data: {
                Level: level,
                Rewards: rewards,
            },
        }
        const FetchResponseSetReferrelConfig = async () => {
            try {
                let response = await SoloChallengeMilestoneServices.updateSoloChallengeMilestone(token, navigate, Data);

                setIsLoading(false);
                if (response.status === 207) {
                    return toast
                        .error(response.error, {
                            position: "bottom-left",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                        })
                }
                toast.success("Data updated successfully!!", {
                    position: "bottom-left",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate(BASE_PATH_TO_SOLO_CHALLENGE_MILESTONES_LIST);
            } catch (e) {
                setIsLoading(false);
                toast.error(e.message, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        };
        FetchResponseSetReferrelConfig();
    };

    return (
        <>
            {isLoaderLoading === true ? (
                <Loader />
            ) : (
                <div className={classes.crumbs}>
                    <Breadcrumbs aria-label="breadcrumb" >
                        <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                            Dashboard
                        </Link>
                        <Typography color="text.primary">
                            Challenege
                        </Typography>
                        <Typography color="text.primary">Update Solo Challenge Milestone</Typography>
                    </Breadcrumbs>
                    <Card className={classes.input}>
                        <div className={classes.Main_div}>
                            Update Solo Challenge Milestone
                        </div>
                        <form onSubmit={SubmitHandler}>
                            <div className={classes.selectbox}>
                                <label htmlFor="Level">Level :</label>
                                <input
                                    type="number"
                                    id="Level"
                                    required
                                    value={level}
                                    onChange={(e) => setLevel(parseInt(e.target.value))}
                                    placeholder="Enter level"
                                />
                            </div>

                            <div className={classes.subfields}>
                                <h3>Rewards</h3>
                                {rewards.map((input, index) => {
                                    return (
                                        <>
                                            <div key={index} className={classes.subfieldsControl}>
                                                <div className={classes.control}>
                                                    <label htmlFor="CutOffNumber">Milestone Number</label>
                                                    <input
                                                        type="text"
                                                        name="CutOffNumber"
                                                        placeholder="Enter Milestone Number"
                                                        value={input.CutOffNumber}
                                                        onChange={(event) => handleFormChangeRewards(index, event)}
                                                    />
                                                </div>
                                                <div className={classes.control}>
                                                    <label htmlFor="Amount">Amount Percentage</label>
                                                    <input
                                                        type="text"
                                                        name="Amount"
                                                        placeholder="Enter Amount Percentage"
                                                        value={input.Amount}
                                                        onChange={(event) => handleFormChangeRewards(index, event)}
                                                    />
                                                </div>
                                                <div className={classes.selectbox}>
                                                    <label htmlFor="IsActive">IsActive : </label>
                                                    <select
                                                        name="IsActive"
                                                        id="IsActive"
                                                        value={input.IsActive}
                                                        onChange={(event) => handleFormChangeRewards(index, event)}
                                                    >
                                                        <option value="false">No</option>
                                                        <option value="true">Yes</option>
                                                    </select>
                                                </div>

                                            </div>
                                            <button
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    removeFieldsRewards(index);
                                                }}
                                            >
                                                Remove
                                            </button>
                                        </>
                                    );
                                })}
                                <button onClick={addFieldsRewards}>Add More...</button>
                            </div>
                            {!isLoading && <Button type="submit">Update</Button>}
                            {isLoading && <p color="white">Sending request...</p>}
                        </form>
                    </Card>
                </div>
            )}
        </>
    );
};

export default UpdateSoloChallengeRewards;
