import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import classes from "../../Redeem.module.css";
import AUTH_CONTEXT from "../../../../store";
import Card from "../../../../components/Card/Card";
import Button from "../../../../components/Button/Button";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { AFAServices } from "../../../../services/redeem_services";
import { BASE_PATH_TO_AFA_LIST } from "../../../../base_routes_paths/redeem_paths";

const AddAFA = () => {
    const navigate = useNavigate();
    const [data, setData] = useState({
        IsCommunityGoalSectionActive: false,
        IsGameSectionActive: false,
        IsMeditationSectionActive: false,
        IsYogaSectionActive: false
    });
    const { token } = AUTH_CONTEXT();
    const [isLoading, setIsLoading] = useState(false);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setData((prevData) => ({
            ...prevData,
            [name]: value === 'true' ? true : false
        }));
    };

    const submitHandler = (event) => {
        event.preventDefault();
        setIsLoading(true);

        const FetchApi = async () => {
            try {
                let response = await AFAServices.addAFA(token, navigate, data);

                if (response.status === 207) {
                    return toast.error(response.error, {
                        position: "bottom-left",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                    })
                }
                setIsLoading(false);
                toast.success("Data added successfully!!", {
                    position: "bottom-left",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate(BASE_PATH_TO_AFA_LIST);
            } catch (e) {
                setIsLoading(false);
                toast.error(e.message, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        };
        FetchApi();
    };

    return (
        <div className={classes.crumbs}>
            <Breadcrumbs aria-label="breadcrumb" >
                <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                    Dashboard
                </Link>
                <Typography color="text.primary">
                    Redeem
                </Typography>
                <Typography color="text.primary">Add App Feature Available</Typography>
            </Breadcrumbs>
            <Card className={classes.input}>
                <div className={classes.Main_div}>App Feature Available</div>
                <form onSubmit={submitHandler}>
                    <div className={classes.selectbox}>
                        <label htmlFor="IsCommunityGoalSectionActive">IsCommunityGoalSectionActive : </label>
                        <select
                            name="IsCommunityGoalSectionActive"
                            id="IsCommunityGoalSectionActive"
                            value={data.IsCommunityGoalSectionActive}
                            onChange={handleInputChange}
                        >
                            <option value={false}>False</option>
                            <option value={true}>True</option>
                        </select>
                    </div>
                    <div className={classes.selectbox}>
                        <label htmlFor="IsGameSectionActive">IsGameSectionActive : </label>
                        <select
                            name="IsGameSectionActive"
                            id="IsGameSectionActive"
                            value={data.IsGameSectionActive}
                            onChange={handleInputChange}
                        >
                            <option value={false}>False</option>
                            <option value={true}>True</option>
                        </select>
                    </div>
                    <div className={classes.selectbox}>
                        <label htmlFor="IsMeditationSectionActive">IsMeditationSectionActive : </label>
                        <select
                            name="IsMeditationSectionActive"
                            id="IsMeditationSectionActive"
                            value={data.IsMeditationSectionActive}
                            onChange={handleInputChange}
                        >
                            <option value={false}>False</option>
                            <option value={true}>True</option>
                        </select>
                    </div>
                    <div className={classes.selectbox} style={{ marginBottom: "2rem" }}>
                        <label htmlFor="IsYogaSectionActive">IsYogaSectionActive : </label>
                        <select
                            name="IsYogaSectionActive"
                            id="IsYogaSectionActive"
                            value={data.IsYogaSectionActive}
                            onChange={handleInputChange}
                        >
                            <option value={false}>False</option>
                            <option value={true}>True</option>
                        </select>
                    </div>

                    {!isLoading && <Button type="submit">Add</Button>}
                    {isLoading && <p color="white">Sending request...</p>}
                </form >
            </Card >
        </div >
    );
};

export default AddAFA;
