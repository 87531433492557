import { useState, useEffect } from "react";
import {
  DataGridPremium
} from "@mui/x-data-grid-premium";
import { useNavigate } from "react-router-dom";
import classes from "../../Sundries.module.css";
import { toast } from "react-toastify";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AUTH_CONTEXT from "../../../../store";
import CustomToolbar from "../../../../components/toolbar";
import Loader from "../../../../components/Loader/Loader";
import { BASE_PATH_TO_ADD_ANNOUNCEMENTS, BASE_PATH_TO_UPDATE_ANNOUNCEMENTS } from "../../../../base_routes_paths/sundries_paths";
import { AnnouncementsServices } from "../../../../services/sundries_services";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";

const AnnouncementsList = () => {
  const userColumns = [
    {
      field: "SortID",
      headerName: "SortID",
      width: 80,
    },
    {
      field: "CorporateID",
      headerName: "CorporateID",
      width: 100,
    },
    {
      field: "Country",
      headerName: "Country",
      width: 100,
    },
    {
      field: "Link",
      headerName: "Link",
      width: 400,
      renderCell: (params) => {
        const link = params.row.Link;
        return (
          <>
            <ContentCopyIcon
              sx={{ marginRight: "10px", cursor: "pointer" }}
              onClick={() => {
                navigator.clipboard.writeText(link);
                toast.success("Copied Link!!");
              }}
              titleAccess="Copy"
            />
            <a
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.copyIcon}
            >
              {link}
            </a>
          </>
        );
      },
    },
    {
      field: "WebUrl",
      headerName: "WebUrl",
      width: 400,
      renderCell: (params) => {
        const WebUrl = params.row.WebUrl;
        return (
          <>
            <ContentCopyIcon
              sx={{ marginRight: "10px", cursor: "pointer" }}
              onClick={() => {
                navigator.clipboard.writeText(WebUrl);
                toast.success("Copied WebUrl!!");
              }}
              titleAccess="Copy"
            />
            <a
              href={WebUrl}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.copyIcon}
            >
              {WebUrl}
            </a>
          </>
        );
      },
    },
    {
      field: "TitleHeading",
      headerName: "TitleHeading",
      width: 400,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const TitleHeading = params.row.TitleHeading;

        return (
          <div>
            <table style={{ textAlign: "center" }}>
              <thead>
                <tr>
                  <th style={{ paddingRight: 25 }}>Key</th>
                  <th style={{ paddingRight: 25 }}>Value</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(TitleHeading).map(([key, value]) => (
                  <tr key={key}>
                    <td style={{ paddingRight: 25 }}>{key}</td>
                    <td style={{ paddingRight: 25 }}>{value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );
      },
    },
    {
      field: "Descriptions",
      headerName: "Descriptions",
      width: 400,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const descriptions = params.row.Descriptions;

        return (
          <div>
            <table style={{ textAlign: "center" }}>
              <thead>
                <tr>
                  <th style={{ paddingRight: 25 }}>Key</th>
                  <th style={{ paddingRight: 25 }}>Value</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(descriptions).map(([key, value]) => (
                  <tr key={key}>
                    <td style={{ paddingRight: 25 }}>{key}</td>
                    <td style={{ paddingRight: 25 }}>{value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );
      },
    },
    {
      field: "IsActive",
      headerName: "IsActive",
      width: 80,
    }
  ];
  const navigate = useNavigate();
  const { token, fileName } = AUTH_CONTEXT();
  const EXPORT_FILENAME = `${fileName}_SpecialReferralsList`;

  const [isDataFetched, setIsDataFetched] = useState(false);
  const [data, setData] = useState([]);

  const FetchApi = async () => {
    try {
      let response = await AnnouncementsServices.getAllAnnouncements(token, navigate, null)
      setIsDataFetched(true);

      let result = response.data;

      let input = result.map(({ _id: id, ...result }) => ({
        id,
        ...result,
      }));
      setData(input);
      toast.success("Data fetched successfully!!", {
        position: "bottom-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (e) {
      setIsDataFetched(true);
      toast.error(e.message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  useEffect(() => {
    if (!isDataFetched) {
      FetchApi();
    }
  }, []);

  const handleDelete = (id) => {
    AnnouncementsServices.deleteAnnouncement(id, token);
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className={classes.cellAction}>
            <div>
              <Link
                href={`${BASE_PATH_TO_UPDATE_ANNOUNCEMENTS}/` + params.row.id}
                style={{ textDecoration: "none" }}
              >
                <div className={classes.viewButton}>Update</div>
              </Link>
            </div>
            <div
              className={classes.deleteButton}
              onClick={() => handleDelete(params.row.id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      {isDataFetched && (
        <div className={classes.datatable}>
          <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 1 }}>
            <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
              Dashboard
            </Link>
            <Typography color="text.primary">
              Sundries
            </Typography>
            <Typography color="text.primary">AnnouncementsList</Typography>
          </Breadcrumbs>
          <DataGridPremium
            rows={data}
            columns={userColumns.concat(actionColumn)}
            slots={{
              toolbar: () => (
                <CustomToolbar
                  EXPORT_FILENAME={EXPORT_FILENAME}
                  onClickCreate={() => {
                    navigate(BASE_PATH_TO_ADD_ANNOUNCEMENTS);
                  }}
                  displayCreate={""}
                  onClickUpdate={() => { }}
                  displayUpdate={"none"}
                />
              ),
            }}
            pagination
            initialState={{
              pagination: {
                paginationModel: { pageSize: 25, page: 0 },
              },
            }}
            disableRowSelectionOnClick
            checkboxSelectionVisibleOnly
            checkboxSelection
            rowHeight={150}
          />
        </div>
      )}
      {!isDataFetched && <Loader />}
    </div>
  );
};

export default AnnouncementsList;
