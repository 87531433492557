import { useState, useEffect } from "react";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { useNavigate } from "react-router-dom";
import classes from "../../Redeem.module.css";
import AUTH_CONTEXT from "../../../../store";
import Loader from "../../../../components/Loader/Loader";
import { toast } from "react-toastify";
import CustomToolbar from "../../../../components/toolbar";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";
import { PaymentInvoiceService } from "../../../../services/redeem_services";
import {
    BASE_PATH_TO_ADD_PAYMENT_INVOICE_CONFIG,
    BASE_PATH_TO_UPDATE_PAYMENT_INVOICE_CONFIG,
} from "../../../../base_routes_paths/redeem_paths";

const InvoicesList = () => {
    const { token, fileName } = AUTH_CONTEXT();
    const EXPORT_FILENAME = `${fileName}_InvoicesList`;

    const invoiceColumns = [
        { field: "UserID", headerName: "User ID", width: 220 },
        { field: "InvoiceNumber", headerName: "Invoice Number", width: 200 },
        { field: "transactionId", headerName: "Transaction ID", width: 150 },
        { field: "SubscriptionID", headerName: "Subscription ID", width: 220 },
        {
            field: "InvoiceUrl",
            headerName: "InvoiceUrl",
            width: 200,
            renderCell: (params) => {
                const link = params.row.InvoiceUrl;
                return (
                    <a href={link} target="_blank" rel="noopener noreferrer">
                        {link}
                    </a>
                );
            },
        },
        { field: "MessageID", headerName: "Message ID", width: 150 },
        { field: "IsSharedViaMail", headerName: "Shared Via Mail", width: 150 },
        { field: "IsNewSub", headerName: "New Subscription", width: 150 },
    ];

    const [isLoading, setIsLoading] = useState(false);
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [data, setData] = useState([]);
    const navigate = useNavigate();

    const FetchResponse = async () => {
        try {
            let response = await PaymentInvoiceService.getInvoices(
                token,
                navigate,
                null
            );
            setIsLoading(true);
            setIsDataFetched(true);
            toast.success("Data fetched successfully!!", {
                position: "bottom-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return response.data;
        } catch (e) {
            setIsLoading(false);
            setIsDataFetched(false);
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    useEffect(() => {
        if (!isDataFetched) {
            FetchResponse()
                .then((result) => {
                    let input = result.map(({ _id: id, ...result }) => ({
                        id,
                        ...result,
                    }));
                    setData(input);
                })
                .catch((err) => console.log(err));
        }
    }, []);

    const handleDelete = (id) => {
        PaymentInvoiceService.deletePaymentInvoice(id, token);
    };

    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: 200,
            renderCell: (params) => {
                return (
                    <div className={classes.cellAction}>
                        <Link
                            href={
                                `${BASE_PATH_TO_UPDATE_PAYMENT_INVOICE_CONFIG}/` + params.row.id
                            }
                            style={{ textDecoration: "none" }}
                        >
                            <div className={classes.viewButton}>Update</div>
                        </Link>
                        <div
                            className={classes.deleteButton}
                            onClick={() => handleDelete(params.row.id)}
                        >
                            Delete
                        </div>
                    </div>
                );
            },
        },
    ];

    return (
        <div className={classes.datatable}>
            <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 1 }}>
                <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                    Dashboard
                </Link>
                <Typography color="text.primary">Redeem</Typography>
                <Typography color="text.primary">Invoice List</Typography>
            </Breadcrumbs>
            {isLoading && (
                <DataGridPremium
                    rows={data}
                    columns={invoiceColumns.concat(actionColumn)}
                    slots={{
                        toolbar: () => (
                            <CustomToolbar
                                EXPORT_FILENAME={EXPORT_FILENAME}
                                onClickCreate={() => {
                                    navigate(BASE_PATH_TO_ADD_PAYMENT_INVOICE_CONFIG);
                                }}
                                displayCreate={""}
                                onClickUpdate={() => { }}
                                displayUpdate={"none"}
                            />
                        ),
                    }}
                    pagination
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 25, page: 0 },
                        },
                    }}
                    disableRowSelectionOnClick
                    checkboxSelectionVisibleOnly
                    checkboxSelection
                />
            )}
            {!isLoading && <Loader />}
        </div>
    );
};

export default InvoicesList;
