import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import classes from "./Auth.module.css";
// import API from "../../Backend";
// import Card from "../UI/Card/Card";
// import Button from "../UI/Button/Button";
import { toast } from "react-toastify";
import { ParseOTP, StatusCode, TokenExpireTime } from "../../utils";
import AUTH_CONTEXT from "../../store";
import Card from "../../components/Card/Card";
import { BASE_PATH_TO_HOME } from "../../base_routes_paths/data_paths";
import Button from "../../components/Button/Button";
import ARENO_API_ENDPOINT from "../../api";

const AuthForm = () => {
    const navigate = useNavigate();
    const OTPInputRef = useRef();
    const MobileNumberInputRef = useRef();
    const { login, isLoggedIn } = AUTH_CONTEXT();
    const [count, setCount] = useState(30);
    const [isOtpSent, setisOtpSent] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (isLoggedIn) {
            navigate(BASE_PATH_TO_HOME)
        }
    })

    const submitHandler = (event) => {
        event.preventDefault();
        let enteredOTP;
        let enteredMobileNumber;
        if (isOtpSent) {
            enteredOTP = ParseOTP(OTPInputRef.current.value);
        } else {
            enteredMobileNumber = MobileNumberInputRef.current.value;
        }

        setIsLoading(true);
        if (!isOtpSent) {
            const FetchResponseToSendOTP = async () => {
                try {
                    let response = await fetch(`${ARENO_API_ENDPOINT}/auth/Authentication`, {
                        method: "POST",
                        body: JSON.stringify({
                            Mobile_Number: enteredMobileNumber,
                            CountryCode: "+91"
                        }),
                        headers: {
                            "Content-Type": "application/json",
                            "isadmin": "true"
                        },
                    });
                    setIsLoading(false);
                    StatusCode(response, navigate);
                    response = await response.json();
                    if (response.status === 207) {
                        return toast.error("Not Authorized!", {
                            position: "bottom-left",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                        })
                    }
                    if (response.status === 200 && response.data.isOtpSent === true) {
                        localStorage.setItem("Mobile_Number", enteredMobileNumber);
                        toast.success("OTP has been sent to the given number!", {
                            position: "bottom-left",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        setisOtpSent(true);

                        const countdownInterval = setInterval(() => {
                            setCount((prevCount) => {
                                if (prevCount === 1) {
                                    clearInterval(countdownInterval);
                                }
                                return prevCount - 1;
                            });
                        }, 1000);
                    } else {
                        throw new Error("Not Authorized!");
                    }
                } catch (e) {
                    setIsLoading(false);
                    toast.error(
                        "Not Authorized!",
                        {
                            position: "bottom-left",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        }
                    );
                }
            };

            FetchResponseToSendOTP();
        } else {
            const currentDateTime = new Date();
            const FetchResponseToVerifyOTP = async () => {
                try {
                    const Mobile_Number = localStorage.getItem("Mobile_Number");
                    let response = await fetch(`${ARENO_API_ENDPOINT}/auth/OTPVerification`, {
                        method: "POST",
                        body: JSON.stringify({
                            Mobile_Number: Mobile_Number,
                            OTP: enteredOTP,
                        }),
                        headers: {
                            "Content-Type": "application/json",
                        },
                    });

                    if (!response.ok) {
                        throw new Error("Inncorrect OTP!");
                    } else {
                        response = await response.json();
                    }
                    if (
                        response.status === 200 &&
                        response.data.isOtpVerifcation === true
                    ) {
                        toast.success("OTP verified!", {
                            position: "bottom-left",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        localStorage.removeItem("Mobile_Number");
                        login(response.data.token, response.data.refreshToken);
                        localStorage.setItem("userName", response.data.UserRecord[0].Name);
                        localStorage.setItem("tokenExpiresIn", TokenExpireTime(currentDateTime, parseInt(response.data.tokenExpiresIn)));
                        localStorage.setItem(
                            "profileImg",
                            response.data.UserRecord[0].ProfileImage
                        );
                        localStorage.setItem(
                            "TimeOffSet",
                            response.data.UserRecord[0].TimeOffSet
                        )
                        setIsLoading(false);
                        navigate(BASE_PATH_TO_HOME);
                        // const FetchResponseToVerifyAdmin = async () => {
                        //   url = `${ARENO_API_ENDPOINT}/auth/VerifyAuthentication`;
                        //   try {
                        //     let resp = await fetch(url, {
                        //       method: "POST",
                        //       headers: {
                        //         "Content-Type": "application/json",
                        //         authorization: `Bearer ${response.data.token}`,
                        //       },
                        //     });
                        //     if (!resp.ok) {
                        //       throw new Error("Inncorrect OTP!");
                        //     } else {
                        //       resp = await resp.json();
                        //     }
                        //     if (
                        //       resp.status === 200 &&
                        //       resp.data.token === response.data.token
                        //     ) {
                        //       toast.success("Admin Verified!", {
                        //         position: "bottom-left",
                        //         autoClose: 2000,
                        //         hideProgressBar: false,
                        //         closeOnClick: true,
                        //         pauseOnHover: true,
                        //         draggable: true,
                        //         progress: undefined,
                        //         theme: "light",
                        //       });
                        //       localStorage.removeItem("Mobile_Number");
                        //     }
                        //     authCtx.login(resp.data.token);
                        //     localStorage.setItem("userName", resp.data.UserRecord[0].Name);
                        //     localStorage.setItem(
                        //       "profileImg",
                        //       resp.data.UserRecord[0].ProfileImage
                        //     );
                        //     setIsLoading(false);
                        //     navigate("/");
                        //   } catch (e) {
                        //     setIsLoading(false);
                        //     toast.error(e.message, {
                        //       position: "bottom-left",
                        //       autoClose: 5000,
                        //       hideProgressBar: false,
                        //       closeOnClick: true,
                        //       pauseOnHover: true,
                        //       draggable: true,
                        //       progress: undefined,
                        //       theme: "light",
                        //     });
                        //   }
                        // };

                        // FetchResponseToVerifyAdmin();
                    } else {
                        toast.error("Inncorrect OTP!", {
                            position: "bottom-left",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        return
                    }
                } catch (e) {
                    setIsLoading(false);
                    toast.error("Not Authorized!", {
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            };

            FetchResponseToVerifyOTP();
        }
    };

    const handleResendOTP = async () => {
        try {
            const response = await fetch(`${ARENO_API_ENDPOINT}/auth/Authentication`, {
                method: "POST",
                body: JSON.stringify({
                    Mobile_Number: localStorage.getItem("Mobile_Number"),
                }),
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) {
                throw new Error("Resending OTP failed!");
            }

            toast.success("OTP has been resent to the given number!", {
                position: "bottom-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            OTPInputRef.current.value = "";
            setCount(30)
            const countdownInterval = setInterval(() => {
                setCount((prevCount) => {
                    if (prevCount === 1) {
                        clearInterval(countdownInterval);
                    }
                    return prevCount - 1;
                });
            }, 1000);
        } catch (error) {
            toast.error(error.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    return (
        <Card style={{}} className={classes.input}>
            <form onSubmit={submitHandler}>
                {!isOtpSent && (
                    <div className={classes.control}>
                        <label htmlFor="MobileNumber">Mobile Number</label>
                        <input
                            placeholder="Enter Mobile Number"
                            type="number"
                            id="MobileNumber"
                            required
                            ref={MobileNumberInputRef}
                        />
                    </div>
                )}
                {isOtpSent && (
                    <div className={classes.control}>
                        <label htmlFor="otp">Enter OTP</label>
                        <input
                            type="number"
                            id="otp"
                            required
                            ref={OTPInputRef}
                            placeholder="Enter the OTP"
                        />
                    </div>
                )}
                <div className={classes.actions}>
                    {!isLoading && (
                        <>
                            {!isOtpSent ? null : (
                                <div className={classes.actions}>
                                    <p>
                                        Didn't recieve OTP?{" "}
                                        {count === 0 ? (
                                            <span
                                                onClick={handleResendOTP}
                                                style={{ cursor: "pointer", color: "blue" }}
                                                title="Click to resend OTP!"
                                            >
                                                Resend OTP.
                                            </span>
                                        ) : (
                                            <span>Try in {count} seconds</span>
                                        )}
                                    </p>
                                </div>
                            )}
                            <div style={{ display: "flex" }}>
                                <div>
                                    <Button type="submit">
                                        {!isOtpSent ? "Send OTP" : "Verify OTP"}
                                    </Button>
                                </div>
                                <div style={{ marginLeft: "1rem" }}></div>
                            </div>
                        </>
                    )}
                    {isLoading && <p>Sending request...</p>}
                </div>
            </form>
        </Card>
    );
};

export default AuthForm;
