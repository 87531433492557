import { useState, useEffect } from "react";
import {
    DataGridPremium
} from "@mui/x-data-grid-premium";
import { useNavigate } from "react-router-dom";
import classes from "../../Challenge.module.css";
import AUTH_CONTEXT from "../../../../store";
import Loader from "../../../../components/Loader/Loader";
import { toast } from "react-toastify";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { BASE_PATH_TO_ALL_SOLO_CHALLENGE_PARTICIPANTS, BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";
import CustomToolbar from "../../../../components/toolbar";
import { BASE_PATH_TO_ADD_SOLO_CHALLENGE, BASE_PATH_TO_SOLO_CHALLENGE_DETAILS, BASE_PATH_TO_UPDATE_SOLO_CHALLENGE } from "../../../../base_routes_paths/challenge_paths";
import { SoloChallengeServices } from "../../../../services/challenge_services";

const SoloChallengeList = () => {
    const { token, fileName } = AUTH_CONTEXT();
    const EXPORT_FILENAME = `${fileName}_SoloChallengeList`;

    const userColumns = [
        {
            field: "id",
            headerName: "Challenege ID",
            width: 230,
            renderCell: (params) => {
                return (
                    <a target="_blank" rel="noreferrer" href={`${BASE_PATH_TO_SOLO_CHALLENGE_DETAILS}/` + params.row.id}>{params.row.id}</a>
                );
            },
        },
        {
            field: "ChallengeThumbURL",
            headerName: "Image",
            width: 50,
            renderCell: (params) => {
                return (
                    <div className={classes.cellWithImg}>
                        <img
                            className={classes.cellImg}
                            src={
                                params.row.ChallengeThumbURL ||
                                "https://www.areno.in/images/logo.png"
                            }
                            alt=""
                        />
                    </div>
                );
            },
        },
        {
            field: "WorkoutID",
            headerName: "Workout Name",
            width: 120,
            renderCell: (params) => {
                const getWorkoutName = (number) => {
                    switch (number) {
                        case 1:
                            return 'Push-ups';
                        case 2:
                            return 'Planks';
                        case 3:
                            return 'Squats';
                        case 4:
                            return 'High Knees';
                        case 5:
                            return 'Lunges';
                        case 6:
                            return 'Situps';
                        case 7:
                            return 'Knee Pushups';
                        case 8:
                            return 'Jumping Jacks';
                        default:
                            return 'Unknown Workout';
                    }
                }
                return <>{`${getWorkoutName(params.row.WorkoutID)} (${params.row.WorkoutID})`}</>
            }
        },
        {
            field: "Level",
            headerName: "Level",
            width: 80,
        },
        {
            field: "Goal",
            headerName: "Goal",
            width: 80,
        },
        {
            field: "Threshold",
            headerName: "Threshold",
            width: 80,
        },
        {
            field: "Duration",
            headerName: "Duration",
            width: 80,
        },
        {
            field: "TimeDuration",
            headerName: "TimeDuration",
            width: 120,
        },
        {
            field: "SetPoint",
            headerName: "SetPoint",
            width: 80,
        },
        {
            field: "Rewards",
            headerName: "Rewards",
            width: 80,
        },
        {
            field: "DeviceType",
            headerName: "DeviceType",
            width: 120,
        },
        {
            field: "IsPaid",
            headerName: "IsPaid",
            width: 80,
        },
        {
            field: "IsActive",
            headerName: "IsActive",
            width: 80,
        },
    ];

    const [isLoading, setIsLoading] = useState(false);
    const [isDataFetched, setIsDataFetched] = useState(false);
    let ChallengeData = [];
    const [data, setData] = useState(ChallengeData);
    const navigate = useNavigate();

    const FetchResponseGetAllUserDetails = async () => {
        try {
            let response = await SoloChallengeServices.getAllSoloChallenge(token, navigate);

            setIsLoading(true);
            setIsDataFetched(true);
            toast.success("Data fetched successfully!!", {
                position: "bottom-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return response.data;
        } catch (e) {
            setIsLoading(false);
            setIsDataFetched(false);
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    useEffect(() => {
        if (!isDataFetched) {
            FetchResponseGetAllUserDetails()
                .then((result) => {
                    let input = result.map(({ _id: id, ...result }) => ({
                        id,
                        ...result,
                    }));
                    setData(input);
                })
                .catch((err) => console.log(err));
        }
    }, []);

    const handleDelete = (id) => {
        SoloChallengeServices.deleteSoloChallenge(id, token);
    };

    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: 300,
            renderCell: (params) => {
                return (
                    <div className={classes.cellAction}>
                        <a target="_blank" rel="noreferrer" href={BASE_PATH_TO_ALL_SOLO_CHALLENGE_PARTICIPANTS}
                            onClick={() => localStorage.setItem("ChallengeID", params.row.id)}
                            style={{ textDecoration: "none" }}
                        >
                            <div className={classes.viewButton}>View Participants</div>
                        </a>
                        <a target="_blank" rel="noreferrer" href={`${BASE_PATH_TO_UPDATE_SOLO_CHALLENGE}/` + params.row.id}
                            style={{ textDecoration: "none" }}
                        >
                            <div className={classes.viewButton}>Update</div>
                        </a>
                        <div
                            className={classes.deleteButton}
                            onClick={() => handleDelete(params.row.id)}
                        >
                            Delete
                        </div>
                    </div>
                );
            },
        },
    ];

    return (
        <div className={classes.datatable}>
            <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 1 }}>
                <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                    Dashboard
                </Link>
                <Typography color="text.primary">
                    Challenges
                </Typography>
                <Typography color="text.primary">Solo Challenges List</Typography>
            </Breadcrumbs>
            {isLoading && (
                <DataGridPremium
                    rows={data}
                    columns={userColumns.concat(actionColumn)}
                    slots={{
                        toolbar: () => (
                            <CustomToolbar
                                EXPORT_FILENAME={EXPORT_FILENAME}
                                onClickCreate={() => {
                                    navigate(BASE_PATH_TO_ADD_SOLO_CHALLENGE);
                                }}
                                displayCreate={""}
                                onClickUpdate={() => { }}
                                displayUpdate={"none"}
                            />
                        ),
                    }}
                    pagination
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 25, page: 0 },
                        },
                    }}
                    disableRowSelectionOnClick
                    checkboxSelectionVisibleOnly
                    checkboxSelection
                />
            )}
            {!isLoading && <Loader />}
        </div>
    );
};

export default SoloChallengeList;
