export const BASE_PATH_TO_REDEEM = "/redeem";

// ==============================|| FREE USER CONFIG ||============================== //

export const BASE_PATH_TO_FREE_USER_CONFIG_VIEW = `${BASE_PATH_TO_REDEEM}/free-user-config`;
export const BASE_PATH_TO_ADD_FREE_USER_CONFIG = `${BASE_PATH_TO_REDEEM}/add-free-user-config`;
export const BASE_PATH_TO_UPDATE_FREE_USER_CONFIG = `${BASE_PATH_TO_REDEEM}/update-free-user-config`;

// ==============================|| SUBSCRIPTIONS ||============================== //

export const BASE_PATH_TO_SUBSCRIPTIONS_LIST = `${BASE_PATH_TO_REDEEM}/subscriptions`;
export const BASE_PATH_TO_ADD_SUBSCRIPTIONS = `${BASE_PATH_TO_REDEEM}/add-subscriptions`;
export const BASE_PATH_TO_UPDATE_SUBSCRIPTIONS = `${BASE_PATH_TO_REDEEM}/update-subscriptions`;

// ==============================|| SUBSCRIPTIONS ||============================== //

export const BASE_PATH_TO_VIEW_SUBSCRIPTIONS_DETAILS = `${BASE_PATH_TO_REDEEM}/subscription-details`;
export const BASE_PATH_TO_ADD_SUBSCRIPTIONS_DETAILS = `${BASE_PATH_TO_REDEEM}/add-subscription-details`;
export const BASE_PATH_TO_UPDATE_SUBSCRIPTIONS_DETAILS = `${BASE_PATH_TO_REDEEM}/update-subscription-details`;

// ==============================|| VOUCHER CONFIG ||============================== //

export const BASE_PATH_TO_VOUCHER_LIST = `${BASE_PATH_TO_REDEEM}/vouchers`;
export const BASE_PATH_TO_ADD_VOUCHER = `${BASE_PATH_TO_REDEEM}/add-voucher`;
export const BASE_PATH_TO_UPDATE_VOUCHER = `${BASE_PATH_TO_REDEEM}/update-voucher`;

// ==============================|| PURCHASE SUBSCRIPTION CONFIG ||============================== //

export const BASE_PATH_TO_PURCHASE_USER_SUBSCRIPTION = `${BASE_PATH_TO_REDEEM}/purchase-user-subscription`;

export const BASE_PATH_TO_AFA_LIST = `${BASE_PATH_TO_REDEEM}/app-feature-available-list`;
export const BASE_PATH_TO_ADD_AFA = `${BASE_PATH_TO_REDEEM}/add-app-feature-available`;
export const BASE_PATH_TO_UPDATE_AFA = `${BASE_PATH_TO_REDEEM}/update-app-feature-available`;

// ==============================|| PURCHASE SUBSCRIPTION CONFIG ||============================== //

export const BASE_PATH_TO_PAYMENT_GATEWAY_CONFIG_LIST = `${BASE_PATH_TO_REDEEM}/payment-gateway-config-list`;
export const BASE_PATH_TO_ADD_PAYMENT_GATEWAY_CONFIG = `${BASE_PATH_TO_REDEEM}/add-payment-gateway-config`;
export const BASE_PATH_TO_UPDATE_PAYMENT_GATEWAY_CONFIG = `${BASE_PATH_TO_REDEEM}/update-payment-gateway-config`;

export const BASE_PATH_TO_PHONE_PE_PAYMENT_INIT = `${BASE_PATH_TO_REDEEM}/phone-pe-payment-init`;
export const BASE_PATH_TO_PHONE_PE_RECURRING_INIT = `${BASE_PATH_TO_REDEEM}/phone-pe-payment-recurring-init`;

// ==============================|| PAYMENT INVOICE CONFIG ||============================== //

export const BASE_PATH_TO_PAYMENT_INVOICE_CONFIG_LIST = `${BASE_PATH_TO_REDEEM}/payment-invoice-config-list`;
export const BASE_PATH_TO_ADD_PAYMENT_INVOICE_CONFIG = `${BASE_PATH_TO_REDEEM}/add-payment-invoice-config`;
export const BASE_PATH_TO_UPDATE_PAYMENT_INVOICE_CONFIG = `${BASE_PATH_TO_REDEEM}/update-payment-invoice-config`;