import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import classes from "../../Challenge.module.css";
import { toast } from "react-toastify";
import AUTH_CONTEXT from "../../../../store";
import ARENO_API_ENDPOINT from "../../../../api";
import { StatusCode } from "../../../../utils";
import Loader from "../../../../components/Loader/Loader";
import Card from "../../../../components/Card/Card";
import Button from "../../../../components/Button/Button";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";
import { BASE_PATH_TO_CORPORATE_LIST } from "../../../../base_routes_paths/challenge_paths";
import { CorporateServices } from "../../../../services/challenge_services";

const UpdateCorporateIDConfig = () => {
    const navigate = useNavigate();
    const { token } = AUTH_CONTEXT();
    const [isLoading, setIsLoading] = useState(false);
    const [isLoaderLoading, setIsLoaderLoading] = useState(false);
    const [id, setId] = useState("");
    const [CorporateID, setCorporateID] = useState("");
    const [corporateImage, setCorporateImage] = useState("");
    const [corporateImageDetail, setCorporateImageDetail] = useState("");
    const [CorporateName, setCorporateName] = useState("");
    const [EmailDomain, setEmailDomain] = useState("");
    const [isEmailRequired, setIsEmailRequired] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [
        isConsolidatedLeaderBoardRequired,
        setIsConsolidatedLeaderBoardRequired,
    ] = useState(false);
    const [IsReferralPointCount, setIsReferralPointCount] = useState(false);
    const [isRegistrationPointCount, setIsRegistrationPointCount] =
        useState(false);
    const [isDailyRewardPointCount, setIsDailyRewardPointCount] = useState(false);
    const [isCaloriesBasedLeaderboard, setIsCaloriesBasedLeaderboard] =
        useState(false);
    const [subscriptionStartsOn, setSubscriptionStartsOn] = useState("");
    const [subscriptionEndsOn, setSubscriptionEndsOn] = useState("");
    const [inputFields, setInputFields] = useState([
        { TeamID: "", TeamName: "", TeamProfileUrl: "" },
    ]);
    const [SubscriptionID, setSubscriptionID] = useState("");
    const [inputCategoryIdPointCountFields, setInputCategoryIdPointCountFields] =
        useState([{ CategoryID: '' }]);
    const [isSubscribed, setIsSubscribed] = useState(false);

    const params = useParams();

    const [subscriptionDetails, setSubscriptionDetails] = useState([]);

    const fetchSubscriptionDetails = async () => {
        try {
            const response = await fetch(
                `${ARENO_API_ENDPOINT}/redeem/GetAllSubscriptionDetailsForApp`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );
            StatusCode(response, navigate);
            const data = await response.json();
            setSubscriptionDetails(data.data);
        } catch (e) {
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    const handleSelectClick = () => {
        if (subscriptionDetails.length === 0) {
            fetchSubscriptionDetails();
        }
    };

    const FetchCorporateDetails = async () => {
        try {
            let response = await CorporateServices.getCorporateByID(token, navigate, params.ID);
            setIsLoaderLoading(false);
            setIsLoaderLoading(true);
            return response.data;
        } catch (e) {
            setIsLoaderLoading(false);
            console.log(e);
        }
    };

    useEffect(() => {
        handleSelectClick();
        FetchCorporateDetails()
            .then((result) => {
                setId(result[0]._id);
                setCorporateID(result[0].CorporateID);
                setCorporateName(result[0].CorporateName);
                setCorporateImage(result[0].CorporateImage);
                setCorporateImageDetail(result[0].CorporateImageDetailedScreen);
                setEmailDomain(result[0].EmailDomain);
                setInputFields(result[0].CorporateTeamIDs);
                setInputCategoryIdPointCountFields(result[0].CategoryIdPointCount);
                setIsEmailRequired(result[0].IsEmailRequired);
                setIsActive(result[0].IsActive);
                setIsConsolidatedLeaderBoardRequired(
                    result[0].IsConsolidatedLeaderBoardRequired
                );
                setIsReferralPointCount(result[0].IsReferralPointCount);
                setIsRegistrationPointCount(result[0].IsRegistrationPointCount);
                setIsDailyRewardPointCount(result[0].IsDailyRewardPointCount);
                setIsCaloriesBasedLeaderboard(result[0].IsCaloriesBasedLeaderboard);
                setIsSubscribed(result[0].IsSubscribed);
                setSubscriptionStartsOn(result[0].SubscriptionStartsOn);
                setSubscriptionEndsOn(result[0].SubscriptionEndsOn);
                setSubscriptionID(result[0].SubscriptionIDForUser);
            })
            .catch((err) => console.log(err));
    }, []);

    const SubmitHandler = (event) => {
        event.preventDefault();
        setIsLoading(true);

        const FetchApi = async () => {
            try {
                let emailFlag = isEmailRequired;
                if (emailFlag === "true") {
                    emailFlag = true;
                } else {
                    emailFlag = false;
                }

                const Data = {
                    _id: id,
                    Data: {
                        CorporateID: CorporateID,
                        CorporateName: CorporateName,
                        EmailDomain: EmailDomain,
                        IsEmailRequired: isEmailRequired,
                        CorporateTeamIDs: inputFields,
                        CorporateImage: corporateImage,
                        CorporateImageDetailedScreen: corporateImageDetail,
                        SubscriptionStartsOn: subscriptionStartsOn,
                        SubscriptionEndsOn: subscriptionEndsOn,
                        CategoryIdPointCount: inputCategoryIdPointCountFields,
                        IsConsolidatedLeaderBoardRequired:
                            isConsolidatedLeaderBoardRequired,
                        IsReferralPointCount: IsReferralPointCount,
                        IsRegistrationPointCount: isRegistrationPointCount,
                        IsDailyRewardPointCount: isDailyRewardPointCount,
                        IsCaloriesBasedLeaderboard: isCaloriesBasedLeaderboard,
                        IsSubscribed: isSubscribed,
                        IsActive: isActive,
                        SubscriptionIDForUser: SubscriptionID,
                    },
                }
                let response = await CorporateServices.updateCorporate(token, navigate, Data);

                setIsLoading(false);
                if (response.status === 207) {
                    return toast
                        .error(response.error, {
                            position: "bottom-left",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                        })
                }
                navigate(BASE_PATH_TO_CORPORATE_LIST);
            } catch (e) {
                setIsLoading(false);
                console.log(e.message);
                alert(e.message);
            }
        };
        FetchApi();
    };

    const handleFormChange = (index, event) => {
        let data = [...inputFields];
        data[index][event.target.name] = event.target.value;
        setInputFields(data);
    };

    const addFields = (event) => {
        event.preventDefault();
        let newfield = { TeamID: "", TeamName: "", TeamProfileUrl: "" };
        setInputFields([...inputFields, newfield]);
    };

    const removeFields = (index) => {
        let data = [...inputFields];
        data.splice(index, 1);
        setInputFields(data);
    };

    const handleFormChangeCategory = (index, event) => {
        let data = [...inputCategoryIdPointCountFields];
        const { name, value } = event.target;
        data[index][name] = name === "CategoryID" ? parseInt(value, 10) : value;
        setInputCategoryIdPointCountFields(data);
    };

    const addFieldsCategory = (event) => {
        event.preventDefault();
        let newfield = { CategoryID: "" };
        setInputCategoryIdPointCountFields([
            ...inputCategoryIdPointCountFields,
            newfield,
        ]);
    };

    const removeFieldsCategory = (index) => {
        let data = [...inputCategoryIdPointCountFields];
        data.splice(index, 1);
        setInputCategoryIdPointCountFields(data);
    };

    return (
        <>
            {!isLoaderLoading && <Loader />}
            {isLoaderLoading && (
                <div className={classes.crumbs}>
                    <Breadcrumbs aria-label="breadcrumb" >
                        <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                            Dashboard
                        </Link>
                        <Typography color="text.primary">
                            Challenege
                        </Typography>
                        <Typography color="text.primary">Update Corporate</Typography>
                    </Breadcrumbs>
                    <Card className={classes.input}>
                        <div className={classes.Main_div}>Update Corporate Details</div>
                        <form onSubmit={SubmitHandler}>
                            <div className={classes.selectbox}>
                                <label htmlFor="CorporateID">ID :</label>
                                <input
                                    type="text"
                                    id="CorporateID"
                                    placeholder="Enter CorporateID"
                                    disabled
                                    defaultValue={CorporateID}
                                />
                            </div>
                            <div className={classes.control}>
                                <label htmlFor="CorporateName">Corporate Name :</label>
                                <input
                                    type="text"
                                    required
                                    id="CorporateName"
                                    placeholder="Enter Corporate name"
                                    value={CorporateName}
                                    onChange={(e) => setCorporateName(e.target.value)}
                                />
                            </div>
                            <div className={classes.control}>
                                <label htmlFor="CorporateImage">CorporateImage :</label>
                                <input
                                    type="url"
                                    id="CorporateImage"
                                    placeholder="Enter Corporate Image URL"
                                    value={corporateImage}
                                    onChange={(e) => setCorporateImage(e.target.value)}
                                />
                            </div>
                            <div className={classes.control}>
                                <label htmlFor="CorporateImageDetailedScreen">
                                    CorporateImageDetailedScreen :
                                </label>
                                <input
                                    type="url"
                                    id="CorporateImageDetailedScreen"
                                    placeholder="Enter Corporate Image Detailed Screen URL"
                                    value={corporateImageDetail}
                                    onChange={(e) => setCorporateImageDetail(e.target.value)}
                                />
                            </div>
                            <div className={classes.subfields}>
                                <h3>Team details</h3>
                                {inputFields.map((input, index) => {
                                    return (
                                        <div key={index} className={classes.subfieldsControl}>
                                            <div className={classes.control}>
                                                <label htmlFor="TeamID">ID</label>
                                                <input
                                                    name="TeamID"
                                                    placeholder="TeamID"
                                                    value={input.TeamID}
                                                    onChange={(event) => handleFormChange(index, event)}
                                                />
                                            </div>
                                            <div className={classes.control}>
                                                <label htmlFor="TeamName">Name</label>
                                                <input
                                                    name="TeamName"
                                                    placeholder="TeamName"
                                                    value={input.TeamName}
                                                    onChange={(event) => handleFormChange(index, event)}
                                                />
                                            </div>
                                            <div className={classes.control}>
                                                <label htmlFor="TeamProfileUrl">Profile Link</label>
                                                <input
                                                    name="TeamProfileUrl"
                                                    placeholder="TeamProfileUrl"
                                                    value={input.TeamProfileUrl}
                                                    onChange={(event) => handleFormChange(index, event)}
                                                />
                                            </div>
                                            <button onClick={() => removeFields(index)}>
                                                Remove
                                            </button>
                                        </div>
                                    );
                                })}
                                <button onClick={addFields}>Add More...</button>
                            </div>
                            <div className={classes.subfields}>
                                <h3>CategoryIdPointCount</h3>
                                {inputCategoryIdPointCountFields.map((input, index) => {
                                    return (
                                        <div key={index} className={classes.subfieldsControl}>
                                            <div className={classes.control}>
                                                <label htmlFor="CategoryID">CategoryID</label>
                                                <input
                                                    name="CategoryID"
                                                    placeholder="CategoryID"
                                                    value={input.CategoryID}
                                                    onChange={(event) =>
                                                        handleFormChangeCategory(index, event)
                                                    }
                                                />
                                            </div>
                                            <button onClick={() => removeFieldsCategory(index)}>
                                                Remove
                                            </button>
                                        </div>
                                    );
                                })}
                                <button onClick={addFieldsCategory}>Add More...</button>
                            </div>
                            <div>
                                <div className={classes.selectbox}>
                                    <label htmlFor="IsEmailRequired">IsEmailRequired</label>
                                    <select
                                        name="IsEmailRequired"
                                        id="Category"
                                        value={isEmailRequired}
                                        onChange={(e) => setIsEmailRequired(e.target.value === "true")}
                                    >
                                        <option value="false">No</option>
                                        <option value="true">Yes</option>
                                    </select>
                                </div>
                                {isEmailRequired === true && (
                                    <div className={classes.control}>
                                        <label htmlFor="EmailDomain">Email domain</label>
                                        <input
                                            type="text"
                                            id="EmailDomain"
                                            value={EmailDomain}
                                            onChange={(e) => { setEmailDomain(e.target.value) }}
                                            placeholder="Enter domain for email"
                                        />
                                    </div>
                                )}
                            </div>
                            <div className={classes.selectbox}>
                                <label htmlFor="IsActive">IsActive</label>
                                <select
                                    name="IsActive"
                                    id="Category"
                                    value={isActive}
                                    onChange={(e) => setIsActive(e.target.value)}
                                >
                                    <option value={true}>Yes</option>
                                    <option value={false}>No</option>
                                </select>
                            </div>
                            <div className={classes.selectbox}>
                                <label htmlFor="IsConsolidatedLeaderBoardRequired">
                                    IsConsolidatedLeaderBoardRequired
                                </label>
                                <select
                                    name="IsConsolidatedLeaderBoardRequired"
                                    id="Category"
                                    value={isConsolidatedLeaderBoardRequired}
                                    onChange={(e) =>
                                        setIsConsolidatedLeaderBoardRequired(e.target.value)
                                    }
                                >
                                    <option value={true}>Yes</option>
                                    <option value={false}>No</option>
                                </select>
                            </div>
                            <div className={classes.selectbox}>
                                <label htmlFor="IsReferralPointCount">
                                    IsReferralPointCount
                                </label>
                                <select
                                    name="IsReferralPointCount"
                                    id="Category"
                                    value={IsReferralPointCount}
                                    onChange={(e) => {
                                        setIsReferralPointCount(e.target.value);
                                    }}
                                >
                                    <option value={true}>Yes</option>
                                    <option value={false}>No</option>
                                </select>
                            </div>
                            <div className={classes.selectbox}>
                                <label htmlFor="IsRegistrationPointCount">
                                    IsRegistrationPointCount
                                </label>
                                <select
                                    name="IsRegistrationPointCount"
                                    id="Category"
                                    value={isRegistrationPointCount}
                                    onChange={(e) => setIsRegistrationPointCount(e.target.value)}
                                >
                                    <option value={true}>Yes</option>
                                    <option value={false}>No</option>
                                </select>
                            </div>
                            <div className={classes.selectbox}>
                                <label htmlFor="IsDailyRewardPointCount">
                                    IsDailyRewardPointCount
                                </label>
                                <select
                                    name="IsDailyRewardPointCount"
                                    id="Category"
                                    value={isDailyRewardPointCount}
                                    onChange={(e) => setIsDailyRewardPointCount(e.target.value)}
                                >
                                    <option value={true}>Yes</option>
                                    <option value={false}>No</option>
                                </select>
                            </div>
                            <div className={classes.selectbox}>
                                <label htmlFor="IsCaloriesBasedLeaderboard">
                                    IsCaloriesBasedLeaderboard
                                </label>
                                <select
                                    name="IsCaloriesBasedLeaderboard"
                                    id="Category"
                                    value={isCaloriesBasedLeaderboard}
                                    onChange={(e) =>
                                        setIsCaloriesBasedLeaderboard(e.target.value)
                                    }
                                >
                                    <option value={true}>Yes</option>
                                    <option value={false}>No</option>
                                </select>
                            </div>

                            <div className={classes.selectbox}>
                                <label htmlFor="SubscriptionID">Subscription ID : </label>
                                <select
                                    name="SubscriptionID"
                                    id="SubscriptionID"
                                    placeholder="Select One"
                                    required
                                    onClick={handleSelectClick}
                                    style={{ width: "300px" }}
                                    onChange={(event) => {
                                        setSubscriptionID(event.target.value);
                                    }}
                                    value={SubscriptionID}
                                >
                                    {subscriptionDetails.map((subscription) => (
                                        <option key={subscription._id} value={subscription._id}>
                                            {subscription.Name} ({subscription._id})
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div
                                className={classes.selectbox}
                                style={{
                                    marginBottom: isSubscribed === false && "2rem",
                                }}
                            >
                                <label htmlFor="IsSubscribed">IsSubscribed</label>
                                <select
                                    name="IsSubscribed"
                                    id="Category"
                                    onChange={(e) => {
                                        setIsSubscribed(e.target.value === "true");
                                        if (e.target.value === "false") {
                                            setSubscriptionStartsOn("");
                                            setSubscriptionEndsOn("");
                                        }
                                    }}
                                    value={isSubscribed}
                                >
                                    <option value={"false"}>No</option>
                                    <option value={"true"}>Yes</option>
                                </select>
                            </div>

                            {isSubscribed === true && (
                                <>
                                    <div className={classes.selectbox}>
                                        <label htmlFor="SubscriptionStartsOn">
                                            SubscriptionStartsOn:{" "}
                                        </label>
                                        <input
                                            id="SubscriptionStartsOn"
                                            required={isSubscribed === true}
                                            type="date"
                                            placeholder="Enter date"
                                            value={subscriptionStartsOn}
                                            onChange={(e) => setSubscriptionStartsOn(e.target.value)}
                                        />
                                    </div>
                                    <div
                                        className={classes.selectbox}
                                        style={{ marginBottom: "2rem" }}
                                    >
                                        <label htmlFor="SubscriptionEndsOn">
                                            SubscriptionEndsOn:{" "}
                                        </label>
                                        <input
                                            id="SubscriptionEndsOn"
                                            type="date"
                                            required={isSubscribed === true}
                                            placeholder="Enter date"
                                            value={subscriptionEndsOn}
                                            onChange={(e) => setSubscriptionEndsOn(e.target.value)}
                                        />
                                    </div>
                                </>
                            )}
                            {!isLoading && <Button type="submit">Update</Button>}
                            {isLoading && <p color="white">Sending request...</p>}
                        </form>
                    </Card>
                </div>
            )}
        </>
    );
};

export default UpdateCorporateIDConfig;
