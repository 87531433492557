import { useState } from "react";
import classes from "../../Challenge.module.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Breadcrumbs, CircularProgress, Link, Typography } from "@mui/material";
import AUTH_CONTEXT from "../../../../store";
import ARENO_API_ENDPOINT from "../../../../api";
import { StatusCode } from "../../../../utils";
import Card from "../../../../components/Card/Card";
import Button from "../../../../components/Button/Button";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";
import { SoloChallengeServices } from "../../../../services/challenge_services";
import { BASE_PATH_TO_SOLO_CHALLENGE_LIST } from "../../../../base_routes_paths/challenge_paths";

const CreateSoloChallenge = () => {
    const navigate = useNavigate();
    const { token } = AUTH_CONTEXT();
    const [isLoading, setIsLoading] = useState(false);
    const [WorkoutID, setWorkoutID] = useState("");
    const [isPaid, setIsPaid] = useState(false);
    const [IsActive, setIsActive] = useState(false);
    const [Duration, setDuration] = useState("");
    const [DeviceType, setDeviceType] = useState("All");
    const [level, setLevel] = useState("");
    const [setPoint, setSetPoint] = useState("");
    const [timeDuration, setTimeDuration] = useState("");
    const [goal, setGoal] = useState("");
    const [threshold, setThreshold] = useState("");
    const [rewards, setRewards] = useState("");
    const [ChallengeThumbURL, setChallengeThumbURL] = useState("");
    const [Description, setDescription] = useState({
        "en-US": "",
        "en-IN": "",
        "en-CA": ""
    });
    const [WorkoutIDAPI, setWorkoutIDAPI] = useState([]);
    const [isWorkoutIDLoading, setIsWorkoutIDLoading] = useState(false);

    const fetchWorkoutIDDetails = async () => {
        setIsWorkoutIDLoading(true)
        try {
            const response = await fetch(
                `${ARENO_API_ENDPOINT}/challenges/GetWorkoutID`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );
            StatusCode(response, navigate);
            const data = await response.json();
            setWorkoutIDAPI(data.data);
            setIsWorkoutIDLoading(false)
        } catch (e) {
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    const handleWorkoutIDSelectClick = () => {
        if (WorkoutIDAPI.length === 0) {
            fetchWorkoutIDDetails();
        }
    };

    const SubmitHandler = (e) => {
        e.preventDefault();
        setIsLoading(true);

        const Data = {
            WorkoutID: parseInt(WorkoutID),
            Level: level,
            IsPaid: isPaid,
            SetPoint: setPoint,
            TimeDuration: timeDuration,
            Goal: goal,
            Threshold: threshold,
            Rewards: rewards,
            ChallengeThumbURL: ChallengeThumbURL,
            Description: Description,
            Duration: +Duration,
            IsActive: IsActive,
            DeviceType: DeviceType
        }

        const FetchResponseSetReferrelConfig = async () => {
            try {
                let response = await SoloChallengeServices.addSoloChallenge(token, navigate, Data);
                if (response.status === 207) {
                    return toast
                        .error(response.error, {
                            position: "bottom-left",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                        })
                }

                if (response.data.WorkoutID === 1) {
                    localStorage.setItem(
                        "Solo-Challenge-Push-ups",
                        JSON.stringify(response.data)
                    );
                } else if (response.data.WorkoutID === 2) {
                    localStorage.setItem(
                        "Solo-Challenge-Planks",
                        JSON.stringify(response.data)
                    );
                } else if (response.data.WorkoutID === 3) {
                    localStorage.setItem(
                        "Solo-Challenge-Squats",
                        JSON.stringify(response.data)
                    );
                } else if (response.data.WorkoutID === 4) {
                    localStorage.setItem(
                        "Solo-Challenge-High Knees",
                        JSON.stringify(response.data)
                    );
                } else if (response.data.WorkoutID === 5) {
                    localStorage.setItem(
                        "Solo-Challenge-Lunges",
                        JSON.stringify(response.data)
                    );
                } else if (response.data.WorkoutID === 6) {
                    localStorage.setItem(
                        "Solo-Challenge-Situps",
                        JSON.stringify(response.data)
                    );
                } else if (response.data.WorkoutID === 7) {
                    localStorage.setItem(
                        "Solo-Challenge-Knee Pushups",
                        JSON.stringify(response.data)
                    );
                } else if (response.data.WorkoutID === 8) {
                    localStorage.setItem(
                        "Solo-Challenge-Jumping Jacks",
                        JSON.stringify(response.data)
                    );
                }
                navigate(BASE_PATH_TO_SOLO_CHALLENGE_LIST);
                toast.success("Data added successfully!!", {
                    position: "bottom-left",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } catch (e) {
                setIsLoading(false);
                toast.error(e.message, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        };
        FetchResponseSetReferrelConfig();
    };

    const handleFormChangeWorkoutID = (e) => {
        e.preventDefault();
        setWorkoutID(parseInt(e.target.value));
        let SoloChallengePrevData;

        if (e.target.value === "1") {
            SoloChallengePrevData = JSON.parse(
                localStorage.getItem("Solo-Challenge-Push-ups")
            );
        } else if (e.target.value === "2") {
            SoloChallengePrevData = JSON.parse(
                localStorage.getItem("Solo-Challenge-Planks")
            );
        } else if (e.target.value === "3") {
            SoloChallengePrevData = JSON.parse(
                localStorage.getItem("Solo-Challenge-Squats")
            );
        } else if (e.target.value === "4") {
            SoloChallengePrevData = JSON.parse(
                localStorage.getItem("Solo-Challenge-High Knees")
            );
        } else if (e.target.value === "5") {
            SoloChallengePrevData = JSON.parse(
                localStorage.getItem("Solo-Challenge-Lunges")
            );
        } else if (e.target.value === "6") {
            SoloChallengePrevData = JSON.parse(
                localStorage.getItem("Solo-Challenge-Situps")
            );
        } else if (e.target.value === "7") {
            SoloChallengePrevData = JSON.parse(
                localStorage.getItem("Solo-Challenge-Knee Pushups")
            );
        } else if (e.target.value === "8") {
            SoloChallengePrevData = JSON.parse(
                localStorage.getItem("Solo-Challenge-Jumping Jacks")
            );
        }

        if (SoloChallengePrevData !== null) {
            setLevel(parseInt(SoloChallengePrevData.Level));
            setIsPaid(SoloChallengePrevData.IsPaid);
            setSetPoint(parseInt(SoloChallengePrevData.SetPoint));
            setTimeDuration(parseInt(SoloChallengePrevData.TimeDuration));
            setThreshold(parseInt(SoloChallengePrevData.Threshold));
            setRewards(parseInt(SoloChallengePrevData.Rewards));
            setGoal(parseInt(SoloChallengePrevData.Goal));
            setChallengeThumbURL(SoloChallengePrevData.ChallengeThumbURL);
            setDescription(SoloChallengePrevData.Description.replace(/\s{2,}/g, ' '));
            setDuration(SoloChallengePrevData.Duration);
            setIsActive(SoloChallengePrevData.IsActive);
        } else {
            setIsPaid(false);
            setLevel("");
            setSetPoint("");
            setChallengeThumbURL("")
            setTimeDuration("");
            setDescription("");
            setThreshold("");
            setRewards("");
            setGoal("");
            setDuration("");
            setIsActive(false);
        }
    };

    const handleDescriptionChange = (e, locale) => {
        const updatedDescription = { ...Description, [locale]: e.target.value };
        setDescription(updatedDescription);
    };

    return (
        <div className={classes.crumbs}>
            <Breadcrumbs aria-label="breadcrumb" >
                <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                    Dashboard
                </Link>
                <Typography color="text.primary">
                    Challenege
                </Typography>
                <Typography color="text.primary">Create Solo Challenge</Typography>
            </Breadcrumbs>
            <Card className={classes.input}>
                <div className={classes.Main_div}>Create Solo Challenge</div>
                <form onSubmit={SubmitHandler}>
                    <div className={classes.selectbox} style={{ alignItems: "center" }}>
                        <label htmlFor="WorkoutID">Workout ID : </label>
                        <select
                            name="WorkoutID"
                            id="WorkoutID"
                            required
                            onClick={handleWorkoutIDSelectClick}
                            style={{ width: "300px" }}
                            onChange={(e) => handleFormChangeWorkoutID(e)}
                            value={WorkoutID}
                        >
                            <option value="">Select WorkoutID</option>
                            {WorkoutIDAPI.map((Workout) => (
                                <option key={Workout.WorkoutID} value={Workout.WorkoutID}>
                                    {Workout.WorkoutID}.{Workout.WorkoutName}
                                </option>
                            ))}
                        </select>
                        <span style={{ marginLeft: "1rem", marginTop: "1px" }}>
                            {isWorkoutIDLoading && (
                                <CircularProgress size={25} thickness={5} />
                            )}
                        </span>
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="Level">Level :</label>
                        <input
                            type="number"
                            id="Level"
                            required
                            value={level}
                            onChange={(e) => setLevel(parseInt(e.target.value))}
                            placeholder="Enter level"
                        />
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="SetPoint">SetPoint :</label>
                        <input
                            type="number"
                            id="SetPoint"
                            required
                            value={setPoint}
                            onChange={(e) => setSetPoint(parseInt(e.target.value))}
                            placeholder="Enter SetPoint"
                        />
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="TimeDuration">TimeDuration :</label>
                        <input
                            type="number"
                            id="TimeDuration"
                            required
                            value={timeDuration}
                            onChange={(e) => setTimeDuration(parseInt(e.target.value))}
                            placeholder="Duration(secs)"
                        />
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="Duration">Duration :</label>
                        <input
                            type="number"
                            id="Duration"
                            required
                            value={Duration}
                            onChange={(e) => setDuration(parseInt(e.target.value))}
                            placeholder="Duration(Days)"
                        />
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="Goal">Goal :</label>
                        <input
                            type="number"
                            id="Goal"
                            required
                            value={goal}
                            onChange={(e) => setGoal(parseInt(e.target.value))}
                            placeholder="Enter Goal"
                        />
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="Threshold">Threshold :</label>
                        <input
                            type="number"
                            id="Threshold"
                            required
                            value={threshold}
                            onChange={(e) => setThreshold(parseInt(e.target.value))}
                            placeholder="Enter Threshold"
                        />
                    </div>

                    <div className={classes.control}>
                        <label htmlFor="ChallengeThumbURL">Challenge Thumb URL : </label>
                        <input
                            id="ChallengeThumbURL"
                            type="text"
                            placeholder="Enter ChallengeThumbURL"
                            value={ChallengeThumbURL}
                            onChange={(e) => setChallengeThumbURL(e.target.value)}
                        />
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="Rewards">Rewards :</label>
                        <input
                            type="number"
                            id="Rewards"
                            required
                            value={rewards}
                            onChange={(e) => setRewards(parseInt(e.target.value))}
                            placeholder="Enter Rewards"
                        />
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="IsPaid">IsPaid : </label>
                        <select
                            name="IsPaid"
                            id="IsPaid"
                            required
                            value={isPaid}
                            onChange={(e) => setIsPaid(e.target.value === "true")}
                        >
                            <option value="false">No</option>
                            <option value="true">Yes</option>
                        </select>
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="IsActive">IsActive : </label>
                        <select
                            name="IsActive"
                            id="IsActive"
                            value={IsActive}
                            onChange={(e) => setIsActive(e.target.value === "true")}
                        >
                            <option value="false">No</option>
                            <option value="true">Yes</option>
                        </select>
                    </div>

                    <div className={classes.selectbox} >
                        <label htmlFor="DeviceType">DeviceType : </label>
                        <select
                            name="DeviceType"
                            id="DeviceType"
                            value={DeviceType}
                            onChange={(e) => setDeviceType(e.target.value)}
                        >
                            <option value="All">All</option>
                            <option value="IOS">IOS</option>
                            <option value="Android">Android</option>
                        </select>
                    </div>

                    <div className={classes.subfields}>
                        <h3>Descriptions</h3>
                        <div className={classes.subfields}>
                            <div className={classes.control}>
                                <label htmlFor="en-US">Description (en-US)</label>
                                <input
                                    type="text"
                                    id="en-US"
                                    value={Description["en-US"]}
                                    onChange={(e) => handleDescriptionChange(e, "en-US")}
                                    placeholder="Enter Description for en-US"
                                />
                            </div>
                            <div className={classes.control}>
                                <label htmlFor="en-IN">Description (en-IN)</label>
                                <input
                                    type="text"
                                    id="en-IN"
                                    value={Description["en-IN"]}
                                    onChange={(e) => handleDescriptionChange(e, "en-IN")}
                                    placeholder="Enter Description for en-IN"
                                />
                            </div>
                            <div className={classes.control}>
                                <label htmlFor="en-CA">Description (en-CA)</label>
                                <input
                                    type="text"
                                    id="en-CA"
                                    value={Description["en-CA"]}
                                    onChange={(e) => handleDescriptionChange(e, "en-CA")}
                                    placeholder="Enter Description for en-CA"
                                />
                            </div>
                        </div>
                    </div>

                    {!isLoading && <Button type="submit">Add</Button>}
                    {isLoading && <p color="white">Sending request...</p>}
                </form>
            </Card>
        </div>
    );
};

export default CreateSoloChallenge;
