import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    DataGridPremium,
    GridToolbarContainer,
    GridToolbarExport,
} from "@mui/x-data-grid-premium";
import { Breadcrumbs, Button, Link, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import classes from "../Data.module.css";
import AUTH_CONTEXT from "../../../store";
import ARENO_API_ENDPOINT from "../../../api";
import { StatusCode } from "../../../utils";
import Loader from "../../../components/Loader/Loader";
import { BASE_PATH_TO_HOME, BASE_PATH_TO_USER_SNAPSHOTS } from "../../../base_routes_paths/data_paths";
import { SnapshotsService } from "../../../services/auth_services";

const userColumns = [
    {
        field: "ChallengeThumbURL",
        headerName: "Challenge",
        width: 250,
        renderCell: (params) => {
            return (
                <div className={classes.cellWithImg}>
                    <img
                        className={classes.cellImg}
                        src={
                            params.row.ChallengeThumbURL ||
                            "https://areno.in/img/group-1@2x.svg"
                        }
                        alt=""
                    />
                    {params.row.Name}
                </div>
            );
        },
    },
    {
        field: "WorkoutTimeOrNo",
        headerName: "WorkoutTimeOrNo",
        width: 180,
    },
    {
        field: "IsCompleted",
        headerName: "IsCompleted",
        width: 120,
    },
    {
        field: "Dated",
        headerName: "Attempted On",
        width: 250,
    },
];

const UserChallangeAttemptSnapshots = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [data, setData] = useState([]);
    const params = useParams();
    const [model, setModel] = useState([]);
    const { token, fileName } = AUTH_CONTEXT()
    const navigate = useNavigate();
    const EXPORT_FILENAME = `${fileName}_UserSnapshotsList`;

    const FetchResponseGetAllUserDetails = async () => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/challenges/GetUserAttemptsForOngoingChallenges`,
                {
                    method: "POST",
                    body: JSON.stringify({
                        UserID: params.ID,
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            setIsLoading(false);
            StatusCode(response, navigate);
            response = await response.json();
            setIsLoading(true);
            setIsDataFetched(true);
            return response.data.data;
        } catch (e) {
            setIsLoading(false);
            setIsDataFetched(false);
            console.log(e.message);
            alert(e.message);
        }
    };

    useEffect(() => {
        if (!isDataFetched) {
            FetchResponseGetAllUserDetails()
                .then((result) => {
                    let input = result.map((obj) => {
                        const newObject = { ...obj };
                        newObject.Dated = new Date(
                            newObject.Dated || new Date()
                        ).toLocaleString("en-Us", { timeZone: "Asia/Kolkata" });
                        const { _id, ...rest } = newObject;
                        return {
                            id: _id,
                            ...rest,
                        };
                    });
                    setData(input);
                })
                .catch((err) => console.log(err));
        }
    }, []);

    const handleDelete = () => {
        SnapshotsService.deleteSnapshots(model, token);
    };

    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: 100,
            renderCell: (params) => {
                return (
                    <div className={classes.cellAction}>
                        <div
                            className={classes.deleteButton}
                            onClick={() => handleDelete(params.row.id)}
                        >
                            Delete
                        </div>
                    </div>
                );
            },
        },
        {
            field: "snapshots",
            headerName: "Snapshots",
            width: 100,
            renderCell: (params) => {
                return (
                    <div className={classes.cellAction}>
                        <a target="_blank" rel="noreferrer" href={
                            BASE_PATH_TO_USER_SNAPSHOTS +
                            "/"
                            +
                            params.row.UserID +
                            "/" +
                            params.row.ChallengeID
                        }
                            style={{ textDecoration: "none" }}
                        >
                            <div className={classes.viewButton}>View</div>
                        </a>
                    </div>
                );
            },
        },
    ];

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport
                    printOptions={{ disableToolbarButton: true }}
                    csvOptions={{ fileName: EXPORT_FILENAME }}
                    excelOptions={{ fileName: EXPORT_FILENAME }}
                />
                <div style={{ marginLeft: "auto" }}>
                    <Button
                        color="primary"
                        onClick={handleDelete}
                        sx={{ margin: 0, padding: 0 }}
                    >
                        <p style={{ marginRight: "5px" }}>Delete</p> <DeleteIcon />
                    </Button>
                </div>
            </GridToolbarContainer>
        );
    }

    return (
        <div className={classes.datatable}>
            {isLoading && (
                <>
                    <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 1 }}>
                        <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                            Dashboard
                        </Link>
                        <Typography color="text.primary">
                            Data
                        </Typography>
                        <Typography color="text.primary">Snapshots</Typography>
                    </Breadcrumbs>
                    <DataGridPremium
                        checkboxSelection
                        checkboxSelectionVisibleOnly
                        disableRowSelectionOnClick
                        rows={data}
                        columns={userColumns.concat(actionColumn)}
                        pageSize={20}
                        rowsPerPageOptions={[20]}
                        slots={{
                            toolbar: CustomToolbar,
                        }}
                        initialState={{
                            pagination: {
                                paginationModel: { pageSize: 25, page: 0 },
                            },
                        }}
                        pagination
                        onRowSelectionModelChange={(e) => {
                            const selectedIDs = new Set(e);
                            setModel(Array.from(selectedIDs));
                        }}
                        rowSelectionModel={model}
                    />
                </>
            )}
            {!isLoading && <Loader />}
        </div>
    );
};

export default UserChallangeAttemptSnapshots;
