import { useState, useEffect } from "react";
import classes from "../../Auth.module.css";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { Breadcrumbs, CircularProgress, Link, Typography } from "@mui/material";
import AUTH_CONTEXT from "../../../../store";
import ARENO_API_ENDPOINT from "../../../../api";
import { StatusCode } from "../../../../utils";
import Loader from "../../../../components/Loader/Loader";
import Card from "../../../../components/Card/Card";
import Button from "../../../../components/Button/Button";
import { BASE_PATH_TO_VIEW_ONBOARDING_SCREEN_RECOMMENDATIONS } from "../../../../base_routes_paths/auth_paths";
import { OnboardinScreenRecommendationsServices } from "../../../../services/auth_services";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";

const UpdateOSRecommendations = () => {
    const navigate = useNavigate();
    const params = useParams();
    const { token } = AUTH_CONTEXT();
    const [isLoading, setIsLoading] = useState(false);
    const [isLoaderLoading, setIsLoaderLoading] = useState(false);
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [IsDefault, setIsDefault] = useState(false);
    const [RecommendationData, setRecommendationData] = useState([]);
    const [SolutionData, setSolutionData] = useState([]);
    const [isCategoryIDLoading, setIsCategoryIDLoading] = useState(false);
    const [CategoryIDAPI, setCategoryIDAPI] = useState([]);
    const [QuestionAPI, setQuestionAPI] = useState([]);
    const [isQuestionsLoading, setIsQuestionsLoading] = useState(false);

    const SubmitHandler = (event) => {
        event.preventDefault();
        setIsLoading(true);

        const Data = {
            _id: params.ID,
            Data: {
                IsDefault,
                SolutionData,
                RecommendationData
            }
        }

        const FetchResponse = async () => {
            try {
                let response = await OnboardinScreenRecommendationsServices.updateOnboardinScreenRecommendations(token, navigate, Data);
                if (response.status === 207) {
                    return toast
                        .error(response.error, {
                            position: "bottom-left",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                        })
                }
                toast.success("Data added successfully!!", {
                    position: "bottom-left",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate(BASE_PATH_TO_VIEW_ONBOARDING_SCREEN_RECOMMENDATIONS);
            } catch (e) {
                setIsLoading(false);
                toast.error(e.message, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        };
        FetchResponse();
    };

    const FetchResponse = async () => {
        try {
            let response = await OnboardinScreenRecommendationsServices.getOnboardinScreenQuestionsByID(token, navigate, params.ID);

            if (response.status === 207) {
                return toast
                    .error(response.error, {
                        position: "bottom-left",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                    })
            }
            setIsLoaderLoading(true);
            setIsDataFetched(true);
            toast.success("Data fetched successfully!!", {
                position: "bottom-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return response.data;
        } catch (e) {
            setIsLoaderLoading(false);
            setIsDataFetched(false);
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    useEffect(() => {
        if (!isDataFetched) {
            handleCategoryIDSelectClick();
            handleQuestionSelectClick();
            FetchResponse()
                .then((result) => {
                    setIsDefault(result.IsDefault);
                    setRecommendationData(result.RecommendationData);
                    setSolutionData(result.SolutionData);
                })
                .catch((err) => console.log(err));
        }
    }, []);

    const handleFormChangeCategoryID = (index, event) => {
        let data = [...RecommendationData];
        data[index][event.target.name] = parseInt(event.target.value);
        setRecommendationData(data);
    };

    const handleFormChangeSolutionData = (index, event) => {
        let data = [...SolutionData];
        data[index][event.target.name] = parseInt(event.target.value);
        setSolutionData(data);
    };

    const addFieldsCategoryID = (event) => {
        event.preventDefault();
        let newfield = { CategoryID: "" };
        setRecommendationData([...RecommendationData, newfield]);
    };

    const addFieldsSolutionData = (event) => {
        event.preventDefault();
        let newfield = {
            QuestionID: "",
            ValueID: ""
        };
        setSolutionData([...SolutionData, newfield]);
    };

    const removeFieldsCategoryID = (index) => {
        let data = [...RecommendationData];
        data.splice(index, 1);
        setRecommendationData(data);
    };

    const removeFieldsSolutionData = (index) => {
        let data = [...SolutionData];
        data.splice(index, 1);
        setSolutionData(data);
    };

    const fetchCategoryIDDetails = async () => {
        setIsCategoryIDLoading(true)
        try {
            const response = await fetch(
                `${ARENO_API_ENDPOINT}/challenges/GetChallengeCategory`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );
            StatusCode(response, navigate);
            const data = await response.json();
            setCategoryIDAPI(data.data);
            setIsCategoryIDLoading(false)
        } catch (e) {
            setIsCategoryIDLoading(false)
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    const handleCategoryIDSelectClick = () => {
        if (CategoryIDAPI.length === 0) {
            fetchCategoryIDDetails();
        }
    };

    const fetchQuestionDetails = async () => {
        setIsQuestionsLoading(true)
        try {
            const response = await fetch(
                `${ARENO_API_ENDPOINT}/auth/AdminGetAllOnboardingScreenQuestions`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );
            StatusCode(response, navigate);
            const data = await response.json();
            setQuestionAPI(data.data[0].QuestionArray);
            console.log(data.data[0])
            setIsQuestionsLoading(false)
        } catch (e) {
            setIsQuestionsLoading(false)
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    const handleQuestionSelectClick = () => {
        if (QuestionAPI.length === 0) {
            fetchQuestionDetails();
        }
    };

    return (
        !isLoaderLoading ? <Loader /> :
            <div className={classes.crumbs}>
                <Breadcrumbs aria-label="breadcrumb" >
                    <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                        Dashboard
                    </Link>
                    <Typography color="text.primary">
                        Auth
                    </Typography>
                    <Typography color="text.primary">Update Onboarding Screen Recommendations</Typography>
                </Breadcrumbs>
                <Card className={classes.input}>
                    <div className={classes.Main_div}>Update Onboarding Screen Recommendations</div>
                    <form onSubmit={SubmitHandler}>
                        <div className={classes.subfields}>
                            <h3>RecommendationData</h3>
                            {RecommendationData.map((input, index) => {
                                return (
                                    <div key={index} className={classes.subfieldsControl}>
                                        <div className={classes.selectbox}>
                                            <label htmlFor="CategoryID">Category ID : </label>
                                            <select
                                                name="CategoryID"
                                                id="CategoryID"
                                                required
                                                style={{ width: "300px" }}
                                                onChange={(e) => handleFormChangeCategoryID(index, e)}
                                                value={input.CategoryID}
                                            >
                                                <option value="">Select CategoryID</option>
                                                {CategoryIDAPI.map((Category) => (
                                                    <option key={Category.CategoryID} value={Category.CategoryID}>
                                                        {Category.CategoryID}.{Category.CategoryName}
                                                    </option>
                                                ))}
                                            </select>
                                            <span style={{ marginLeft: "1rem", marginTop: "1px" }}>
                                                {isCategoryIDLoading && (
                                                    <CircularProgress size={25} thickness={5} />
                                                )}
                                            </span>
                                        </div>
                                        <button
                                            onClick={(event) => {
                                                event.preventDefault();
                                                removeFieldsCategoryID(index);
                                            }}
                                        >
                                            Remove CategoryID
                                        </button>
                                    </div>
                                );
                            })}
                            <button onClick={addFieldsCategoryID}>Add More CategoryID...</button>
                        </div>

                        <div className={classes.subfields}>
                            <h3>SolutionData</h3>
                            {SolutionData.map((input, index) => {
                                return (
                                    <div key={index} className={classes.subfieldsControl}>
                                        <div className={classes.selectbox}>
                                            <label htmlFor="QuestionID">Question ID : </label>
                                            <select
                                                name="QuestionID"
                                                id="QuestionID"
                                                required
                                                style={{ width: "400px" }}
                                                onChange={(event) => handleFormChangeSolutionData(index, event)}
                                                value={input.QuestionID}
                                            >
                                                <option value="">Select QuestionID</option>
                                                {QuestionAPI.map((QuestionAPI) => (
                                                    <option key={QuestionAPI.QuestionID} value={QuestionAPI.QuestionID}>
                                                        {QuestionAPI.QuestionID}.{QuestionAPI.Question}
                                                    </option>
                                                ))}
                                            </select>
                                            <span style={{ marginLeft: "1rem", marginTop: "1px" }}>
                                                {isQuestionsLoading && (
                                                    <CircularProgress size={25} thickness={5} />
                                                )}
                                            </span>
                                        </div>
                                        <div className={classes.selectbox}>
                                            <label htmlFor="ValueID">Value ID : </label>
                                            <select
                                                name="ValueID"
                                                id="ValueID"
                                                required
                                                style={{ width: "400px" }}
                                                onChange={(event) => handleFormChangeSolutionData(index, event)}
                                                value={input.ValueID}
                                            >
                                                {input.QuestionID === "" ? <option value="">Please select a QuestionID</option> : <option value="">Select ValueID</option>}
                                                {QuestionAPI[input.QuestionID - 1] && QuestionAPI[input.QuestionID - 1].ValueArray?.map((QuestionAPI) => (
                                                    <option key={QuestionAPI.ValueID} value={QuestionAPI.ValueID}>
                                                        {QuestionAPI.ValueID}.{QuestionAPI.Value}
                                                    </option>
                                                ))}
                                            </select>
                                            <span style={{ marginLeft: "1rem", marginTop: "1px" }}>
                                                {isQuestionsLoading && (
                                                    <CircularProgress size={25} thickness={5} />
                                                )}
                                            </span>
                                        </div>
                                        <button
                                            onClick={(event) => {
                                                event.preventDefault();
                                                removeFieldsSolutionData(index);
                                            }}
                                        >
                                            Remove SolutionData...
                                        </button>
                                    </div>
                                );
                            })}
                            <button onClick={addFieldsSolutionData}>Add More SolutionData...</button>
                        </div>

                        <div className={classes.selectbox} style={{ marginBottom: "1rem" }}>
                            <label htmlFor="IsDefault">IsDefault : </label>
                            <select
                                name="IsDefault"
                                id="IsDefault"
                                value={IsDefault}
                                onChange={(e) => setIsDefault(e.target.value)}
                            >
                                <option value={false}>False</option>
                                <option value={true}>True</option>
                            </select>
                        </div>

                        {!isLoading && <Button type="submit">Update</Button>}
                        {isLoading && <p color="white">Sending request...</p>}
                    </form>
                </Card>
            </div>
    );
};

export default UpdateOSRecommendations;
