import { useState, useEffect } from "react";
import {
    DataGridPremium
} from "@mui/x-data-grid-premium";
import classes from "../../Challenge.module.css";
import AUTH_CONTEXT from "../../../../store";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../../../components/Loader/Loader";
import CustomToolbar from "../../../../components/toolbar";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";
import { RoutinePlansServices } from "../../../../services/challenge_services";
import { BASE_PATH_TO_ADD_ROUTINE_PLAN, BASE_PATH_TO_UPDATE_ROUTINE_PLAN } from "../../../../base_routes_paths/challenge_paths";

const RoutinePlansList = () => {
    const userColumns = [
        {
            field: "SortID",
            headerName: "SortID",
            width: 80,
        },
        {
            field: "RoutinePlanName",
            headerName: "RoutinePlanName",
            width: 300,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const RoutinePlanName = params.row.RoutinePlanName;

                return (
                    <div>
                        <table style={{ textAlign: "center" }}>
                            <thead>
                                <tr>
                                    <th style={{ paddingRight: 25 }}>Key</th>
                                    <th style={{ paddingRight: 25 }}>Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.entries(RoutinePlanName).map(([key, value]) => (
                                    <tr key={key}>
                                        <td style={{ paddingRight: 25 }}>{key}</td>
                                        <td style={{ paddingRight: 25 }}>{value}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                );
            },
        },
        {
            field: "RoutinePlanTaxonomy",
            headerName: "Routine Plan Taxonomy",
            width: 200,
            renderCell: (params) => {
                const taxonomy = params.row.RoutinePlanTaxonomy;

                return (
                    <div>
                        <table style={{ textAlign: "left", width: "100%" }}>
                            <tbody>
                                <tr>
                                    <td style={{ paddingRight: "10px" }}><strong>Activity Status:</strong></td>
                                    <td>{taxonomy.ActivityStatus}</td>
                                </tr>
                                <tr>
                                    <td style={{ paddingRight: "10px" }}><strong>Purpose:</strong></td>
                                    <td>{taxonomy.Purpose}</td>
                                </tr>
                                <tr>
                                    <td style={{ paddingRight: "10px" }}><strong>Body Part:</strong></td>
                                    <td>{taxonomy.BodyPart}</td>
                                </tr>
                                <tr>
                                    <td style={{ paddingRight: "10px" }}><strong>Duration:</strong></td>
                                    <td>{taxonomy.Duration}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                );
            },
        },
        {
            field: "RoutineBodyTargetArea",
            headerName: "RoutineBodyTargetArea",
            width: 300,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const RoutineBodyTargetArea = params.row.RoutineBodyTargetArea;

                return (
                    <div>
                        <table style={{ textAlign: "center" }}>
                            <thead>
                                <tr>
                                    <th style={{ paddingRight: 25 }}>Key</th>
                                    <th style={{ paddingRight: 25 }}>Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.entries(RoutineBodyTargetArea).map(([key, value]) => (
                                    <tr key={key}>
                                        <td style={{ paddingRight: 25 }}>{key}</td>
                                        <td style={{ paddingRight: 25 }}>{value}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                );
            },
        },
        {
            field: "RoutinePlanImageUrl",
            headerName: "RoutinePlanImageUrl",
            width: 700,
            renderCell: (params) => {
                const link = params.row.RoutinePlanImageUrl;
                return (
                    <a href={link} target="_blank" rel="noopener noreferrer">
                        {link}
                    </a>
                );
            },
        },
        {
            field: "RoutinePlanDurationInSecs",
            headerName: "RoutinePlanDurationInSecs",
            width: 190,
        },
        {
            field: "ActivitiesCount",
            headerName: "ActivitiesCount",
            width: 180,
        },
        {
            field: "IsActive",
            headerName: "IsActive",
            width: 80,
        },
        {
            field: "IsPaid",
            headerName: "IsPaid",
            width: 80,
        },
        {
            field: "Description",
            headerName: "Description",
            width: 300,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const Description = params.row.Description;

                return (
                    <div>
                        <table style={{ textAlign: "center" }}>
                            <thead>
                                <tr>
                                    <th style={{ paddingRight: 25 }}>Key</th>
                                    <th style={{ paddingRight: 25 }}>Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.entries(Description).map(([key, value]) => (
                                    <tr key={key}>
                                        <td style={{ paddingRight: 25 }}>{key}</td>
                                        <td style={{ paddingRight: 25 }}>{value}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                );
            },
        },
    ];

    const { token, fileName } = AUTH_CONTEXT();
    const EXPORT_FILENAME = `${fileName}_RoutinePlansList`;
    const [isLoading, setIsLoading] = useState(false);
    const [isDataFetched, setIsDataFetched] = useState(false);
    const navigate = useNavigate();
    const [data, setData] = useState([]);

    const FetchApi = async () => {
        try {
            let response = await RoutinePlansServices.getAllRoutinePlans(token, navigate);
            if (response.status === 207) {
                toast.error(response.error, {
                    position: "bottom-left",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                });
                return;
            }
            setIsLoading(true);
            setIsDataFetched(true);
            toast.success("Data fetched successfully!!", {
                position: "bottom-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return response.data;
        } catch (e) {
            setIsLoading(false);
            setIsDataFetched(false);
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    useEffect(() => {
        if (!isDataFetched) {
            FetchApi()
                .then((result) => {
                    let input = result.map(({ _id: id, ...result }) => ({
                        id,
                        ...result,
                    }));
                    setData(input);
                })
                .catch((err) => console.log(err));
        }
    }, []);

    const handleDelete = (id) => {
        RoutinePlansServices.deleteRoutinePlan(id, token);
    };

    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: 200,
            renderCell: (params) => {
                return (
                    <div className={classes.cellAction}>
                        <div>
                            <Link
                                href={`${BASE_PATH_TO_UPDATE_ROUTINE_PLAN}/` + params.row.id}
                                style={{ textDecoration: "none" }}
                            >
                                <div className={classes.viewButton}>Update</div>
                            </Link>
                        </div>
                        <div
                            className={classes.deleteButton}
                            onClick={() => handleDelete(params.row.id)}
                        >
                            Delete
                        </div>
                    </div>
                );
            },
        },
    ];

    return (
        <div className={classes.datatable}>
            <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 1 }}>
                <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                    Dashboard
                </Link>
                <Typography color="text.primary">
                    Challenge
                </Typography>
                <Typography color="text.primary">Routine Plans List</Typography>
            </Breadcrumbs>
            {isLoading && (
                <DataGridPremium
                    rows={data}
                    columns={userColumns.concat(actionColumn)}
                    slots={{
                        toolbar: () => (
                            <CustomToolbar
                                EXPORT_FILENAME={EXPORT_FILENAME}
                                onClickCreate={() => {
                                    navigate(BASE_PATH_TO_ADD_ROUTINE_PLAN);
                                }}
                                displayCreate={""}
                                onClickUpdate={() => { }}
                                displayUpdate={"none"}
                            />
                        )
                    }}
                    pagination
                    getRowHeight={() => 'auto'}
                    getEstimatedRowHeight={() => 200}
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 25, page: 0 },
                        },
                    }}
                    disableRowSelectionOnClick
                    checkboxSelectionVisibleOnly
                    checkboxSelection
                />
            )}
            {!isLoading && <Loader />}
        </div>
    );
};

export default RoutinePlansList