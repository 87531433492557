import { useState } from "react";
import { useNavigate } from "react-router-dom";
import classes from "../../Dashboard.module.css";
import { toast } from "react-toastify";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";
import AUTH_CONTEXT from "../../../../store";
import Card from "../../../../components/Card/Card";
import Button from "../../../../components/Button/Button";
import { DeviceURLServices } from "../../../../services/dashboard_services";
import { BASE_PATH_TO_DEVICE_URL_LIST } from "../../../../base_routes_paths/dashboard_paths";

const NewDeviceUrlsConfig = () => {
    const navigate = useNavigate();
    const { token } = AUTH_CONTEXT();
    const [key, setKey] = useState("");
    const [value, setValue] = useState("");
    const [labelText, setLabelText] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const SubmitHandler = (event) => {
        event.preventDefault();
        console.log(key, "helo");
        setIsLoading(true);

        const Data = {
            Value: value,
            LabelText: labelText,
        }

        const FetchResponse = async () => {
            try {
                let response = await DeviceURLServices.addPointConfig(token, navigate, key, Data);

                if (response.status === 207) {
                    return toast
                        .error(response.error, {
                            position: "bottom-left",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                        })
                }
                setIsLoading(false);
                navigate(BASE_PATH_TO_DEVICE_URL_LIST);
                toast.success("Data added successfully!!", {
                    position: "bottom-left",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } catch (e) {
                setIsLoading(false);
                toast.error(e.message, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        };
        FetchResponse();
    };

    return (
        <div className={classes.crumbs}>
            <Breadcrumbs aria-label="breadcrumb" >
                <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                    Dashboard
                </Link>
                <Typography color="text.primary">
                    Dashboard
                </Typography>
                <Typography color="text.primary">Add Device Url</Typography>
            </Breadcrumbs>
            <Card className={classes.input}>
                <div className={classes.Main_div}>Add Device Url</div>
                <form onSubmit={SubmitHandler}>
                    <div className={classes.control}>
                        <label htmlFor="Key">Key</label>
                        <input
                            type="number"
                            id="Key"
                            required
                            value={key}
                            onChange={(e) => setKey(parseInt(e.target.value))}
                            placeholder="Enter unique key eg. 1, 2, etc."
                        />
                    </div>
                    <div className={classes.control}>
                        <label htmlFor="Key">Link</label>
                        <input
                            type="text"
                            id="Key"
                            required
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                            placeholder="Enter link on the video"
                        />
                    </div>
                    <div className={classes.control}>
                        <label htmlFor="PointForReferralUser">Label Text</label>
                        <input
                            type="text"
                            id="LabelText"
                            required
                            value={labelText}
                            onChange={(e) => setLabelText(e.target.value)}
                            placeholder="Enter text for label"
                        />
                    </div>
                    {!isLoading && <Button type="submit">Add</Button>}
                    {isLoading && <p color="white">Sending request...</p>}
                </form>
            </Card>
        </div>
    );
};

export default NewDeviceUrlsConfig;
