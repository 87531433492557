import { useState } from "react";
import { useNavigate } from "react-router-dom";
import classes from "../Redeem.module.css";
import { toast } from "react-toastify";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import AUTH_CONTEXT from "../../../store";
import { BASE_PATH_TO_HOME } from "../../../base_routes_paths/data_paths";
import Button from "../../../components/Button/Button";
import Card from "../../../components/Card/Card";
import { PurchaseUserSubscriptionService } from "../../../services/redeem_services";
import { TrimInput } from "../../../utils";

const PurchaseUserSubscription = () => {
    const { token, fileName } = AUTH_CONTEXT();
    const navigate = useNavigate();
    const [OSType, setOSType] = useState("Android"); // Values [ "IOS" , "Android" ]

    // For Android (If Android => states)
    const [UserID, setUserID] = useState("");
    const [packageName, setPackageName] = useState("");
    const [productId, setProductId] = useState("");
    const [purchaseToken, setPurchaseToken] = useState("");

    // For IOS (If IOS => states)
    const [transactionId, setTransactionId] = useState("");
    const Env_Name = fileName;
    const [isLoading, setIsLoading] = useState(false);

    const SubmitHandler = (event) => {
        event.preventDefault();
        setIsLoading(true);
        let Data;

        // Android Data
        let AndroidData = {
            UserID: TrimInput(UserID),
            OSType: TrimInput(OSType),
            packageName: TrimInput(packageName),
            productId: TrimInput(productId),
            purchaseToken: TrimInput(purchaseToken),
        }

        // IOS Data
        let IOSData = {
            UserID: TrimInput(UserID),
            OSType: TrimInput(OSType),
            transactionId: TrimInput(transactionId),
            Env: TrimInput(Env_Name === "prod" ? "Production" : "Sandbox")
        }

        const FetchResponse = async () => {
            if (OSType === "Android") {
                Data = AndroidData;
            } else {
                Data = IOSData;
            }

            try {
                let response = await PurchaseUserSubscriptionService.addUserSubscription(token, navigate, Data);
                if (response.status === 207) {
                    return toast
                        .error(response.error, {
                            position: "bottom-left",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                        })
                }
                navigate(BASE_PATH_TO_HOME);
                toast.success("Data added successfully!!", {
                    position: "bottom-left",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } catch (e) {
                setIsLoading(false);
                toast.error(e.message, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        };
        FetchResponse();
    };

    return (
        <div className={classes.crumbs}>
            <Breadcrumbs aria-label="breadcrumb" >
                <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                    Dashboard
                </Link>
                <Typography color="text.primary">
                    Redeem
                </Typography>
                <Typography color="text.primary">Purchase User Subscription</Typography>
            </Breadcrumbs>
            <Card className={classes.input}>
                <div className={classes.Main_div}>Purchase Subscription for Users</div>
                <form onSubmit={SubmitHandler}>
                    <div className={classes.selectbox}>
                        <label htmlFor="OSType">OSType : </label>
                        <select
                            name="OSType"
                            id="OSType"
                            value={OSType}
                            onChange={(e) => setOSType(e.target.value)}
                        >
                            <option value="Android">Android</option>
                            <option value="IOS">IOS</option>
                        </select>
                    </div>

                    {
                        OSType === "Android" ?
                            <>
                                <div className={classes.control}>
                                    <label htmlFor="UserID">UserID</label>
                                    <input
                                        type="text"
                                        id="UserID"
                                        required
                                        value={UserID}
                                        onChange={(e) => setUserID(e.target.value)}
                                        placeholder="Enter UserID"
                                    />
                                </div>
                                <div className={classes.control}>
                                    <label htmlFor="packageName">Package Name</label>
                                    <input
                                        required
                                        type="text"
                                        id="packageName"
                                        value={packageName}
                                        onChange={(e) => setPackageName(e.target.value)}
                                        placeholder="Enter Package Name"
                                    />
                                </div>
                                <div className={classes.control}>
                                    <label htmlFor="productId">Product ID</label>
                                    <input
                                        required
                                        type="text"
                                        id="productId"
                                        value={productId}
                                        onChange={(e) => setProductId(e.target.value)}
                                        placeholder="Enter Product ID"
                                    />
                                </div>
                                <div className={classes.control}>
                                    <label htmlFor="purchaseToken">Purchase Token</label>
                                    <textarea
                                        type="text"
                                        required
                                        id="purchaseToken"
                                        value={purchaseToken}
                                        onChange={(e) => setPurchaseToken(e.target.value)}
                                        placeholder="Enter Purchase Token"
                                    />
                                </div>
                            </>
                            :
                            <>
                                <div className={classes.control}>
                                    <label htmlFor="UserID">UserID</label>
                                    <input
                                        type="text"
                                        id="UserID"
                                        required
                                        value={UserID}
                                        onChange={(e) => setUserID(e.target.value)}
                                        placeholder="Enter UserID"
                                    />
                                </div>
                                <div className={classes.control}>
                                    <label htmlFor="transactionId">Product ID</label>
                                    <input
                                        required
                                        type="text"
                                        id="transactionId"
                                        value={transactionId}
                                        onChange={(e) => setTransactionId(e.target.value)}
                                        placeholder="Enter Product ID"
                                    />
                                </div>
                            </>
                    }
                    {!isLoading && <Button type="submit">Submit</Button>}
                    {isLoading && <p color="white">Sending request...</p>}
                </form>
            </Card>
        </div>
    );
};

export default PurchaseUserSubscription;
