import {
    BASE_PATH_TO_ADD_CATEGORY,
    BASE_PATH_TO_ADD_CCR,
    BASE_PATH_TO_ADD_CHALLENGES,
    BASE_PATH_TO_ADD_COMMUNITY_GOALS,
    BASE_PATH_TO_ADD_CORPORATE,
    BASE_PATH_TO_ADD_MEDITATIONS,
    BASE_PATH_TO_ADD_ROUTINE_ACTIVITY,
    BASE_PATH_TO_ADD_ROUTINE_PLAN,
    BASE_PATH_TO_ADD_SOLO_CHALLENGE,
    BASE_PATH_TO_ADD_SOLO_CHALLENGE_MILESTONES,
    BASE_PATH_TO_ADD_WORKOUT,
    BASE_PATH_TO_CATEGORY_LIST,
    BASE_PATH_TO_CCR_LIST,
    BASE_PATH_TO_CHALLENGE,
    BASE_PATH_TO_CHALLENGES_LIST,
    BASE_PATH_TO_CHALLENGE_DETAILS,
    BASE_PATH_TO_COMMUNITY_GOALS_LIST,
    BASE_PATH_TO_CORPORATE_LIST,
    BASE_PATH_TO_MEDITATIONS_LIST,
    BASE_PATH_TO_ROUTINE_ACTIVITY_LIST,
    BASE_PATH_TO_ROUTINE_PLANS_LIST,
    BASE_PATH_TO_SOLO_CHALLENGE_ATTEMPTS,
    BASE_PATH_TO_SOLO_CHALLENGE_DETAILS,
    BASE_PATH_TO_SOLO_CHALLENGE_LIST,
    BASE_PATH_TO_SOLO_CHALLENGE_MILESTONES_LIST,
    BASE_PATH_TO_UPDATE_CATEGORY,
    BASE_PATH_TO_UPDATE_CCR,
    BASE_PATH_TO_UPDATE_CHALLENGES,
    BASE_PATH_TO_UPDATE_COMMUNITY_GOALS,
    BASE_PATH_TO_UPDATE_CORPORATE,
    BASE_PATH_TO_UPDATE_MEDITATIONS,
    BASE_PATH_TO_UPDATE_ROUTINE_ACTIVITY,
    BASE_PATH_TO_UPDATE_ROUTINE_PLAN,
    BASE_PATH_TO_UPDATE_SOLO_CHALLENGE,
    BASE_PATH_TO_UPDATE_SOLO_CHALLENGE_MILESTONES,
    BASE_PATH_TO_UPDATE_WORKOUT,
    BASE_PATH_TO_WORKOUT_LIST,
} from "../base_routes_paths/challenge_paths";
import DashboardPageLayout from "../layouts/dashboard_layout";
import CreateCategoryId from "../pages/challenge/category/add_category";
import CategoryList from "../pages/challenge/category/category_list";
import UpdateCategoryId from "../pages/challenge/category/update_category";
import ChallengeCreationForm from "../pages/challenge/challenge/add_challenge";
import ChallengeDetails from "../pages/challenge/challenge/challenge_details";
import ChallengesList from "../pages/challenge/challenge/challenge_list";
import ChallengeUpdationForm from "../pages/challenge/challenge/update_challenge";
import AddCommunityGoal from "../pages/challenge/community_goals/add_goals";
import CommunityGoalsList from "../pages/challenge/community_goals/goals_list";
import UpdateCommunityGoal from "../pages/challenge/community_goals/update_goals";
import NewCorporateID from "../pages/challenge/corporate/add_corporate";
import CorporateTable from "../pages/challenge/corporate/corporate_list";
import UpdateCorporateIDConfig from "../pages/challenge/corporate/update_corporate";
import AddCCR from "../pages/challenge/country_conversion_rates/add_rates";
import CCRList from "../pages/challenge/country_conversion_rates/rates_list";
import UpdateCCR from "../pages/challenge/country_conversion_rates/update_rates";
import AddMeditations from "../pages/challenge/meditations/add_meditations";
import MeditationsList from "../pages/challenge/meditations/meditations_list";
import UpdateMeditations from "../pages/challenge/meditations/update_meditations";
import AddRoutineActivties from "../pages/challenge/routine_activities/add_routine_activity";
import RoutineActivityList from "../pages/challenge/routine_activities/all_routine_activities";
import UpdateRoutineActivity from "../pages/challenge/routine_activities/update_routine_activity";
import AddRoutinePlans from "../pages/challenge/routine_plans/add_routine_plan";
import RoutinePlansList from "../pages/challenge/routine_plans/all_routine_plans";
import UpdateRoutinePlans from "../pages/challenge/routine_plans/update_routine_plan";
import CreateSoloChallenge from "../pages/challenge/solo_challenge/add_solo_challenge";
import UserSoloChallengeAttempts from "../pages/challenge/solo_challenge/solo_challenge_attempts";
import SoloChallengeDetails from "../pages/challenge/solo_challenge/solo_challenge_details";
import SoloChallengeList from "../pages/challenge/solo_challenge/solo_challenge_list";
import UpdateSoloChallenge from "../pages/challenge/solo_challenge/update_solo_challenge";
import CreateSoloChallengeRewards from "../pages/challenge/solo_challenge_milestone/add_solo_challenge_milestone";
import SoloChallengeRewardsList from "../pages/challenge/solo_challenge_milestone/solo_challenge_milstone_list";
import UpdateSoloChallengeRewards from "../pages/challenge/solo_challenge_milestone/update_solo_challenge_milestone";
import CreateWorkoutIdConfig from "../pages/challenge/workout/add_workout";
import UpdateWorkoutID from "../pages/challenge/workout/update_workout";
import WorkoutIdList from "../pages/challenge/workout/workout_list";
import { ProtectedRoutes } from "./protected_route";

const ChallengesRoutes = {
    path: BASE_PATH_TO_CHALLENGE,
    element: (
        <ProtectedRoutes>
            <DashboardPageLayout />
        </ProtectedRoutes>
    ),
    children: [
        {
            path: BASE_PATH_TO_CHALLENGES_LIST,
            element: <ChallengesList />,
        },
        {
            path: BASE_PATH_TO_ADD_CHALLENGES,
            element: <ChallengeCreationForm />,
        },
        {
            path: `${BASE_PATH_TO_UPDATE_CHALLENGES}/:ID`,
            element: <ChallengeUpdationForm />,
        },
        {
            path: `${BASE_PATH_TO_CHALLENGE_DETAILS}/:ID`,
            element: <ChallengeDetails />,
        },
        {
            path: BASE_PATH_TO_CORPORATE_LIST,
            element: <CorporateTable />,
        },
        {
            path: BASE_PATH_TO_ADD_CORPORATE,
            element: <NewCorporateID />,
        },
        {
            path: `${BASE_PATH_TO_UPDATE_CORPORATE}/:ID`,
            element: <UpdateCorporateIDConfig />,
        },
        {
            path: BASE_PATH_TO_WORKOUT_LIST,
            element: <WorkoutIdList />,
        },
        {
            path: BASE_PATH_TO_ADD_WORKOUT,
            element: <CreateWorkoutIdConfig />,
        },
        {
            path: `${BASE_PATH_TO_UPDATE_WORKOUT}/:ID`,
            element: <UpdateWorkoutID />,
        },
        {
            path: BASE_PATH_TO_CATEGORY_LIST,
            element: <CategoryList />,
        },
        {
            path: BASE_PATH_TO_ADD_CATEGORY,
            element: <CreateCategoryId />,
        },
        {
            path: `${BASE_PATH_TO_UPDATE_CATEGORY}/:ID`,
            element: <UpdateCategoryId />,
        },
        {
            path: BASE_PATH_TO_SOLO_CHALLENGE_LIST,
            element: <SoloChallengeList />,
        },
        {
            path: BASE_PATH_TO_ADD_SOLO_CHALLENGE,
            element: <CreateSoloChallenge />,
        },
        {
            path: `${BASE_PATH_TO_UPDATE_SOLO_CHALLENGE}/:ID`,
            element: <UpdateSoloChallenge />,
        },
        {
            path: `${BASE_PATH_TO_SOLO_CHALLENGE_DETAILS}/:ID`,
            element: <SoloChallengeDetails />,
        },
        {
            path: `${BASE_PATH_TO_SOLO_CHALLENGE_ATTEMPTS}/:UserID/:ChallengeID`,
            element: <UserSoloChallengeAttempts />,
        },
        {
            path: BASE_PATH_TO_SOLO_CHALLENGE_MILESTONES_LIST,
            element: <SoloChallengeRewardsList />,
        },
        {
            path: BASE_PATH_TO_ADD_SOLO_CHALLENGE_MILESTONES,
            element: <CreateSoloChallengeRewards />,
        },
        {
            path: `${BASE_PATH_TO_UPDATE_SOLO_CHALLENGE_MILESTONES}/:ID`,
            element: <UpdateSoloChallengeRewards />,
        },
        {
            path: `${BASE_PATH_TO_UPDATE_MEDITATIONS}/:ID`,
            element: <UpdateMeditations />,
        },
        {
            path: BASE_PATH_TO_MEDITATIONS_LIST,
            element: <MeditationsList />,
        },
        {
            path: BASE_PATH_TO_ADD_MEDITATIONS,
            element: <AddMeditations />,
        },
        {
            path: BASE_PATH_TO_CCR_LIST,
            element: <CCRList />,
        },
        {
            path: BASE_PATH_TO_ADD_CCR,
            element: <AddCCR />,
        },
        {
            path: `${BASE_PATH_TO_UPDATE_CCR}/:ID`,
            element: <UpdateCCR />,
        },
        {
            path: BASE_PATH_TO_ADD_COMMUNITY_GOALS,
            element: <AddCommunityGoal />,
        },
        {
            path: `${BASE_PATH_TO_UPDATE_COMMUNITY_GOALS}/:ID`,
            element: <UpdateCommunityGoal />,
        },
        {
            path: BASE_PATH_TO_COMMUNITY_GOALS_LIST,
            element: <CommunityGoalsList />,
        },
        {
            path: BASE_PATH_TO_ADD_ROUTINE_ACTIVITY,
            element: <AddRoutineActivties />,
        },
        {
            path: `${BASE_PATH_TO_UPDATE_ROUTINE_ACTIVITY}/:ID`,
            element: <UpdateRoutineActivity />,
        },
        {
            path: BASE_PATH_TO_ROUTINE_ACTIVITY_LIST,
            element: <RoutineActivityList />,
        },
        {
            path: BASE_PATH_TO_ADD_ROUTINE_PLAN,
            element: <AddRoutinePlans />,
        },
        {
            path: `${BASE_PATH_TO_UPDATE_ROUTINE_PLAN}/:ID`,
            element: <UpdateRoutinePlans />,
        },
        {
            path: BASE_PATH_TO_ROUTINE_PLANS_LIST,
            element: <RoutinePlansList />,
        },
    ],
};

export default ChallengesRoutes;
