import { useEffect, useState } from "react";
import classes from "../../Challenge.module.css";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import AUTH_CONTEXT from "../../../../store";
import Card from "../../../../components/Card/Card";
import Button from "../../../../components/Button/Button";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";
import { RoutineActivitiesServices } from "../../../../services/challenge_services";
import { BASE_PATH_TO_ROUTINE_ACTIVITY_LIST } from "../../../../base_routes_paths/challenge_paths";
import Loader from "../../../../components/Loader/Loader";

const UpdateRoutineActivity = () => {
    const params = useParams();
    const navigate = useNavigate();
    const { token } = AUTH_CONTEXT();
    const [isLoading, setIsLoading] = useState(false);
    const [IsLoaderLoading, setIsLoaderLoading] = useState(false);
    const [isDataFetched, setIsDataFetched] = useState(false);

    const [routineActivity, setRoutineActivity] = useState({
        RoutineActivityName: {
            "en-US": '',
            "en-IN": '',
            "en-CA": ''
        },
        RoutineActivityID: 0,
        RoutineActivityAnimatedGraphic: '',
        IsWorkout: true,
        IsActive: true,
        RoutineActivityInstructions: {
            "en-US": [],
            "en-IN": [],
            "en-CA": []
        },
        RoutineActivityFocusArea: {
            "en-US": [],
            "en-IN": [],
            "en-CA": []
        },
        RoutineActivityCalories: 0
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setRoutineActivity(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleNestedInputChange = (section, locale, index, value) => {
        setRoutineActivity(prevState => {
            const updatedSection = { ...prevState[section] };
            if (Array.isArray(updatedSection[locale])) {
                updatedSection[locale][index] = value;
            } else {
                updatedSection[locale] = value;
            }
            return {
                ...prevState,
                [section]: updatedSection
            };
        });
    };

    const handleAddInstruction = (locale) => {
        setRoutineActivity(prevState => ({
            ...prevState,
            RoutineActivityInstructions: {
                ...prevState.RoutineActivityInstructions,
                [locale]: [...prevState.RoutineActivityInstructions[locale], '']
            }
        }));
    };

    const handleRemoveInstruction = (locale, index) => {
        setRoutineActivity(prevState => {
            const updatedInstructions = [...prevState.RoutineActivityInstructions[locale]];
            updatedInstructions.splice(index, 1);
            return {
                ...prevState,
                RoutineActivityInstructions: {
                    ...prevState.RoutineActivityInstructions,
                    [locale]: updatedInstructions
                }
            };
        });
    };

    const handleAddFocusArea = (locale) => {
        setRoutineActivity(prevState => ({
            ...prevState,
            RoutineActivityFocusArea: {
                ...prevState.RoutineActivityFocusArea,
                [locale]: [...prevState.RoutineActivityFocusArea[locale], '']
            }
        }));
    };

    const handleRemoveFocusArea = (locale, index) => {
        setRoutineActivity(prevState => {
            const updatedFocusAreas = [...prevState.RoutineActivityFocusArea[locale]];
            updatedFocusAreas.splice(index, 1);
            return {
                ...prevState,
                RoutineActivityFocusArea: {
                    ...prevState.RoutineActivityFocusArea,
                    [locale]: updatedFocusAreas
                }
            };
        });
    };

    const FetchResponse = async () => {
        try {
            let response = await RoutineActivitiesServices.getAllRoutineActivity(token, navigate, params.ID);
            if (response.status === 207) {
                return toast
                    .error(response.error, {
                        position: "bottom-left",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                    })
            }
            setIsDataFetched(true);
            setIsLoaderLoading(false);
            toast.success("Data added successfully!!", {
                position: "bottom-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return response.data;
        } catch (e) {
            setIsDataFetched(false);
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    useEffect(() => {
        async function FetchData() {
            try {
                if (!isDataFetched) {
                    let result = await FetchResponse();
                    setRoutineActivity(result[0])
                }
            } catch (error) {
                toast.error(error.message, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }

        if (params.ID) {
            FetchData();
            setIsLoaderLoading(true);
        }
    }, []);

    const SubmitHandler = (e) => {
        e.preventDefault();
        setIsLoading(true);

        const FetchResponseSetReferrelConfig = async () => {
            try {
                let response = await RoutineActivitiesServices.addRoutineActivity(token, navigate, { _id: params.ID, Data: routineActivity });
                if (response.status === 207) {
                    return toast
                        .error(response.error, {
                            position: "bottom-left",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                        });
                }
                navigate(BASE_PATH_TO_ROUTINE_ACTIVITY_LIST);
                toast.success("Data added successfully!!", {
                    position: "bottom-left",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } catch (e) {
                setIsLoading(false);
                toast.error(e.message, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        };
        FetchResponseSetReferrelConfig();
    };

    return (
        IsLoaderLoading ? <Loader /> :
            <div className={classes.crumbs}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                        Dashboard
                    </Link>
                    <Typography color="text.primary">Challenge</Typography>
                    <Typography color="text.primary">Create Routine Activity</Typography>
                </Breadcrumbs>
                <Card className={classes.input}>
                    <div className={classes.Main_div}>Create Routine Activity</div>
                    <form onSubmit={SubmitHandler}>
                        {/* RoutineActivityName inputs */}
                        <div className={classes.subfields}>
                            <h3>Routine Activity Name</h3>
                            {Object.keys(routineActivity.RoutineActivityName).map(locale => (
                                <div className={classes.control} key={locale}>
                                    <label htmlFor={`RoutineActivityName-${locale}`}>Name ({locale})</label>
                                    <input
                                        type="text"
                                        id={`RoutineActivityName-${locale}`}
                                        value={routineActivity.RoutineActivityName[locale]}
                                        onChange={(e) => handleNestedInputChange('RoutineActivityName', locale, null, e.target.value)}
                                        placeholder={`Enter name for ${locale}`}
                                    />
                                </div>
                            ))}
                        </div>
                        {/* RoutineActivityID input */}
                        <div className={classes.selectbox}>
                            <label htmlFor="RoutineActivityID">Routine Activity ID:</label>
                            <input
                                type="number"
                                id="RoutineActivityID"
                                required
                                value={routineActivity.RoutineActivityID}
                                onChange={handleInputChange}
                                placeholder="Enter Routine Activity ID"
                                name="RoutineActivityID"
                            />
                        </div>

                        {/* RoutineActivityAnimatedGraphic input */}
                        <div className={classes.selectbox}>
                            <label htmlFor="RoutineActivityAnimatedGraphic">Animated Graphic URL:</label>
                            <input
                                type="text"
                                id="RoutineActivityAnimatedGraphic"
                                value={routineActivity.RoutineActivityAnimatedGraphic}
                                onChange={handleInputChange}
                                placeholder="Enter Animated Graphic URL"
                                name="RoutineActivityAnimatedGraphic"
                            />
                        </div>

                        {/* RoutineActivityInstructions inputs */}
                        <div className={classes.subfields}>
                            <h3>Routine Activity Instructions</h3>
                            {Object.keys(routineActivity.RoutineActivityInstructions).map(locale => (
                                <div key={locale}>
                                    <h4>{locale}</h4>
                                    {routineActivity.RoutineActivityInstructions[locale].map((instruction, index) => (
                                        <div key={index}>
                                            <input
                                                type="text"
                                                value={instruction}
                                                onChange={(e) => handleNestedInputChange('RoutineActivityInstructions', locale, index, e.target.value)}
                                                placeholder={`Enter instruction for ${locale}`}
                                            />
                                            <button type="button" onClick={() => handleRemoveInstruction(locale, index)}>Remove</button>
                                        </div>
                                    ))}
                                    <button type="button" onClick={() => handleAddInstruction(locale)}>Add Instruction</button>
                                </div>
                            ))}
                        </div>

                        {/* RoutineActivityFocusArea inputs */}
                        <div className={classes.subfields}>
                            <h3>Routine Activity Focus Area</h3>
                            {Object.keys(routineActivity.RoutineActivityFocusArea).map(locale => (
                                <div key={locale}>
                                    <h4>{locale}</h4>
                                    {routineActivity.RoutineActivityFocusArea[locale].map((area, index) => (
                                        <div key={index}>
                                            <input
                                                type="text"
                                                value={area}
                                                onChange={(e) => handleNestedInputChange('RoutineActivityFocusArea', locale, index, e.target.value)}
                                                placeholder={`Enter focus area for ${locale}`}
                                            />
                                            <button type="button" onClick={() => handleRemoveFocusArea(locale, index)}>Remove</button>
                                        </div>
                                    ))}
                                    <button type="button" onClick={() => handleAddFocusArea(locale)}>Add Focus Area</button>
                                </div>
                            ))}
                        </div>

                        {/* RoutineActivityCalories input */}
                        <div className={classes.selectbox}>
                            <label htmlFor="RoutineActivityCalories">Calories:</label>
                            <input
                                type="number"
                                id="RoutineActivityCalories"
                                required
                                value={routineActivity.RoutineActivityCalories}
                                onChange={handleInputChange}
                                placeholder="Enter Calories"
                                name="RoutineActivityCalories"
                            />
                        </div>

                        {/* IsWorkout input */}
                        <div className={classes.selectbox}>
                            <label htmlFor="IsWorkout">Is Workout:</label>
                            <select
                                name="IsWorkout"
                                id="IsWorkout"
                                required
                                value={routineActivity.IsWorkout}
                                onChange={(e) => setRoutineActivity(prevState => ({
                                    ...prevState,
                                    IsWorkout: e.target.value
                                }))}
                            >
                                <option value={false}>False</option>
                                <option value={true}>True</option>
                            </select>
                        </div>

                        {/* IsActive input */}
                        <div className={classes.selectbox}>
                            <label htmlFor="IsActive">Is Active:</label>
                            <select
                                name="IsActive"
                                id="IsActive"
                                required
                                value={routineActivity.IsActive}
                                onChange={(e) => setRoutineActivity(prevState => ({
                                    ...prevState,
                                    IsActive: e.target.value
                                }))}
                            >
                                <option value={false}>False</option>
                                <option value={true}>True</option>
                            </select>
                        </div>

                        {!isLoading && <Button type="submit">Update</Button>}
                        {isLoading && <p>Sending request...</p>}
                    </form>
                </Card>
            </div>
    );
};

export default UpdateRoutineActivity