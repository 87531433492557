import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import classes from "../../Game.module.css";
import { toast } from "react-toastify";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import AUTH_CONTEXT from "../../../../store";
import ARENO_API_ENDPOINT from "../../../../api";
import Card from "../../../../components/Card/Card";
import Button from "../../../../components/Button/Button";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";
import { StatusCode } from "../../../../utils";
import { GameLevelServices } from "../../../../services/games_services";
import { BASE_PATH_TO_GAMES_LIST } from "../../../../base_routes_paths/game_paths";
import Loader from "../../../../components/Loader/Loader";

const UpdateGameLevel = () => {
    const navigate = useNavigate();
    const params = useParams();

    const [WorkoutID, setWorkoutID] = useState("");
    const [Threshold, SetThreshold] = useState("");
    const [Level, SetLevel] = useState("");
    const [TimeDuration, setTimeDuration] = useState("");
    const [NoOfAttempts, setNoOfAttempts] = useState("");
    const [Frequency, setFrequency] = useState("");
    const [ChallengeThumbURL, setChallengeThumbURL] = useState("");
    const [Goal, setGoal] = useState("");
    const [IsPaid, setIsPaid] = useState(true)
    const [DeviceType, setDeviceType] = useState("All");
    const [Description, setDescription] = useState({
        "en-US": "",
        "en-IN": "",
        "en-CA": ""
    });
    const [isActive, setIsActive] = useState(true);
    const [IsUnlimitedAttempts, setIsUnlimitedAttempts] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [IsLoaderLoading, setIsLoaderLoading] = useState(false);
    const [isDataFetched, setIsDataFetched] = useState(false);
    const { token } = AUTH_CONTEXT();

    const [WorkoutIDAPI, setWorkoutIDAPI] = useState([]);

    const fetchWorkoutIDDetails = async () => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/challenges/GetWorkoutID`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );
            StatusCode(response, navigate);
            response = await response.json();
            setWorkoutIDAPI(response.data);
        } catch (e) {
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    const handleWorkoutIDSelectClick = () => {
        if (WorkoutIDAPI.length === 0) {
            fetchWorkoutIDDetails();
        }
    };

    const FetchResponse = async () => {
        try {
            let response = await GameLevelServices.getAllGameLevels(token, navigate, params.ID);
            if (response.status === 207) {
                return toast
                    .error(response.error, {
                        position: "bottom-left",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                    })
            }
            setIsDataFetched(true);
            setIsLoaderLoading(false);
            toast.success("Data added successfully!!", {
                position: "bottom-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return response.data;
        } catch (e) {
            setIsDataFetched(false);
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    useEffect(() => {
        async function FetchData() {
            try {
                if (!isDataFetched) {
                    let resp = await FetchResponse();

                    const result = resp[0];
                    setWorkoutID(result.WorkoutID)
                    SetLevel(result.Level)
                    setTimeDuration(result.TimeDuration)
                    setNoOfAttempts(result.NoOfAttempts)
                    setIsUnlimitedAttempts(result.IsUnlimitedAttempts)
                    setIsPaid(result.IsPaid)
                    setFrequency(result.Frequency)
                    setGoal(result.Goal)
                    SetThreshold(result.Threshold)
                    setIsActive(result.IsActive)
                    setChallengeThumbURL(result.ChallengeThumbURL)
                    setDescription(result.Description)
                    setDeviceType(result.DeviceType)
                }
            } catch (error) {
                toast.error(error.message, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }

        if (params.ID) {
            FetchData();
            setIsLoaderLoading(true);
            handleWorkoutIDSelectClick();
        }
    }, []);

    const submitHandler = (event) => {
        event.preventDefault();
        setIsLoading(true);

        const FetchApi = async () => {
            try {
                const Data = {
                    _id: params.ID,
                    WorkoutID: WorkoutID,
                    Level: +Level,
                    TimeDuration: +TimeDuration,
                    NoOfAttempts: +NoOfAttempts,
                    IsUnlimitedAttempts: IsUnlimitedAttempts,
                    IsPaid: IsPaid,
                    Frequency: Frequency,
                    Goal: +Goal,
                    Threshold: +Threshold,
                    ChallengeThumbURL: ChallengeThumbURL,
                    Description: Description,
                    IsActive: isActive,
                    DeviceType: DeviceType
                }

                let response = await GameLevelServices.addGameLevel(token, navigate, Data);

                setIsLoading(true);
                if (response.status === 207) {
                    return toast
                        .error(response.error, {
                            position: "bottom-left",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                        })
                }
                navigate(BASE_PATH_TO_GAMES_LIST);
                toast.success("Data added successfully!!", {
                    position: "bottom-left",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } catch (e) {
                setIsLoading(false);
                toast.error(e.message, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        };
        FetchApi();
    };

    const handleDescriptionChange = (e, locale) => {
        setDescription(prevState => ({
            ...prevState,
            [locale]: e.target.value
        }));
    };

    return (
        IsLoaderLoading ? <Loader /> : <div className={classes.crumbs}>
            <Breadcrumbs aria-label="breadcrumb" >
                <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                    Dashboard
                </Link>
                <Typography color="text.primary">
                    Game
                </Typography>
                <Typography color="text.primary">Update Game Level</Typography>
            </Breadcrumbs>
            <Card className={classes.input}>
                <div className={classes.Main_div}>Update Game Level</div>
                <form onSubmit={submitHandler}>
                    <div className={classes.selectbox}>
                        <label htmlFor="WorkoutID">Workout ID : </label>
                        <select
                            name="WorkoutID"
                            id="WorkoutID"
                            placeholder="Select One"
                            required
                            style={{ width: "300px" }}
                            onChange={(e) => setWorkoutID(e.target.value)}
                            value={WorkoutID}
                        >
                            {WorkoutIDAPI.map((Workout) => (
                                <option key={Workout.WorkoutID} value={Workout.WorkoutID}>
                                    {Workout.WorkoutID}.{Workout.WorkoutName}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="Level">Level :</label>
                        <input
                            type="number"
                            id="Level"
                            required
                            value={Level}
                            onChange={(e) => SetLevel(e.target.value)}
                            placeholder="Enter Level"
                        />
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="TimeDuration">Time Duration : </label>
                        <input
                            required
                            id="TimeDuration"
                            type="number"
                            placeholder="Enter time duration (in sec)"
                            value={TimeDuration}
                            onChange={(e) => setTimeDuration(e.target.value)}
                        />
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="NoOfAttempts">No Of Attempts : </label>
                        <input
                            required
                            id="NoOfAttempts"
                            type="number"
                            placeholder="Enter maximum number of attempts"
                            value={NoOfAttempts}
                            onChange={(e) => setNoOfAttempts(e.target.value)}
                        />
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="Frequency">Frequency : </label>
                        <input
                            required
                            id="Frequency"
                            type="number"
                            placeholder="Enter Frequency"
                            value={Frequency}
                            onChange={(e) => setFrequency(e.target.value)}
                        />
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="Goal">Goal : </label>
                        <input
                            required
                            id="Goal"
                            type="number"
                            placeholder="Enter Goal"
                            value={Goal}
                            onChange={(e) => setGoal(e.target.value)}
                        />
                    </div>


                    <div className={classes.selectbox}>
                        <label htmlFor="Threshold">Threshold :</label>
                        <input
                            type="number"
                            id="Threshold"
                            required
                            value={Threshold}
                            onChange={(e) => SetThreshold(e.target.value)}
                            placeholder="Enter threshold"
                        />
                    </div>

                    <div className={classes.control}>
                        <label htmlFor="ChallengeThumbURL">Thumbnail URL : </label>
                        <input
                            id="ChallengeThumbURL"
                            type="text"
                            placeholder="Enter Thumbnail URL"
                            value={ChallengeThumbURL}
                            onChange={(e) => setChallengeThumbURL(e.target.value)}
                        />
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="IsUnlimitedAttempts">IsUnlimitedAttempts : </label>
                        <select
                            name="IsUnlimitedAttempts"
                            id="IsUnlimitedAttempts"
                            value={IsUnlimitedAttempts}
                            onChange={(e) => setIsUnlimitedAttempts(e.target.value)}
                        >
                            <option value="false">False</option>
                            <option value="true">True</option>
                        </select>
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="IsPaid">IsPaid : </label>
                        <select
                            name="IsPaid"
                            id="IsPaid"
                            value={IsPaid}
                            onChange={(e) => {
                                setIsPaid(e.target.value);
                            }}
                        >
                            <option value="false">False</option>
                            <option value="true">True</option>
                        </select>
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="IsActive">IsActive : </label>
                        <select
                            name="IsActive"
                            id="IsActive"
                            value={isActive}
                            onChange={(e) => setIsActive(e.target.value)}
                        >
                            <option value="false">False</option>
                            <option value="true">True</option>
                        </select>
                    </div>

                    <div className={classes.selectbox} >
                        <label htmlFor="DeviceType">DeviceType : </label>
                        <select
                            name="DeviceType"
                            id="DeviceType"
                            value={DeviceType}
                            onChange={(e) => setDeviceType(e.target.value)}
                        >
                            <option value="All">All</option>
                            <option value="IOS">IOS</option>
                            <option value="Android">Android</option>
                        </select>
                    </div>

                    <div className={classes.subfields}>
                        <h3>Descriptions</h3>
                        <div className={classes.subfields}>
                            <div className={classes.control}>
                                <label htmlFor="en-US">Description (en-US)</label>
                                <input
                                    type="text"
                                    id="en-US"
                                    value={Description["en-US"]}
                                    onChange={(e) => handleDescriptionChange(e, "en-US")}
                                    placeholder="Enter Description for en-US"
                                />
                            </div>
                            <div className={classes.control}>
                                <label htmlFor="en-IN">Description (en-IN)</label>
                                <input
                                    type="text"
                                    id="en-IN"
                                    value={Description["en-IN"]}
                                    onChange={(e) => handleDescriptionChange(e, "en-IN")}
                                    placeholder="Enter Description for en-IN"
                                />
                            </div>
                            <div className={classes.control}>
                                <label htmlFor="en-CA">Description (en-CA)</label>
                                <input
                                    type="text"
                                    id="en-CA"
                                    value={Description["en-CA"]}
                                    onChange={(e) => handleDescriptionChange(e, "en-CA")}
                                    placeholder="Enter Description for en-CA"
                                />
                            </div>
                        </div>
                    </div>

                    {!isLoading && <Button type="submit">Update</Button>}
                    {isLoading && <p color="white">Sending request...</p>}
                </form>
            </Card>
        </div>
    );
};

export default UpdateGameLevel;
