import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import classes from "../../Point.module.css";
import AUTH_CONTEXT from "../../../../store";
import Button from "../../../../components/Button/Button";
import Card from "../../../../components/Card/Card";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";
import { ThresholdServices } from "../../../../services/point_services";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import Loader from "../../../../components/Loader/Loader";
import { BASE_PATH_TO_ADD_THRESHOLD_POINT, BASE_PATH_TO_UPDATE_THRESHOLD_POINT } from "../../../../base_routes_paths/points_paths";

const ThresholdConfig = (props) => {
    const navigate = useNavigate();
    const [threshold, setThreshold] = useState("")
    const { token } = AUTH_CONTEXT();
    const [isLoading, setIsLoading] = useState(false);

    const FetchResponse = async () => {
        try {
            let response = await ThresholdServices.getThreshold(token, navigate);
            if (response.status === 207) {
                return toast.error(response.error, {
                    position: "bottom-left",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                });
            }
            setIsLoading(true)
            toast.success("Data Fetched successfully!!", {
                position: "bottom-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setThreshold(response.data.threshold)
        } catch (e) {
            setIsLoading(false)
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    useEffect(() => {
        FetchResponse();
    }, []);

    return (
        !isLoading ? <Loader /> :
            <div className={classes.crumbs}>
                <Breadcrumbs aria-label="breadcrumb" >
                    <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                        Dashboard
                    </Link>
                    <Typography color="text.primary">
                        Points
                    </Typography>
                    <Typography color="text.primary">Threshold Config</Typography>
                </Breadcrumbs>
                <Card className={classes.input}>
                    <div className={classes.Main_div}>Threshold for Redeemption</div>
                    <div className={classes.control}>
                        <label htmlFor="threshold">Threshold Value : </label>
                        <input
                            type="number"
                            disabled
                            value={threshold}
                        />
                    </div>
                    <div style={{ display: "flex" }}>
                        <div>
                            <Button
                                onClick={() => {
                                    navigate(BASE_PATH_TO_UPDATE_THRESHOLD_POINT);
                                }}
                            >
                                Edit Threshold
                            </Button>
                        </div>
                        <div style={{ marginLeft: "1rem" }}>
                            <Button
                                onClick={() => {
                                    navigate(BASE_PATH_TO_ADD_THRESHOLD_POINT);
                                }}

                            >
                                Add New Threshold
                            </Button>
                        </div>
                    </div>
                </Card>
            </div>
    );
};

export default ThresholdConfig;
