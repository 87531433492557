import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import classes from "../../Auth.module.css";
import AUTH_CONTEXT from "../../../../store";
import Card from "../../../../components/Card/Card";
import Button from "../../../../components/Button/Button";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";
import { CommunityURLServices } from "../../../../services/auth_services";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { BASE_PATH_TO_VIEW_COMMUNITY_LINK } from "../../../../base_routes_paths/auth_paths";
import { TrimInput } from "../../../../utils";

const CommunityUrlConfig = () => {
    const navigate = useNavigate();
    const [url, setUrl] = useState();
    const { token } = AUTH_CONTEXT()
    const [isLoading, setIsLoading] = useState(false);
    const SubmitHandler = (event) => {
        event.preventDefault();
        setIsLoading(true);

        const FetchResponse = async () => {
            try {
                let response = await CommunityURLServices.addCommunityLink(token, navigate, url);

                setIsLoading(false);
                if (response.status === 207) {
                    return toast
                        .error(response.error, {
                            position: "bottom-left",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                        })
                }
                navigate(BASE_PATH_TO_VIEW_COMMUNITY_LINK);
                toast.success("Data added successfully!!", {
                    position: "bottom-left",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } catch (e) {
                setIsLoading(false);
                toast.error(e.message, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        };
        FetchResponse();
    };

    return (
        <div className={classes.crumbs}>
            <Breadcrumbs aria-label="breadcrumb" >
                <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                    Dashboard
                </Link>
                <Typography color="text.primary">
                    Auth
                </Typography>
                <Typography color="text.primary">Add Community Link</Typography>
            </Breadcrumbs>
            <Card className={classes.input}>
                <div className={classes.Main_div}>Add WhatsApp Community Link</div>
                <form onSubmit={SubmitHandler}>
                    <div className={classes.control}>
                        <label htmlFor="Url">Community Link</label>
                        <input
                            type="text"
                            id="Url"
                            required
                            value={url}
                            onChange={(e) => { setUrl(TrimInput(e.target.value)) }}
                            placeholder="Enter Community Url"
                        />
                    </div>
                    {!isLoading && <Button type="submit">Add</Button>}
                    {isLoading && <p color="white">Sending request...</p>}
                </form>
            </Card>
        </div>
    );
};

export default CommunityUrlConfig;
