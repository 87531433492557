import { toast } from "react-toastify";
import ARENO_API_ENDPOINT from "../../api";
import { StatusCode } from "../../utils";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const PurchaseServices = {
    getAllPointPurchaseVouchers: async (token, navigate, ID) => {
        try {
            let response = await fetch(`${ARENO_API_ENDPOINT}/purchase/AdminGetAllPointPurchaseVouchers`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    _id: ID
                })
            });

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    addPointPurchaseVouchers: async (token, navigate, point, AndroidproductId, IOSproductId, PointVoucherImageLink, price, Country, IsActive, SortID, Amount) => {
        try {
            let response = await fetch(`${ARENO_API_ENDPOINT}/purchase/AdminAddPointPurchaseVoucher`, {
                method: "POST",
                body: JSON.stringify({
                    Point: point,
                    AndroidproductId: AndroidproductId,
                    IOSproductId: IOSproductId,
                    PointVoucherImageLink: PointVoucherImageLink,
                    Price: price,
                    Country: Country,
                    IsActive: IsActive,
                    SortID: SortID,
                    amount: Amount
                }),
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${token}`,
                },
            });
            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    updatePointPurchaseVouchers: async (token, navigate, ID, Data) => {
        try {
            let response = await fetch(`${ARENO_API_ENDPOINT}/purchase/AdminUpdatePointPurchaseVoucher`, {
                method: "POST",
                body: JSON.stringify({
                    _id: ID,
                    Data: Data
                }),
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${token}`,
                },
            });

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    deletePointPurchaseVouchers: async (id, token) => {
        confirmAlert({
            title: "Confirm to delete",
            message: "Are you sure you want to delete this?",
            buttons: [
                {
                    label: "Yes",
                    onClick: async () => {
                        try {
                            let response = await fetch(`${ARENO_API_ENDPOINT}/purchase/AdminDeletePointPurchaseVoucher`, {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                    authorization: `Bearer ${token}`,
                                },
                                body: JSON.stringify({
                                    _id: id,
                                }),
                            });

                            StatusCode(response);

                            const data = await response.json();

                            if (response.status === 200) {
                                toast.success("Voucher deleted successfully!", {
                                    position: "bottom-left",
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                                window.location.reload();
                            } else {
                                toast.error(data.error || "Failed to delete.", {
                                    position: "bottom-left",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                            }
                        } catch (error) {
                            toast.error(error.message || "An error occurred while deleting.", {
                                position: "bottom-left",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                        }
                    },
                },
                {
                    label: "No",
                },
            ],
        });
    },
}

const PhonePeServices = {
    getAllPhonePeInitData: async (token, navigate, ID) => {
        try {
            let response = await fetch(`${ARENO_API_ENDPOINT}/purchase/AdminGetAllPhonePeVoucherPayAPIInitData`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    _id: ID
                })
            });

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
}

export { PurchaseServices, PhonePeServices };