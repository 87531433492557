import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import classes from "../Point.module.css";
import AUTH_CONTEXT from "../../../store";
import Card from "../../../components/Card/Card";
import Button from "../../../components/Button/Button";
import { UserPointServices } from "../../../services/point_services";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { BASE_PATH_TO_HOME } from "../../../base_routes_paths/data_paths";

const AdminAddUserPointConfig = () => {
    const navigate = useNavigate();
    const ChallengeRef = useRef("");
    const MessageRef = useRef("");
    const UserIDRef = useRef("");
    const PointRef = useRef("");
    const { token } = AUTH_CONTEXT()
    const [isLoading, setIsLoading] = useState(false);

    const SubmitHandler = (event) => {
        event.preventDefault();
        let EnteredChallengeRef = ChallengeRef.current.value;
        let EnteredMessageRef = MessageRef.current.value;
        let EnteredPointRef = +PointRef.current.value;
        let EnteredUserIDRef = UserIDRef.current.value;
        setIsLoading(true);

        const FetchResponseSetReferrelConfig = async () => {
            try {
                let response = await UserPointServices.addUserPoint(token, navigate, EnteredChallengeRef, EnteredMessageRef, EnteredUserIDRef, EnteredPointRef);
                if (response.status === 207) {
                    return toast
                        .error(response.error, {
                            position: "bottom-left",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                        })
                }
                setIsLoading(false);
                navigate(`/GetUserDetails/${EnteredUserIDRef}`);
                toast.success("Data added successfully!!", {
                    position: "bottom-left",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                localStorage.removeItem("userID");
            } catch (e) {
                setIsLoading(false);
                toast.error(e.message, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        };
        FetchResponseSetReferrelConfig();
    };

    useEffect(() => {
        const UserID = localStorage.getItem("userID");

        if (UserID) {
            UserIDRef.current.value = UserID;

            setTimeout(() => {
                localStorage.removeItem("userID");
            }, 200);
        } else {
            UserIDRef.current.value = "";
        }
    }, []);


    return (
        <div className={classes.crumbs}>
            <Breadcrumbs aria-label="breadcrumb" >
                <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                    Dashboard
                </Link>
                <Typography color="text.primary">
                    Points
                </Typography>
                <Typography color="text.primary">Add User Point</Typography>
            </Breadcrumbs>
            <Card className={classes.input}>
                <div className={classes.Main_div}>Add User Point</div>
                <form onSubmit={SubmitHandler}>
                    <div className={classes.control}>
                        <label htmlFor="Challenge">Description</label>
                        <input
                            type="text"
                            id="Challenge"
                            required
                            ref={ChallengeRef}
                            placeholder="Enter challenge or Despcription"
                        />
                    </div>
                    <div className={classes.control}>
                        <label htmlFor="Message">Message</label>
                        <input
                            type="text"
                            id="Message"
                            required
                            ref={MessageRef}
                            placeholder="Write a message"
                        />
                    </div>
                    <div className={classes.control}>
                        <label htmlFor="UserID">UserID</label>
                        <input
                            type="text"
                            id="UserID"
                            required
                            defaultValue={UserIDRef.current.value}
                            ref={UserIDRef}
                            placeholder="Enter UserID"
                        />
                    </div>

                    <div className={classes.control}>
                        <label htmlFor="Point">Point</label>
                        <input
                            type="number"
                            id="Point"
                            required
                            ref={PointRef}
                            placeholder="Enter points to be added"
                        />
                    </div>
                    {!isLoading && <Button type="submit">Add</Button>}
                    {isLoading && <p color="white">Sending request...</p>}
                </form>
            </Card>
        </div>
    );
};

export default AdminAddUserPointConfig;
