import React, { useState } from "react";
import cardClasses from "../../Data.module.css";
import { BASE_PATH_TO_HOME, BASE_PATH_TO_LEADERBOARD } from "../../../../base_routes_paths/data_paths";
import Card from "../../../../components/Card/Card";
import Button from "../../../../components/Button/Button";
import { Breadcrumbs, Link, Typography } from "@mui/material";

const LeaderBoardInput = () => {
    const [id, setId] = useState("");
    return (
        <div className={cardClasses.crumbs}>
            <Breadcrumbs aria-label="breadcrumb" >
                <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                    Dashboard
                </Link>
                <Typography color="text.primary">
                    Data
                </Typography>
                <Typography color="text.primary">Leaderboard</Typography>
            </Breadcrumbs>
            <Card className={cardClasses.input}>
                <div className={cardClasses.Main_div}>Enter Challenge ID</div>
                <div>
                    <label htmlFor="challenge-id">ChallengeID : </label>
                    <input
                        id="challenge-id"
                        type="text"
                        placeholder="Enter challenge ID"
                        onChange={(e) => {
                            setId(e.target.value);
                        }}
                        value={id}
                    />
                </div>
                <Link
                    href={`${BASE_PATH_TO_LEADERBOARD}/${id}`}
                    style={{ textDecoration: "none", color: "#4f005f" }}
                >
                    <Button>Submit</Button>
                </Link>
            </Card>
        </div>
    );
};

export default LeaderBoardInput;
