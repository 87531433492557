import DashboardPageLayout from "../layouts/dashboard_layout";
import { ProtectedRoutes } from "./protected_route";
import CommunityUrlConfig from "../pages/auth_service/community_link/add_community_link";
import VersionConfig from "../pages/auth_service/version/version_config_view";
import SpecialReferral from "../pages/auth_service/special_referral/special_referral_list";
import OSQuestions from "../pages/auth_service/onboarding_screen_questions/view_onboarding_screen_questions";
import OSRecommendations from "../pages/auth_service/onboarding_screen_recommendations/view_onboarding_screen_recommendations";
import IpAddressList from "../pages/auth_service/ip_address/ip_address_list";
import { BASE_PATH_TO_ADD_COMMUNITY_LINK, BASE_PATH_TO_ADD_IP_ADDRESS, BASE_PATH_TO_ADD_ONBOARDING_SCREEN_QUESTIONS, BASE_PATH_TO_ADD_ONBOARDING_SCREEN_RECOMMENDATIONS, BASE_PATH_TO_ADD_SPECIAL_REFERRAL, BASE_PATH_TO_ADD_VERSION_CONFIG, BASE_PATH_TO_SPECIAL_REFERRAL_LIST, BASE_PATH_TO_UPDATE_COMMUNITY_LINK, BASE_PATH_TO_UPDATE_IP_ADDRESS, BASE_PATH_TO_UPDATE_ONBOARDING_SCREEN_QUESTIONS, BASE_PATH_TO_UPDATE_ONBOARDING_SCREEN_RECOMMENDATIONS, BASE_PATH_TO_UPDATE_SPECIAL_REFERRAL, BASE_PATH_TO_UPDATE_VERSION_CONFIG, BASE_PATH_TO_VIEW_COMMUNITY_LINK, BASE_PATH_TO_VIEW_IP_ADDRESS, BASE_PATH_TO_VIEW_ONBOARDING_SCREEN_QUESTIONS, BASE_PATH_TO_VIEW_ONBOARDING_SCREEN_RECOMMENDATIONS, BASE_PATH_TO_VIEW_VERSION_CONFIG } from "../base_routes_paths/auth_paths";
import AddVersionConfig from "../pages/auth_service/version/add_version_config";
import UpdateVersionUrlConfig from "../pages/auth_service/version/update_version_config";
import AddSpecialReferral from "../pages/auth_service/special_referral/add_special_referral";
import UpdateSpecialReferral from "../pages/auth_service/special_referral/update_special_referra;";
import UpdateOSQuestions from "../pages/auth_service/onboarding_screen_questions/update_onboarding_screen_recommendations";
import CreateOSQuestions from "../pages/auth_service/onboarding_screen_questions/add_onboarding_screen_questions";
import UpdateOSRecommendations from "../pages/auth_service/onboarding_screen_recommendations/update_onboarding_screen_recommendations";
import CreateOSRecommendations from "../pages/auth_service/onboarding_screen_recommendations/add_onboarding_screen_recommendations";
import AddIpAddress from "../pages/auth_service/ip_address/add_ip_address";
import UpdateIpAddress from "../pages/auth_service/ip_address/update_ip_address";
import ViewCommunityUrlConfig from "../pages/auth_service/community_link/community_link";
import UpdateCommunityUrlConfig from "../pages/auth_service/community_link/update_community_link";

const AuthRoutes = {
    path: "/auth",
    element: (
        <ProtectedRoutes>
            <DashboardPageLayout />
        </ProtectedRoutes>
    ),
    children: [
        {
            path: BASE_PATH_TO_VIEW_COMMUNITY_LINK,
            element: <ViewCommunityUrlConfig />,
        },
        {
            path: BASE_PATH_TO_UPDATE_COMMUNITY_LINK,
            element: <UpdateCommunityUrlConfig />,
        },
        {
            path: BASE_PATH_TO_ADD_COMMUNITY_LINK,
            element: <CommunityUrlConfig />,
        },
        {
            path: BASE_PATH_TO_VIEW_VERSION_CONFIG,
            element: <VersionConfig />,
        },
        {
            path: BASE_PATH_TO_ADD_VERSION_CONFIG,
            element: <AddVersionConfig />,
        },
        {
            path: BASE_PATH_TO_UPDATE_VERSION_CONFIG,
            element: <UpdateVersionUrlConfig />,
        },
        {
            path: BASE_PATH_TO_SPECIAL_REFERRAL_LIST,
            element: <SpecialReferral />,
        },
        {
            path: BASE_PATH_TO_ADD_SPECIAL_REFERRAL,
            element: <AddSpecialReferral />,
        },
        {
            path: `${BASE_PATH_TO_UPDATE_SPECIAL_REFERRAL}/:ID`,
            element: <UpdateSpecialReferral />,
        },
        {
            path: BASE_PATH_TO_VIEW_ONBOARDING_SCREEN_QUESTIONS,
            element: <OSQuestions />,
        },
        {
            path: BASE_PATH_TO_ADD_ONBOARDING_SCREEN_QUESTIONS,
            element: <CreateOSQuestions />,
        },
        {
            path: `${BASE_PATH_TO_UPDATE_ONBOARDING_SCREEN_QUESTIONS}/:ID`,
            element: <UpdateOSQuestions />,
        },
        {
            path: BASE_PATH_TO_VIEW_ONBOARDING_SCREEN_RECOMMENDATIONS,
            element: <OSRecommendations />,
        },
        {
            path: BASE_PATH_TO_ADD_ONBOARDING_SCREEN_RECOMMENDATIONS,
            element: <CreateOSRecommendations />,
        },
        {
            path: `${BASE_PATH_TO_UPDATE_ONBOARDING_SCREEN_RECOMMENDATIONS}/:ID`,
            element: <UpdateOSRecommendations />,
        },
        {
            path: BASE_PATH_TO_VIEW_IP_ADDRESS,
            element: <IpAddressList />,
        },
        {
            path: BASE_PATH_TO_ADD_IP_ADDRESS,
            element: <AddIpAddress />,
        },
        {
            path: BASE_PATH_TO_UPDATE_IP_ADDRESS,
            element: <UpdateIpAddress />,
        },
    ],
};

export default AuthRoutes;
