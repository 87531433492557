import { useState, useEffect } from "react";
import { GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import { Link, useNavigate, useParams } from "react-router-dom";
import classes from "../Redeemption.module.css";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { toast } from "react-toastify";
import { Box, Button } from "@mui/material";
import Loader from "../../../../components/Loader/Loader";
import AUTH_CONTEXT from "../../../../store";
import { BASE_PATH_TO_UPDATE_REDEEM, BASE_PATH_TO_USER_DETAILS } from "../../../../base_routes_paths/data_paths";
import { RedeemService } from "../../../../services/redeem_services";

const RedeemUserDetailsList = () => {
    const { token, fileName } = AUTH_CONTEXT();
    const userColumns = [
        { field: "id", headerName: "ID", width: 230 },
        {
            field: "Voucher_image",
            headerName: "Voucher",
            width: 230,
            renderCell: (params) => {
                return (
                    <div className={classes.cellWithImg}>
                        <img
                            className={classes.cellImg}
                            src={
                                params.row.Voucher_image ||
                                "https://areno.in/img/group-1@2x.svg"
                            }
                            alt=""
                        />
                        {params.row.Voucher_title}
                    </div>
                );
            },
        },
        {
            field: "Voucher_id",
            headerName: "VoucherID",
            width: 230,
        },
        {
            field: "User_id",
            headerName: "UserID",
            width: 230,
            renderCell: (params) => {
                return (
                    <Link to={`${BASE_PATH_TO_USER_DETAILS}/` + params.row.User_id}>
                        {params.row.User_id}
                    </Link>
                );
            },
        },
        {
            field: "User_name",
            headerName: "Username",
            width: 180,
        },
        {
            field: "Mobile_number",
            headerName: "Mobile",
            width: 130,
        },
        {
            field: "Email",
            headerName: "Email",
            width: 130,
        },
        {
            field: "Voucher_points",
            headerName: "Points",
            width: 80,
        },
        {
            field: "IsReadyToShareWithUser",
            headerName: "IsReadyToShareWithUser",
            width: 180,
        },
        {
            field: "IsSharedWithUser",
            headerName: "IsSharedWithUser",
            width: 180,
        },
        {
            field: "IsSharedViaMail",
            headerName: "IsSharedViaMail",
            width: 180,
        },
        {
            field: "VoucherPin",
            headerName: "VoucherPin",
            width: 180,
        },
        {
            field: "MessageID",
            headerName: "MessageID",
            width: 180,
        },
        {
            field: "Voucher_category",
            headerName: "Category",
            width: 80,
        },
        {
            field: "RedeemptionDetails",
            headerName: "Voucher Link",
            width: 400,
        },
        {
            field: "Date",
            headerName: "Redeemption Date",
            width: 160,
        },
        {
            field: "Time",
            headerName: "Redeemption Time",
            width: 160,
        },
    ];

    const [isDataFetched, setIsDataFetched] = useState(false);
    const [data, setData] = useState([]);
    const EXPORT_FILENAME = `${fileName}_RedeemUserDetailsList`;
    const [isLoaderLoading, setIsLoaderLoading] = useState(false);
    const params = useParams();
    const Date = params.date;
    const navigate = useNavigate();
    useEffect(() => {
        const FetchResponseSetReferrelConfig = async () => {
            try {
                let response = await RedeemService.getRedeemData(token, navigate, Date);

                if (response.status === 207) {
                    return toast
                        .error(response.error, {
                            position: "bottom-left",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                        })
                }
                setIsDataFetched(true);
                let result = response.data.UsersDetails;
                let input = result.map(({ _id: id, ...result }) => ({
                    id,
                    ...result,
                }));
                setData(input);
                setIsLoaderLoading(false);
                toast.success("Data fetched successfully!!", {
                    position: "bottom-left",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } catch (e) {
                setIsDataFetched(false);
                toast.error(e.message, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        };
        //

        if (Date) {
            setIsLoaderLoading(true);
            FetchResponseSetReferrelConfig();
        } else {
            return;
        }
    }, []);

    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: 200,
            renderCell: (params) => {
                var Voucher = JSON.stringify({
                    RedeemptionDetails: params.row.RedeemptionDetails,
                    IsReadyToShareWithUser: params.row.IsReadyToShareWithUser,
                    VoucherPin: params.row.VoucherPin,
                });
                return (
                    <div className={classes.cellAction}>
                        <Link
                            to={`${BASE_PATH_TO_UPDATE_REDEEM}/` + params.row.id}
                            style={{ textDecoration: "none" }}
                            onClick={(params) => {
                                sessionStorage.setItem("Date", Date);
                                sessionStorage.setItem("Voucher", Voucher);
                            }}
                        >
                            <div className={classes.viewButton}>Edit</div>
                        </Link>
                    </div>
                );
            },
        },
    ];

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport
                    printOptions={{ disableToolbarButton: true }}
                    csvOptions={{ fileName: fileName }}
                    excelOptions={{ fileName: fileName }}
                />
                <div
                    style={{ marginLeft: "auto", display: "flex", alignItems: "center" }}
                >
                    <Box sx={{ color: "#4f005f" }}>
                        Do you want to distribute vouchers manually? Click here{" "}
                    </Box>{" "}
                    <Button
                        color="primary"
                        onClick={() => {
                            RedeemService.distributeVoucher(token);
                        }}
                        sx={{ marginLeft: 2, padding: 0 }}
                    >
                        <p
                            style={{
                                marginRight: "5px",
                                textTransform: "capitalize",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            {" "}
                            Distribute
                        </p>
                    </Button>
                </div>
            </GridToolbarContainer>
        );
    }

    return (
        <div>
            {isLoaderLoading === true ? (
                <Loader />
            ) : (
                <>
                    {isDataFetched && (
                        <div className={classes.datatable}>
                            <DataGridPremium
                                rows={data}
                                columns={userColumns.concat(actionColumn)}
                                pageSize={20}
                                rowsPerPageOptions={[20]}
                                slots={{
                                    toolbar: () => (
                                        <CustomToolbar
                                            EXPORT_FILENAME={EXPORT_FILENAME}
                                            onClickCreate={() => { }}
                                            displayCreate={""}
                                            onClickUpdate={() => { }}
                                            displayUpdate={"none"}
                                        />
                                    ),
                                }}
                                pagination
                                initialState={{
                                    pagination: {
                                        paginationModel: { pageSize: 25, page: 0 },
                                    },
                                }}
                                disableRowSelectionOnClick
                                checkboxSelectionVisibleOnly
                                checkboxSelection
                            />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default RedeemUserDetailsList;
