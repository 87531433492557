import { useState } from "react";
import { useNavigate } from "react-router-dom";
import classes from "../../Purchase.module.css";
import { toast } from "react-toastify";
import AUTH_CONTEXT from "../../../../store";
import Card from "../../../../components/Card/Card";
import Button from "../../../../components/Button/Button";
import { BASE_PATH_TO_POINT_PURCHASE_VOUCHERS_LIST } from "../../../../base_routes_paths/purchase_paths";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";
import { PurchaseServices } from "../../../../services/purchase_services";

const AddPointPurchaseVoucher = () => {
    const navigate = useNavigate();
    const [AndroidproductId, setAndroidproductId] = useState("");
    const [IOSproductId, setIOSproductId] = useState("");
    const [PointVoucherImageLink, setPointVoucherImageLink] = useState("");
    const [point, setPoint] = useState("");
    const [SortID, setSortID] = useState("");
    const [Amount, setAmount] = useState(0);
    const [price, setPrice] = useState("");
    const [Country, setCountry] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [IsActive, setIsActive] = useState(true);
    const { token } = AUTH_CONTEXT();

    const SubmitHandler = (event) => {
        event.preventDefault();
        setIsLoading(true);

        const FetchApi = async () => {
            try {
                let response = await PurchaseServices.addPointPurchaseVouchers(token, navigate, point, AndroidproductId, IOSproductId, PointVoucherImageLink, price, Country, IsActive, SortID, Amount);
                if (response.status === 207) {
                    return toast
                        .error(response.error, {
                            position: "bottom-left",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                        })
                }
                setIsLoading(false);
                navigate(BASE_PATH_TO_POINT_PURCHASE_VOUCHERS_LIST);
                toast.success("Data added successfully!!", {
                    position: "bottom-left",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } catch (e) {
                setIsLoading(false);
                toast.error(e.message, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        };
        FetchApi();
    };

    return (
        <div className={classes.crumbs}>
            <Breadcrumbs aria-label="breadcrumb" >
                <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                    Dashboard
                </Link>
                <Typography color="text.primary">
                    Purchase
                </Typography>
                <Typography color="text.primary">Add Point Purchase Voucher</Typography>
            </Breadcrumbs>
            <Card className={classes.input}>
                <div className={classes.Main_div}>Add Point Purchase Voucher</div>
                <form onSubmit={SubmitHandler}>
                    <div className={classes.control}>
                        <label htmlFor="SortID">SortID</label>
                        <input
                            type="number"
                            id="SortID"
                            required
                            value={SortID}
                            onChange={(e) => setSortID(parseInt(e.target.value))}
                            placeholder="Enter SortID"
                        />
                    </div>

                    <div className={classes.control}>
                        <label htmlFor="AndroidproductId">AndroidproductId</label>
                        <input
                            type="text"
                            id="AndroidproductId"
                            required
                            value={AndroidproductId}
                            onChange={(e) => setAndroidproductId(e.target.value)}
                            placeholder="Enter AndroidproductId"
                        />
                    </div>

                    <div className={classes.control}>
                        <label htmlFor="IOSproductId">IOSproductId</label>
                        <input
                            type="text"
                            id="IOSproductId"
                            required
                            value={IOSproductId}
                            onChange={(e) => setIOSproductId(e.target.value)}
                            placeholder="Enter IOSproductId"
                        />
                    </div>

                    <div className={classes.control}>
                        <label htmlFor="Point">Point</label>
                        <input
                            type="number"
                            id="Point"
                            required
                            value={point}
                            onChange={(e) => setPoint(parseInt(e.target.value))}
                            placeholder="Enter point"
                        />
                    </div>

                    <div className={classes.control}>
                        <label htmlFor="Price">Price</label>
                        <input
                            type="text"
                            id="Price"
                            required
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                            placeholder="Enter price of voucher"
                        />
                    </div>

                    <div className={classes.control}>
                        <label htmlFor="Amount">Amount</label>
                        <input
                            type="number"
                            id="Amount"
                            required
                            value={Amount}
                            onChange={(e) => setAmount(parseInt(e.target.value))}
                            placeholder="Enter Amount"
                        />
                    </div>

                    <div className={classes.control}>
                        <label htmlFor="Country">Country</label>
                        <input
                            type="text"
                            id="Country"
                            required
                            value={Country}
                            onChange={(e) => setCountry(e.target.value)}
                            placeholder="Enter Country code( eg: IN / US)"
                        />
                    </div>

                    <div className={classes.control}>
                        <label htmlFor="PointVoucherImageLink">PointVoucherImageLink</label>
                        <input
                            type="url"
                            id="PointVoucherImageLink"
                            required
                            value={PointVoucherImageLink}
                            onChange={(e) => setPointVoucherImageLink(e.target.value)}
                            placeholder="Enter PointVoucherImageLink"
                        />
                    </div>

                    <div className={classes.selectbox} style={{ marginBottom: "1rem" }}>
                        <label htmlFor="IsActive">IsActive : </label>
                        <select
                            name="IsActive"
                            id="IsActive"
                            value={IsActive}
                            onChange={(e) => setIsActive(e.target.value)}
                        >
                            <option value="false">False</option>
                            <option value="true">True</option>
                        </select>
                    </div>

                    {!isLoading && <Button type="submit">Add</Button>}
                    {isLoading && <p color="white">Sending request...</p>}
                </form>
            </Card>
        </div>
    );
};

export default AddPointPurchaseVoucher;
