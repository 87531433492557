import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import classes from "../../Data.module.css";
import { toast } from "react-toastify";
import AUTH_CONTEXT from "../../../../store";
import Card from "../../../../components/Card/Card";
import Button from "../../../../components/Button/Button";
import { BASE_PATH_TO_REDEEM_DATA } from "../../../../base_routes_paths/data_paths";
import { RedeemService } from "../../../../services/redeem_services";

const UpdateRedeemptionData = () => {
    const Date = sessionStorage.getItem("Date");
    const Voucher = sessionStorage.getItem("Voucher");
    const navigate = useNavigate();
    const params = useParams();
    const { token } = AUTH_CONTEXT();
    const [RedeemptionDetails, setRedeemptionDetails] = useState("");
    const [IsReadyToShareWithUser, setIsReadyToShareWithUser] = useState(false);
    const [VoucherPin, setVoucherPin] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (Voucher) {
            const parsedVoucher = JSON.parse(Voucher);
            setRedeemptionDetails(parsedVoucher.RedeemptionDetails || "");
            setIsReadyToShareWithUser(parsedVoucher.IsReadyToShareWithUser);
            setVoucherPin(parsedVoucher.VoucherPin || "");
        }
    }, [Voucher]);

    const SubmitHandler = (event) => {
        event.preventDefault();
        setIsLoading(true);

        const Data = {
            RedeemptionID: params.ID,
            RedeemptionDetails,
            VoucherPin,
            IsReadyToShareWithUser,
        }

        const FetchResponseSetReferrelConfig = async () => {
            try {
                let response = await RedeemService.updateRedeemData(token, navigate, Data);

                setIsLoading(false);
                if (response.status === 207) {
                    return toast
                        .error(response.error, {
                            position: "bottom-left",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                        })
                }
                navigate(`${BASE_PATH_TO_REDEEM_DATA}/${Date}`);
                toast.success("Data added successfully!!", {
                    position: "bottom-left",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } catch (e) {
                setIsLoading(false);
                toast.error(e.message, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        };
        FetchResponseSetReferrelConfig();
    };

    return (
        <Card className={classes.input}>
            <div className={classes.Main_div}>Update Redeemption details</div>
            <form onSubmit={SubmitHandler}>
                <div className={classes.control}>
                    <label htmlFor="link">Link: </label>
                    <input
                        type="text"
                        id="link"
                        required
                        value={RedeemptionDetails}
                        onChange={(e) => setRedeemptionDetails(e.target.value)}
                        placeholder="Enter voucher link"
                    />
                </div>
                <div className={classes.selectbox}>
                    <label htmlFor="IsReadyToShareWithUser">IsReadyToShareWithUser</label>
                    <select
                        name="IsReadyToShareWithUser"
                        id="Category"
                        onChange={(e) => {
                            setIsReadyToShareWithUser(e.target.value === "true");
                        }}
                        value={IsReadyToShareWithUser ? "true" : "false"}
                    >
                        <option value={"false"}>No</option>
                        <option value={"true"}>Yes</option>
                    </select>
                </div>

                <div className={classes.control}>
                    <label htmlFor="VoucherPin">VoucherPin: </label>
                    <input
                        type="text"
                        id="VoucherPin"
                        value={VoucherPin}
                        onChange={(e) => setVoucherPin(e.target.value)}
                        placeholder="Enter voucher VoucherPin"
                    />
                </div>
                {!isLoading && <Button type="submit">Add</Button>}
                {isLoading && <p color="white">Sending request...</p>}
            </form>
        </Card>
    );
};

export default UpdateRedeemptionData;
