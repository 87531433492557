import { useState, useEffect } from "react";
import {
    DataGridPremium,
} from "@mui/x-data-grid-premium";
import { useNavigate } from "react-router-dom";
import classes from "../../Purchase.module.css";
import { toast } from "react-toastify";
import AUTH_CONTEXT from "../../../../store";
import Loader from "../../../../components/Loader/Loader";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";
import CustomToolbar from "../../../../components/toolbar";
import { PhonePeServices, PurchaseServices } from "../../../../services/purchase_services";

const PhonePePaymentInitList = () => {
    const userColumns = [
        { field: "UserID", headerName: "UserID", width: 230 },
        {
            field: "merchantId",
            headerName: "merchantId",
            width: 230,
        },
        {
            field: "merchantUserId",
            headerName: "merchantUserId",
            width: 230,
        },
        {
            field: "merchantTransactionId",
            headerName: "merchantTransactionId",
            width: 230,
        },
        {
            field: "amount",
            headerName: "amount",
            width: 230,
        },
        {
            field: "points",
            headerName: "points",
            width: 180,
        },
        {
            field: "status",
            headerName: "status",
            width: 180,
        },
        {
            field: "IsPointAdded",
            headerName: "IsPointAdded",
            width: 100,
        },
        {
            field: "paymentInstrument",
            headerName: "paymentInstrument",
            width: 230,
            renderCell: (params) => {
                return (
                    <div>
                        {params.row.paymentInstrument.type}
                    </div>
                );
            },
        },
    ];

    const { token, fileName } = AUTH_CONTEXT();
    const EXPORT_FILENAME = `${fileName}_PointPurchaseVouchers`;
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [data, setData] = useState([]);
    const navigate = useNavigate()

    const FetchApi = async () => {
        try {
            let response = await PhonePeServices.getAllPhonePeInitData(token, navigate, null);
            if (response.status === 207) {
                return toast
                    .error(response.error, {
                        position: "bottom-left",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                    })
            }
            let result = response.data;
            let input = result.map(({ _id: id, ...result }) => ({
                id,
                ...result,
            }));
            setData(input);
            setIsDataFetched(true);
            toast.success("Data fetched successfully!!", {
                position: "bottom-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } catch (e) {
            setIsDataFetched(false);
            toast.error(e, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    useEffect(() => {
        if (!isDataFetched) {
            FetchApi();
        }
    }, []);

    const handleDelete = (id) => {
        PurchaseServices.deletePointPurchaseVouchers(id, token);
    };

    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: 200,
            renderCell: (params) => {
                return (
                    <div className={classes.cellAction}>
                        <div>
                            <Link
                                // href={`${BASE_PATH_TO_UPDATE_POINT_PURCHASE_VOUCHERS}/` + params.row.id}
                                style={{ textDecoration: "none" }}
                            >
                                <div className={classes.viewButton}>Update</div>
                            </Link>
                        </div>
                        <div
                            className={classes.deleteButton}
                            onClick={() => handleDelete(params.row.id)}
                        >
                            Delete
                        </div>
                    </div>
                );
            },
        },
    ];

    return (
        <div>
            {isDataFetched && (
                <div className={classes.datatable}>
                    <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 1 }}>
                        <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                            Dashboard
                        </Link>
                        <Typography color="text.primary">
                            Redeem
                        </Typography>
                        <Typography color="text.primary">PhonePe Vouchers Payment List</Typography>
                    </Breadcrumbs>
                    <DataGridPremium
                        rows={data}
                        columns={userColumns}
                        slots={{
                            toolbar: () => (
                                <CustomToolbar
                                    EXPORT_FILENAME={EXPORT_FILENAME}
                                    displayCreate={"none"}
                                    onClickUpdate={() => { }}
                                    displayUpdate={"none"}
                                />
                            ),
                        }}
                        pagination
                        initialState={{
                            pagination: {
                                paginationModel: { pageSize: 25, page: 0 },
                            },
                        }}
                        disableRowSelectionOnClick
                        checkboxSelectionVisibleOnly
                        checkboxSelection
                    />
                </div>
            )}
            {!isDataFetched && <Loader />}
        </div>
    );
};

export default PhonePePaymentInitList;
