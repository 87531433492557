import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import classes from "../../Auth.module.css";
import { toast } from "react-toastify";
import AUTH_CONTEXT from "../../../../store";
import Card from "../../../../components/Card/Card";
import Button from "../../../../components/Button/Button";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";
import { SpecialReferraServices } from "../../../../services/auth_services";
import { BASE_PATH_TO_SPECIAL_REFERRAL_LIST } from "../../../../base_routes_paths/auth_paths";

const AddSpecialReferral = () => {
    const navigate = useNavigate();
    const ReferralLimitRef = useRef();
    const [referralCode, setReferralCode] = useState("");
    const [PointForReferralUser, setPointForReferralUser] = useState("");
    const [PointForReferredUser, setPointForReferredUser] = useState("");
    const { token } = AUTH_CONTEXT();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const code = localStorage.getItem("ReferralCode");
        if (code) {
            setReferralCode(code);
            localStorage.removeItem("ReferralCode");
        }
    }, []);

    const SubmitHandler = (event) => {
        event.preventDefault();
        setIsLoading(true);

        const Data = {
            ReferralLimit: +ReferralLimitRef.current.value,
            ReferralCode: referralCode,
            PointForReferralUser: +PointForReferralUser,
            PointForReferredUser: +PointForReferredUser
        }

        const FetchResponse = async () => {
            try {
                let response = await SpecialReferraServices.addSpecialReferral(token, navigate, Data);
                if (response.status === 207) {
                    return toast
                        .error(response.error, {
                            position: "bottom-left",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                        })
                }
                navigate(BASE_PATH_TO_SPECIAL_REFERRAL_LIST);
                toast.success("Data added successfully!!", {
                    position: "bottom-left",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                localStorage.removeItem("ReferralCode");
            } catch (e) {
                setIsLoading(false);
                toast.error(e.message, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        };
        FetchResponse();
    };

    return (
        <div className={classes.crumbs}>
            <Breadcrumbs aria-label="breadcrumb" >
                <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                    Dashboard
                </Link>
                <Typography color="text.primary">
                    Auth
                </Typography>
                <Typography color="text.primary">Add Special Referral</Typography>
            </Breadcrumbs>
            <Card className={classes.input}>
                <div className={classes.Main_div}>Add Special Referral Configuration</div>
                <form onSubmit={SubmitHandler}>
                    <div className={classes.control}>
                        <label htmlFor="ReferralCode">Referral Code</label>
                        <input
                            type="text"
                            id="ReferralCode"
                            required
                            value={referralCode}
                            onChange={(e) => setReferralCode(e.target.value)}
                            placeholder="Enter Referral Code"
                        />
                    </div>
                    <div className={classes.control}>
                        <label htmlFor="ReferralLimit">Referral Limit</label>
                        <input
                            type="number"
                            id="ReferralLimit"
                            required
                            ref={ReferralLimitRef}
                            placeholder="Enter Referral Limit for a User"
                        />
                    </div>
                    <div className={classes.control}>
                        <label htmlFor="PointForReferralUser">Point For Referral User</label>
                        <input
                            type="text"
                            id="PointForReferralUser"
                            value={PointForReferralUser}
                            onChange={(e) => setPointForReferralUser(e.target.value)}
                            placeholder="Enter Points Referral User"
                        />
                    </div>
                    <div className={classes.control}>
                        <label htmlFor="PointForReferredUser">Point For Referred User</label>
                        <input
                            type="text"
                            id="PointForReferredUser"
                            value={PointForReferredUser}
                            onChange={(e) => setPointForReferredUser(e.target.value)}
                            placeholder="Enter Points Referred Code"
                        />
                    </div>
                    {!isLoading && <Button type="submit">Add</Button>}
                    {isLoading && <p color="white">Sending request...</p>}
                </form>
            </Card>
        </div>
    );
};

export default AddSpecialReferral;
