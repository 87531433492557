import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
    DataGridPremium,
    GridToolbarContainer,
    GridToolbarExport,
} from "@mui/x-data-grid-premium";
import classes from "../../Redeem.module.css";
import { toast } from "react-toastify";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import IndianTimeFormat, { StatusCode } from "../../../../utils";
import AUTH_CONTEXT from "../../../../store";
import ARENO_API_ENDPOINT from "../../../../api";
import Loader from "../../../../components/Loader/Loader";
import { BASE_PATH_TO_ANDROID_USER_SUBSCRIPTIONS_DETAILS, BASE_PATH_TO_HOME, BASE_PATH_TO_IOS_USER_SUBSCRIPTIONS_DETAILS, BASE_PATH_TO_USER_DETAILS } from "../../../../base_routes_paths/data_paths";
import { BASE_PATH_TO_UPDATE_SUBSCRIPTIONS } from "../../../../base_routes_paths/redeem_paths";
import { Breadcrumbs, Typography } from "@mui/material";

const androidUserColumns = [
    {
        field: "SubscriptionID",
        headerName: "SubscriptionID",
        width: 230,
        renderCell: (params) => {
            return (
                <Link to={`${BASE_PATH_TO_UPDATE_SUBSCRIPTIONS}/` + params.row.SubscriptionID}>{params.row.SubscriptionID}</Link>
            );
        },
    },
    {
        field: "SubscriptionName",
        headerName: "SubscriptionName",
        width: 230,
    },
    {
        field: "orderId",
        headerName: "Order Id",
        width: 230,
    },
    {
        field: "packageName",
        headerName: "Package Name",
        width: 120,
    },
    {
        field: "productId",
        headerName: "Product Id",
        width: 150,
    },
    {
        field: "purchaseTime",
        headerName: "Purchase Time",
        width: 220,
    },
    {
        field: "purchaseState",
        headerName: "Purchase State",
        width: 120,
    },
    {
        field: "quantity",
        headerName: "Quantity",
        width: 80,
    },
    {
        field: "purchaseToken",
        headerName: "Purchase Token",
        width: 180,
        renderCell: (params) => {
            const link = params.row.purchaseToken;
            return (
                <>
                    <ContentCopyIcon
                        sx={{ marginRight: "10px", cursor: "pointer" }}
                        onClick={() => {
                            navigator.clipboard.writeText(link);
                            toast.success("Copied Token!!");
                        }}
                        titleAccess="Copy"
                        fontSize="small"
                    />
                    {link}
                </>
            );
        },
    },
    {
        field: "serverVerificationDataToken",
        headerName: "ServerVerificationDataToken",
        width: 300,
        renderCell: (params) => {
            const link = params.row.serverVerificationDataToken;
            return (
                <>
                    <ContentCopyIcon
                        sx={{ marginRight: "10px", cursor: "pointer" }}
                        onClick={() => {
                            navigator.clipboard.writeText(link);
                            toast.success("Copied Token!!");
                        }}
                        fontSize="small"
                        titleAccess="Copy"
                    />
                    {link}
                </>
            );
        },
    },
    {
        field: "autoRenewing",
        headerName: "Auto Renewing",
        width: 150,
    },
    {
        field: "acknowledged",
        headerName: "Acknowledged",
        width: 180,
    },
    {
        field: "action",
        headerName: "Action",
        width: 100,
        renderCell: (params) => {
            return (
                <div className={classes.cellAction}>
                    <Link
                        to={`${BASE_PATH_TO_ANDROID_USER_SUBSCRIPTIONS_DETAILS}/` + params.row.id}
                        style={{ textDecoration: "none" }}
                    >
                        <div className={classes.viewButton}>View</div>
                    </Link>
                </div>
            );
        },
    },
];

const IOSUserColumns = [
    {
        field: "UserID",
        headerName: "User ID",
        width: 230,
        renderCell: (params) => {
            return (
                <Link to={`${BASE_PATH_TO_USER_DETAILS}/` + params.row.UserID}>{params.row.UserID}</Link>
            );
        },
    },
    {
        field: "SubscriptionID",
        headerName: "SubscriptionID",
        width: 230,
        renderCell: (params) => {
            return (
                <Link to={`${BASE_PATH_TO_UPDATE_SUBSCRIPTIONS}/` + params.row.SubscriptionID}>{params.row.SubscriptionID}</Link>
            );
        },
    },
    {
        field: "SubscriptionName",
        headerName: "SubscriptionName",
        width: 140,
    },
    {
        field: "SubscriptionStatus",
        headerName: "SubscriptionStatus",
        width: 140,
        renderCell: (params) => {
            let Status = "";
            if (params.row.SubscriptionStatus === 4) {
                Status = "Purchased"
            } else if (params.row.SubscriptionStatus === 2) {
                Status = "Renewed"
            } else if (params.row.SubscriptionStatus === 13) {
                Status = "Expired"
            } else if (params.row.SubscriptionStatus === 42) {
                Status = "Renewed"
            }
            return (
                <>{`${Status} (${params.row.SubscriptionStatus})`}</>
            );
        },
    },
    {
        field: "price",
        headerName: "Price",
        width: 80,
    },
    {
        field: "currency",
        headerName: "Currency",
        width: 80,
    },
    {
        field: "storefront",
        headerName: "Storefront",
        width: 80,
    },
    {
        field: "storefrontId",
        headerName: "Storefront ID",
        width: 120,
    },
    {
        field: "inAppOwnershipType",
        headerName: "inAppOwnershipType",
        width: 180,
    },
    {
        field: "transactionReason",
        headerName: "TransactionReason",
        width: 180,
    },
    {
        field: "type",
        headerName: "Type",
        width: 230,
    },
    {
        field: "IsSubscribedByCorporate",
        headerName: "IsSubscribedByCorporate",
        width: 180,
    },
    {
        field: "transactionId",
        headerName: "Transaction ID",
        width: 180,
    },
    {
        field: "originalTransactionId",
        headerName: "OriginalTransaction ID",
        width: 180,
    },
    {
        field: "webOrderLineItemId",
        headerName: "WebOrderLineItem ID",
        width: 150,
    },
    {
        field: "subscriptionGroupIdentifier",
        headerName: "SubscriptionGroupIdentifier",
        width: 200,
    },
    {
        field: "signedDate",
        headerName: "SignedDate",
        width: 150,
        renderCell: (params) => {

            return (
                <>{IndianTimeFormat(params.row.signedDate)}</>
            );
        },
    },
    {
        field: "purchaseDate",
        headerName: "PurchaseDate",
        width: 150,
        renderCell: (params) => {

            return (
                <>{IndianTimeFormat(params.row.purchaseDate)}</>
            );
        },
    },
    {
        field: "originalPurchaseDate",
        headerName: "OriginalPurchaseDate",
        width: 150,
        renderCell: (params) => {

            return (
                <>{IndianTimeFormat(params.row.originalPurchaseDate)}</>
            );
        },
    },
    {
        field: "expiresDate",
        headerName: "ExpiresDate",
        width: 150,
        renderCell: (params) => {

            return (
                <>{IndianTimeFormat(params.row.expiresDate)}</>
            );
        },
    },
    {
        field: "productId",
        headerName: "Product ID",
        width: 150,
    },
    {
        field: "quantity",
        headerName: "Quantity",
        width: 80,
    },
    {
        field: "environment",
        headerName: "Environment",
        width: 100,
    },
    {
        field: "bundleId",
        headerName: "BundleId",
        width: 80,
    },
    {
        field: "action",
        headerName: "Action",
        width: 100,
        renderCell: (params) => {
            return (
                <div className={classes.cellAction}>
                    <Link
                        to={`${BASE_PATH_TO_IOS_USER_SUBSCRIPTIONS_DETAILS}/` + params.row.id}
                        style={{ textDecoration: "none" }}
                    >
                        <div className={classes.viewButton}>View</div>
                    </Link>
                </div>
            );
        },
    },
];

const UserSubscriptionDetailsList = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [data, setData] = useState([]);
    const [userColumns, setUserColumn] = useState(androidUserColumns);
    const params = useParams();
    const { token, fileName } = AUTH_CONTEXT()
    const EXPORT_FILENAME = `${fileName}_SingleUserSubscriptionList`;
    const navigate = useNavigate();

    const FetchResponseGetAllUserDetails = async () => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/redeem/AdminGetSubscriptionByUserID`,
                {
                    method: "POST",
                    body: JSON.stringify({
                        UserID: params.ID,
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            setIsLoading(false);
            StatusCode(response, navigate);
            response = await response.json();
            if (response.status === 207) {
                return toast
                    .error(response.error, {
                        position: "bottom-left",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                    })
            }
            setIsLoading(true);
            setIsDataFetched(true);
            toast.success("Data fetched successfully!!", {
                position: "bottom-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return response.data.data;
        } catch (e) {
            setIsLoading(false);
            setIsDataFetched(false);
            console.log(e.message);
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    useEffect(() => {
        if (!isDataFetched) {
            FetchResponseGetAllUserDetails()
                .then((result) => {
                    const deviceType = result.length > 0 ? result[0].DeviceType : "android";
                    let input = result.map((obj) => {
                        const newObject = { ...obj };
                        newObject.Dated = new Date(
                            newObject.Dated || new Date()
                        ).toLocaleString("en-Us", { timeZone: "Asia/Kolkata" });
                        const { _id, ...rest } = newObject;
                        return {
                            id: _id,
                            ...rest,
                        };
                    });
                    setUserColumn(deviceType === 'android' ? androidUserColumns : IOSUserColumns);
                    setData(input);
                })
                .catch((err) => console.log(err));
        }
    }, []);

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport
                    printOptions={{ disableToolbarButton: true }}
                    csvOptions={{ fileName: EXPORT_FILENAME }}
                    excelOptions={{ fileName: EXPORT_FILENAME }}
                />
            </GridToolbarContainer>
        );
    }

    return (
        <div className={classes.datatable}>
            {isLoading && (
                <>
                    <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 1 }}>
                        <Link underline="hover" color="inherit" to={BASE_PATH_TO_HOME}>
                            Dashboard
                        </Link>
                        <Typography color="text.primary">
                            Data
                        </Typography>
                        <Typography color="text.primary">Users Subscribtions List</Typography>
                    </Breadcrumbs>
                    <DataGridPremium
                        checkboxSelection
                        checkboxSelectionVisibleOnly
                        disableRowSelectionOnClick
                        rows={data}
                        columns={userColumns}
                        pageSize={20}
                        rowsPerPageOptions={[20]}
                        slots={{
                            toolbar: CustomToolbar,
                        }}
                        initialState={{
                            pagination: {
                                paginationModel: { pageSize: 25, page: 0 },
                            },
                        }}
                        pagination
                    />
                </>
            )}
            {!isLoading && <Loader />}
        </div>
    );
};

export default UserSubscriptionDetailsList;
