import { useState, useEffect, useContext, createContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CurrentTime, TimeStringCalculate, TokenExpireTime } from "../utils";
import ARENO_API_ENDPOINT from "../api";
import { BASE_PATH_TO_AUTH } from "../base_routes_paths/auth_paths";
import Loader from "../components/Loader/Loader";

const AuthContext = createContext();

const retrieveStoredToken = () => {
    const storedToken = localStorage.getItem("token");

    return {
        token: storedToken,
    };
};

function extractEnvironment() {
    let url = ARENO_API_ENDPOINT;

    const startIndex = url.indexOf("mobileapi-") + "mobileapi-".length;
    const endIndex = url.indexOf(".areno.in");

    if (startIndex >= 0 && endIndex > startIndex) {
        return url.substring(startIndex, endIndex);
    } else {
        return "prod";
    }
}

const fileName = extractEnvironment();

const retrieveUserName = () => {
    const storedName = localStorage.getItem("userName");
    return {
        name: storedName,
    };
};

const retrieveUserImg = () => {
    const storedImage = localStorage.getItem("profileImg");
    return {
        img: storedImage,
    };
};

export const AuthContextProvider = (props) => {
    const tokenData = retrieveStoredToken();
    const userName = retrieveUserName();
    const userImg = retrieveUserImg();
    const navigate = useNavigate();
    const currentDateTime = new Date();

    let initialToken;
    let name;
    let profileImg;
    if (tokenData && userName && userImg) {
        name = userName.name;
        initialToken = tokenData.token;
        profileImg = userImg && userImg.img !== null && userImg.img !== undefined ? userImg.img : "https://www.areno.in/images/logo.png";
    }

    const [token, setToken] = useState(initialToken);
    const [loading, setLoading] = useState(true);
    const [apiCallCheck, setApiCallCheck] = useState(false);

    const userIsLoggedIn = !!token;

    const logoutHandler = () => {
        setToken(null);
        localStorage.removeItem("userName");
        localStorage.removeItem("tokenExpiresIn");
        localStorage.removeItem("profileImg");
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("TimeOffSet");
        toast.success("Logged out successfully!!", {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
        navigate(BASE_PATH_TO_AUTH);
    };

    const loginHandler = (token, refreshToken) => {
        setToken(token);
        localStorage.setItem("token", token);
        localStorage.setItem("refreshToken", refreshToken);
    };

    const updateToken = async () => {
        const refreshToken = localStorage.getItem("refreshToken");
        if (refreshToken) {
            if (refreshToken !== "undefined" || refreshToken !== undefined) {
                let response = await fetch(`${ARENO_API_ENDPOINT}/auth/RefreshToken`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        authorization: `Bearer ${localStorage.getItem("refreshToken")}`,
                    },
                });

                let data = await response.json();

                if (response.status === 200) {
                    setToken(data.data.token);
                    loginHandler(data.data.token, data.data.refreshToken);
                    localStorage.setItem("tokenExpiresIn", TokenExpireTime(currentDateTime, parseInt(data.data.tokenExpiresIn)));
                    window.location.reload();
                } else {
                    localStorage.removeItem("userName");
                    localStorage.removeItem("tokenExpiresIn");
                    localStorage.removeItem("profileImg");
                    localStorage.removeItem("token");
                    localStorage.removeItem("refreshToken");
                    localStorage.removeItem("TimeOffSet");
                    setToken(null);
                    toast.error("An unknown error occurred, Please login again!!", {
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    navigate(BASE_PATH_TO_AUTH);
                }
            } else {
                localStorage.removeItem("userName");
                localStorage.removeItem("tokenExpiresIn");
                localStorage.removeItem("profileImg");
                localStorage.removeItem("token");
                localStorage.removeItem("refreshToken");
                localStorage.removeItem("TimeOffSet");
                setToken(null);
                toast.error("An unknown error occurred, Please login again!!", {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate(BASE_PATH_TO_AUTH);
            }
        } else {
            localStorage.removeItem("userName");
            localStorage.removeItem("tokenExpiresIn");
            localStorage.removeItem("profileImg");
            localStorage.removeItem("token");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("TimeOffSet");
            setToken(null);
            toast.error("An unknown error occurred, Please login again!!", {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            navigate(BASE_PATH_TO_AUTH);
        }
        setLoading(false);
    };

    useEffect(() => {
        if (localStorage.length !== 0) {
            if (localStorage.getItem("refreshToken") !== "undefined") {
                const timestamp1String = CurrentTime();
                const timestamp2String = localStorage.getItem("tokenExpiresIn");

                const timestamp1 = TimeStringCalculate(timestamp1String);
                const timestamp2 = TimeStringCalculate(timestamp2String);

                const timeDifference = timestamp1 - timestamp2;

                if (timeDifference > 1000) {
                    if (token) {
                        updateToken();
                    } else {
                        setToken(null);
                        localStorage.removeItem("userName");
                        localStorage.removeItem("tokenExpiresIn");
                        localStorage.removeItem("profileImg");
                        localStorage.removeItem("token");
                        localStorage.removeItem("refreshToken");
                        localStorage.removeItem("TimeOffSet");
                        navigate(BASE_PATH_TO_AUTH);
                    }
                }
            } else {
                setToken(null);
                localStorage.removeItem("userName");
                localStorage.removeItem("tokenExpiresIn");
                localStorage.removeItem("profileImg");
                localStorage.removeItem("token");
                localStorage.removeItem("refreshToken");
                localStorage.removeItem("TimeOffSet");
                toast.error("Session expired, Please Login again!!", {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate(BASE_PATH_TO_AUTH);
            }
        }
        setLoading(false);
    }, [navigate, token]);

    const contextValue = {
        user: name,
        profileImg: profileImg,
        token: token,
        isLoggedIn: userIsLoggedIn,
        login: loginHandler,
        logout: logoutHandler,
        apiCallCheck: apiCallCheck,
        setApiCallCheck: setApiCallCheck,
        fileName: fileName,
    };

    return (
        <AuthContext.Provider value={contextValue}>
            {loading ? <Loader /> : props.children}
        </AuthContext.Provider>
    );
};

const AUTH_CONTEXT = () => {
    return useContext(AuthContext);
};

export default AUTH_CONTEXT;