
import { toast } from "react-toastify";

const IndianTimeFormat = (date) => {
    try {
        let indiandate = new Date(date || new Date()).toLocaleString("en-Us", {
            timeZone: "Asia/Kolkata",
        });
        let today = indiandate.split(",")[0];
        let todayDate = new Date(today);
        var dd = todayDate.getDate();
        var mm = todayDate.getMonth() + 1;
        var yyyy = todayDate.getFullYear();
        if (dd < 10) {
            dd = "0" + dd;
        }
        if (mm < 10) {
            mm = "0" + mm;
        }
        let TodayDateFormat = dd + "-" + mm + "-" + yyyy;
        return TodayDateFormat;
    } catch (e) {
        console.log(e.message);
        return "";
    }
};

const IndianTime = (date) => {
    try {
        let indiandate = new Date(date || new Date()).toLocaleString("en-Us", {
            timeZone: "Asia/Kolkata",
        });
        let timeString = indiandate.split(",")[1].trim();
        let today = new Date("1970-01-01 " + timeString);
        let hh = today.getHours().toString().padStart(2, "0");
        let mm = today.getMinutes().toString().padStart(2, "0");
        let ss = today.getSeconds().toString().padStart(2, "0");
        let TodayTimeFormat = `${hh}:${mm}:${ss}`;
        return TodayTimeFormat;
    } catch (e) {
        console.log(e.message);
        return "";
    }
};

const TokenExpireTime = (date, expiresIn) => {
    try {
        const updatedDate = new Date(date.getTime() + (expiresIn - 240000));
        const datePart = IndianTimeFormat(updatedDate);
        const timePart = IndianTime(updatedDate);

        if (!datePart || !timePart) {
            return "";
        }

        return `${datePart} ${timePart}`;
    } catch (e) {
        console.log(e.message);
        return "";
    }
};

const CurrentTime = () => {
    const date = new Date();
    try {
        const datePart = IndianTimeFormat(date);
        const timePart = IndianTime(date);

        if (!datePart || !timePart) {
            return "";
        }

        return `${datePart} ${timePart}`;
    } catch (e) {
        console.log(e.message);
        return "";
    }
};

const TimeStringCalculate = (timeString) => {
    try {
        const [datePart, timePart] = timeString.split(" ");

        const [day, month, year] = datePart.split("-");

        const [hh, mm, ss] = timePart.split(":");

        const customDate = new Date(year, month - 1, day, hh, mm, ss);

        return customDate;
    } catch {
        return ""
    }
}

const StatusCode = async (response, navigate) => {
    if (response.status === 200) {
        return response;
    } else if (response.status === 400) {
        return toast.error("Bad Request, try again in sometime!", {
            position: "bottom-left",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
        });
    } else if (response.status === 403 || response.status === 401) {
        return toast.error("Session logged out, please login again!", {
            position: "bottom-left",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
        });
    } else if (response.status === 404) {
        return navigate("*");
    } else if (response.status === 408) {
        return toast.error("Request timed out, please login again!", {
            position: "bottom-left",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
        });
    } else if (response.status === 500) {
        return toast.error("Server is currently unavailable, please try again later!", {
            position: "bottom-left",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
        });
    } else if (response.status === 503) {
        return toast.error("Service Unavailable!!!", {
            position: "bottom-left",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
        });
    } else if (response.status === 504) {
        return toast.error("Gateway Timeout!!!!", {
            position: "bottom-left",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
        });
    }
};

const ParseOTP = (otp) => {
    const reversedString = otp.toString().split("").reverse().join("");
    return reversedString;
};

const TrimInput = (str) => {
    return str.replace(/^\s+|\s+$/g, '');
}

export default IndianTimeFormat;
export { TokenExpireTime, CurrentTime, TimeStringCalculate, StatusCode, ParseOTP, TrimInput };
