import classes from "./Chart.module.css";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AUTH_CONTEXT from "../../store";
import { StatusCode } from "../../utils";
import ARENO_API_ENDPOINT from "../../api";
import Stack from "@mui/material/Stack";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

const UserActivityChart = ({ aspect, title }) => {
  const { token } = AUTH_CONTEXT();
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [data, setData] = useState([]);
  const [TimeDuration, setTimeDuration] = useState("week");
  const navigate = useNavigate();

  const [Service, setService] = useState("All");

  const handleChange = (event, newService) => {
    if (newService !== null) {
      setService(newService);
      setIsDataFetched(false);
    }
  };

  const children = [
    <ToggleButton value="All" key="All">
      All
    </ToggleButton>,
    <ToggleButton value="Activity" key="Activity">
      AI Workouts
    </ToggleButton>,
    <ToggleButton value="Games" key="Games">
      Games
    </ToggleButton>,
    <ToggleButton value="Steps" key="Steps">
      Steps
    </ToggleButton>,
    <ToggleButton value="Meditation" key="Meditation">
      Meditations
    </ToggleButton>,
    <ToggleButton value="Routines" key="Routines">
      Routines
    </ToggleButton>,
  ];

  const control = {
    value: Service,
    onChange: handleChange,
    exclusive: true,
  };

  const FetchApi = async () => {
    try {
      let response = await fetch(
        `${ARENO_API_ENDPOINT}/challenges/GetUserActivityStatsAdminDashboard`,
        {
          method: "POST",
          body: JSON.stringify({
            TimeDuration: TimeDuration,
            Service: Service
          }),
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer" + " " + token,
          },
        }
      );

      StatusCode(response, navigate);
      response = await response.json();
      setIsDataFetched(true);
      return response.data.GraphData;
    } catch (e) {
      setIsDataFetched(false);
      console.log(e.message);
      alert(e.message);
    }
  };

  useEffect(() => {
    async function FetchData() {
      try {
        if (!isDataFetched) {
          let result = await FetchApi();
          setData(result);
        }
      } catch (err) {
        console.log(err.message);
      }
    }
    FetchData();
  }, [TimeDuration, Service]);

  const TimeDurationHandler = (event) => {
    event.preventDefault();
    setTimeDuration(event.target.value);
    setIsDataFetched(false);
  };

  return (
    <div className={classes.chart}>
      <div className={classes.title}>
        <div className={classes.subtitle}>{title}</div>
        <div>
          <div className={classes.selectbox}>
            <div className={classes.chart}>
              <label htmlFor="CategoryName" style={{ fontSize: "20px" }}>Duration: </label>
              <select
                name="CategoryName"
                id="CategoryName"
                value={TimeDuration}
                onChange={(e) => TimeDurationHandler(e)}
              >
                <option value="week">Week</option>
                <option value="month">Month</option>
                <option value="halfyear">6 months</option>
                <option value="year">1 Year</option>
              </select>
            </div>
            <div className={classes.buttonsContainer}>
              <Stack spacing={2} alignItems="center">
                <ToggleButtonGroup color="primary" size="small" {...control} aria-label="Small sizes">
                  {children}
                </ToggleButtonGroup>
              </Stack>
            </div>
          </div>
        </div>
      </div>
      {
        isDataFetched && (
          <div>
            <ResponsiveContainer width="100%" aspect={aspect}>
              <AreaChart
                className={classes.AreaChart}
                width={650}
                height={250}
                data={data}
                margin={{ top: 1, right: 20, left: -10, bottom: 0 }}
              >
                <defs>
                  <linearGradient id="total" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="black" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <XAxis dataKey="Day" stroke="black" />
                <YAxis dataKey="Count" stroke="black"></YAxis>
                <CartesianGrid
                  strokeDasharray="3 3"
                  className={classes.chartGrid}
                />
                <Tooltip />
                <Area
                  type="monotone"
                  dataKey="Count"
                  stroke="#8884d8"
                  fillOpacity={1}
                  fill="url(#total)"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        )
      }
      {!isDataFetched && <h1>Loading...</h1>}
      <div className={classes.BottomHeading}>(Date)</div>
    </div >
  );
};

export default UserActivityChart;
