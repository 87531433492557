import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import classes from "../../../Data.module.css";
import { toast } from "react-toastify";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import AUTH_CONTEXT from "../../../../../store";
import ARENO_API_ENDPOINT from "../../../../../api";
import IndianTimeFormat, { StatusCode } from "../../../../../utils";
import Loader from "../../../../../components/Loader/Loader";
import Card from "../../../../../components/Card/Card";

const AndroidUserSubscription = () => {
    const params = useParams();
    const { token } = AUTH_CONTEXT()
    const [data, setData] = useState([]);
    const [UserID, setUserID] = useState("");
    const [SubscriptionID, setSubscriptionID] = useState("");
    const [orderId, setorderId] = useState("");
    const [SubscriptionName, setSubscriptionName] = useState("");
    const [packageName, setPackageName] = useState("");
    const [productId, setProductId] = useState("");
    const [purchaseTime, setPurchaseTime] = useState("");
    const [purchaseState, setPurchaseState] = useState(0);
    const [quantity, setQuantity] = useState(0);
    const [purchaseToken, setPurchaseToken] = useState("");
    const [serverVerificationDataToken, setServerVerificationDataToken] = useState([]);
    const [acknowledged, setAcknowledged] = useState(false);
    const [IsSubscribedByCorporate, setIsSubscribedByCorporate] = useState(false);
    const [autoRenewing, setAutoRenewing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isDataFetched, setIsDataFetched] = useState(false);
    const navigate = useNavigate();

    const FetchResponseGetAllUserDetails = async () => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/redeem/AdminGetUserSubscriptionByID`,
                {
                    method: "POST",
                    body: JSON.stringify({
                        _id: params.ID,
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            setIsLoading(false);
            StatusCode(response, navigate);
            response = await response.json();
            if (response.status === 207) {
                return toast
                    .error(response.error, {
                        position: "bottom-left",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                    })
            }
            setIsLoading(true);
            setIsDataFetched(true);
            toast.success("Data fetched successfully!!", {
                position: "bottom-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return response.data;
        } catch (e) {
            setIsLoading(false);
            setIsDataFetched(false);
            console.log(e.message);
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    useEffect(() => {
        if (params.ID) {
            if (!isDataFetched) {
                FetchResponseGetAllUserDetails()
                    .then((result) => {
                        setUserID(result.UserID)
                        setSubscriptionID(result.SubscriptionID)
                        setSubscriptionName(result.SubscriptionName)
                        setorderId(result.orderId)
                        setQuantity(result.quantity)
                        setServerVerificationDataToken(result.serverVerificationDataToken)
                        setPackageName(result.packageName)
                        setProductId(result.productId)
                        setPurchaseTime(result.purchaseTime)
                        setPurchaseToken(result.purchaseToken)
                        setPurchaseState(result.purchaseState)
                        setAutoRenewing(result.autoRenewing)
                        setAcknowledged(result.acknowledged)
                        setIsSubscribedByCorporate(result.IsSubscribedByCorporate)
                        let input = result.NotificationData.map((row, index) => ({ ...row, id: index + 1 }));
                        setData(input)
                    })
                    .catch((err) => console.log(err));
            }
        } else {
            toast.error("ID missing", {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }, []);

    const userColumns = [
        {
            field: "Time",
            headerName: "Time",
            width: 130,
        },
        {
            field: "Date",
            headerName: "Date",
            width: 130,
        },
        {
            field: "NotificationType",
            headerName: "NotificationType",
            width: 130,
        },
        {
            field: "",
            headerName: "Notification Type Message",
            width: 240,
            renderCell: (params) => {
                const notificationType = params.row.NotificationType;
                let Message = "";
                let cellColor = "";
                let color = ""

                if (notificationType === 1) {
                    Message = "SUBSCRIPTION_RECOVERED";
                } else if (notificationType === 2) {
                    Message = "SUBSCRIPTION_RENEWED";
                    cellColor = "#ECFFDC"; // Set cell color for notification type 2
                } else if (notificationType === 3) {
                    Message = "SUBSCRIPTION_CANCELED";
                    cellColor = "red";
                    color = "white";
                } else if (notificationType === 4) {
                    Message = "SUBSCRIPTION_PURCHASED";
                    cellColor = "darkgreen";
                    color = "white";
                } else if (notificationType === 5) {
                    Message = "SUBSCRIPTION_ON_HOLD";
                } else if (notificationType === 6) {
                    Message = "SUBSCRIPTION_IN_GRACE_PERIOD";
                } else if (notificationType === 7) {
                    Message = "SUBSCRIPTION_RESTARTED";
                } else if (notificationType === 8) {
                    Message = "SUBSCRIPTION_PRICE_CHANGE_CONFIRMED";
                } else if (notificationType === 9) {
                    Message = "SUBSCRIPTION_DEFERRED";
                } else if (notificationType === 10) {
                    Message = "SUBSCRIPTION_PAUSED";
                } else if (notificationType === 11) {
                    Message = "SUBSCRIPTION_PAUSE_SCHEDULE_CHANGED";
                } else if (notificationType === 12) {
                    Message = "SUBSCRIPTION_REVOKED";
                } else if (notificationType === 13) {
                    Message = "SUBSCRIPTION_EXPIRED";
                    cellColor = "orange";
                    color = "white";
                }

                return <div style={{ width: "220px", backgroundColor: cellColor, padding: "10px", color: color, borderRadius: "5px" }}>{Message}</div>;
            },
        },
        {
            field: "Data",
            headerName: "Notification Meaning",
            width: 300,
            renderCell: (params) => {
                const notificationType = params.row.NotificationType;
                let Message = "";

                if (notificationType === 1) {
                    Message = "A subscription was recovered from account hold.";
                } else if (notificationType === 2) {
                    Message = "An active subscription was renewed.";

                } else if (notificationType === 3) {
                    Message = "A subscription was either voluntarily or involuntarily cancelled. For voluntary cancellation, sent when the user cancels.";
                } else if (notificationType === 4) {
                    Message = "A new subscription was purchased.";

                } else if (notificationType === 5) {
                    Message = "A subscription has entered account hold (if enabled).";
                } else if (notificationType === 6) {
                    Message = "A subscription has entered grace period (if enabled).";
                } else if (notificationType === 7) {
                    Message = "User has restored their subscription from Play > Account > Subscriptions. The subscription was canceled but had not expired yet when the user restores.";
                } else if (notificationType === 8) {
                    Message = "A subscription price change has successfully been confirmed by the user.";
                } else if (notificationType === 9) {
                    Message = "A subscription's recurrence time has been extended.";
                } else if (notificationType === 10) {
                    Message = "A subscription has been paused.";
                } else if (notificationType === 11) {
                    Message = "A subscription pause schedule has been changed.";
                } else if (notificationType === 12) {
                    Message = "A subscription has been revoked from the user before the expiration time.";
                } else if (notificationType === 13) {
                    Message = "A subscription has expired.";
                }

                return <div>{Message}</div>;
            },
        }
    ]

    return (
        !isLoading ? <Loader /> :
            <Card className={classes.input}>
                <div className={classes.Main_div}>Android User Subscription Details</div>
                <div className={classes.control}>
                    <label htmlFor="UserID">UserID :</label>
                    <input
                        disabled
                        type="text"
                        id="UserID"
                        value={UserID}
                    />
                </div>

                <div className={classes.control}>
                    <label htmlFor="SubscriptionID">SubscriptionID :</label>
                    <input
                        disabled
                        id="SubscriptionID"
                        value={SubscriptionID}
                    />
                </div>

                <div className={classes.control}>
                    <label htmlFor="SubscriptionName">SubscriptionName :</label>
                    <input
                        disabled
                        id="SubscriptionName"
                        value={SubscriptionName}
                    />
                </div>

                <div className={classes.control}>
                    <label htmlFor="orderId">OrderId :</label>
                    <input
                        disabled
                        id="orderId"
                        value={orderId}
                    />
                </div>
                <div className={classes.control}>
                    <label htmlFor="packageName">PackageName :</label>
                    <input
                        disabled
                        id="packageName"
                        value={packageName}
                    />
                </div>

                <div className={classes.control}>
                    <label htmlFor="purchaseState">PurchaseState :</label>
                    <input
                        disabled
                        id="purchaseState"
                        value={purchaseState}
                    />
                </div>

                <div className={classes.control}>
                    <label htmlFor="quantity">Quantity :</label>
                    <input
                        disabled
                        id="quantity"
                        value={quantity}
                    />
                </div>

                <div className={classes.control}>
                    <label htmlFor="purchaseTime">PurchaseTime (UTC) :</label>
                    <input
                        disabled
                        id="purchaseTime"
                        value={purchaseTime}
                    />
                </div>

                <div className={classes.control}>
                    <label htmlFor="purchaseToken">PurchaseToken :</label>
                    <textarea
                        disabled
                        id="purchaseToken"
                        value={purchaseToken}
                    />
                </div>

                <div className={classes.control}>
                    <label htmlFor="serverVerificationDataToken">Server Verification Data Token :</label>
                    <textarea
                        disabled
                        id="serverVerificationDataToken"
                        value={serverVerificationDataToken}
                    />
                </div>

                <div className={classes.control}>
                    <label htmlFor="productId">ProductId :</label>
                    <input
                        disabled
                        type="text"
                        id="productId"

                        value={productId}
                        placeholder="Enter productId"
                    />
                </div>

                <div className={classes.datatable} style={{ backgroundColor: "white" }}>
                    <DataGridPremium
                        rows={data}
                        columns={userColumns}
                        pagination
                        pageSizeOptions={[20, 50]}
                        disableRowSelectionOnClick
                    />
                </div>

                <div className={classes.selectbox} >
                    <label htmlFor="autoRenewing">Auto Renewing : </label>
                    <div style={{ marginLeft: "5px", marginTop: ".8rem" }}>
                        {autoRenewing === true ? "Yes" : "No"}
                    </div>
                </div>

                <div className={classes.selectbox} >
                    <label htmlFor="acknowledged">Acknowledged : </label>
                    <div style={{ marginLeft: "5px", marginTop: ".8rem" }}>
                        {acknowledged === true ? "Yes" : "No"}
                    </div>
                </div>

                <div className={classes.selectbox} style={{ margin: 0 }} >
                    <label htmlFor="IsSubscribedByCorporate">IsSubscribedByCorporate : </label>
                    <div style={{ marginLeft: "5px", marginTop: ".8rem" }}>
                        {IsSubscribedByCorporate === true ? "Yes" : "No"}
                    </div>
                </div>
            </Card>
    );
};

export default AndroidUserSubscription;
