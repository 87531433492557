import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { SidebarData } from "./SidebarData";
import SubMenu from "./SubMenu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import classes from "./Sidebar.module.css";
import { IconContext } from "react-icons/lib";
import logo from "../../images/Areno_logo2-removebg.png";
import { Box, Popover } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { toast } from "react-toastify";
import AUTH_CONTEXT from "../../store";
import { BASE_PATH_TO_HOME } from "../../base_routes_paths/data_paths";
import { BASE_PATH_TO_AUTH } from "../../base_routes_paths/auth_paths";

const Nav = styled.div`
  background: #4f005f;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const NavIcon = styled(Box)`
  margin-left: 2rem;
  font-size: 2rem;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  &&:hover {
    cursor: pointer;
  }
`;

const Logo = styled.img`
  width: 4rem;
  height: 4rem;
  color: #4f005f;
  background-color: #4f005f;
  transition: margin-left 0.4s ease;
`;

const SidebarNav = styled.nav`
  bottom: 0;
  background: #4f005f;
  width: ${({ sidebar }) => (sidebar ? "100%" : "0")};
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  overflow-y: scroll;
  left: 0;
  transition: width 0.3s ease;
  z-index: 10;

  @media screen and (min-width: 768px) {
    width: ${({ sidebar }) => (sidebar ? "18vw" : "0")};
  }
`;

const SidebarWrap = styled.div`
  width: 100%;
`;

const Sidebar = () => {

    const { isLoggedIn, user, logout, profileImg } = AUTH_CONTEXT();
    const logoutHandler = () => {
        logout();
        // optional: redirect the user
        toast.success("Logged out successfully!!", {
            position: "bottom-left",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    };
    const [sidebar, setSidebar] = useState(false);
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const sidebarRef = useRef(null);

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
    };

    const userName = user;
    const profileImage =
        profileImg !== "" && profileImg !== null
            ? profileImg
            : "https://www.areno.in/images/logo.png";

    useEffect(() => {
        const closeSidebar = (event) => {
            if (
                sidebarRef.current &&
                !sidebarRef.current.contains(event.target) &&
                sidebar
            ) {
                setSidebar(false);
            }
        };

        document.addEventListener("mousedown", closeSidebar);

        return () => {
            document.removeEventListener("mousedown", closeSidebar);
        };
    }, [sidebar]);

    const showSidebar = () => setSidebar(!sidebar);

    return (
        <>
            <IconContext.Provider value={{ color: "#fff" }}>
                <Nav>
                    {isLoggedIn && (
                        <NavIcon to="#">
                            <FaIcons.FaBars onClick={showSidebar} />
                        </NavIcon>
                    )}

                    <div
                        style={{
                            width: "88vw",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <Link to={BASE_PATH_TO_HOME} style={{ textDecoration: "none" }}>
                            <Logo
                                src={logo}
                                style={{
                                    marginLeft: sidebar ? "15vw" : "2vw",
                                    marginTop: "0.2rem",
                                    float: "left",
                                }}
                                alt="Areno"
                            />
                        </Link>
                        {!isLoggedIn ? (
                            <Link
                                to={BASE_PATH_TO_AUTH}
                                style={{
                                    fontWeight: "600",
                                    fontSize: "15px",
                                    backgroundColor: "#4f005f",
                                    color: "rgb(255, 209, 5)",
                                    border: "0px",
                                    float: "right",
                                    textDecoration: "none",
                                }}
                            >
                                Login
                            </Link>
                        ) : (
                            <span
                                style={{
                                    fontWeight: "600",
                                    fontSize: "15px",
                                    backgroundColor: "#4f005f",
                                    color: "rgb(255, 209, 5)",
                                    border: "0px",
                                    float: "right",
                                    textDecoration: "none",
                                    cursor: "pointer",
                                    display: "flex",
                                    alignItems: "center",
                                }}
                                onClick={handleOpen}
                            >
                                <div className={classes.cellWithImg}>
                                    <img className={classes.cellImg} src={profileImg} alt="" />
                                </div>
                                {userName}
                                <div className={classes.cellWithImg}>
                                    <KeyboardArrowDownIcon />
                                </div>
                            </span>
                        )}
                    </div>
                </Nav>
                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    sx={{ mt: 2, width: "300px" }}
                >
                    <Box
                        sx={{
                            p: 2,
                            fontWeight: "600",
                            fontSize: "15px",
                            display: "flex",
                            cursor: "pointer",
                            "&&:hover ": { bgcolor: "#f0f0f0" },
                        }}
                        onClick={() => {
                            logoutHandler();
                            handleClose();
                        }}
                    >
                        Logout
                        <LogoutIcon sx={{ marginLeft: 2 }} />
                    </Box>
                </Popover>
                <SidebarNav sidebar={sidebar} ref={sidebarRef}>
                    <SidebarWrap>
                        <NavIcon to="#">
                            <AiIcons.AiOutlineClose onClick={showSidebar} />
                        </NavIcon>
                        {isLoggedIn &&
                            SidebarData.map((item, index) => {
                                return (
                                    <SubMenu item={item} key={index} onClick={showSidebar} />
                                );
                            })}
                    </SidebarWrap>
                </SidebarNav>
            </IconContext.Provider>
        </>
    );
};

export default Sidebar;
