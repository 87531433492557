import React, { useState } from "react";
import cardClasses from "../../Data.module.css";
import { Link } from "react-router-dom";
import Card from "../../../../components/Card/Card";
import Button from "../../../../components/Button/Button";
import { BASE_PATH_TO_REDEEM_DATA } from "../../../../base_routes_paths/data_paths";

const RedeemUserInput = () => {
    const [id, setId] = useState("");
    return (
        <div>
            <Card className={cardClasses.input}>
                <div className={cardClasses.Main_div}>Set range for UserActivity</div>
                <div className={cardClasses.selectbox}>
                    <label htmlFor="StartDate">Date : </label>
                    <input
                        id="Date"
                        type="date"
                        placeholder="Enter date"
                        onChange={(e) => {
                            setId(e.target.value);
                        }}
                        value={id}
                    />
                </div>
                <Button>
                    <Link
                        to={`${BASE_PATH_TO_REDEEM_DATA}/${id}`}
                        style={{ textDecoration: "none", color: "#4f005f" }}
                    >
                        Submit
                    </Link>
                </Button>
            </Card>
        </div>
    );
};

export default RedeemUserInput;
