import { useState, useEffect } from "react";
import { GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import classes from "../Data.module.css";
import { toast } from "react-toastify";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader/Loader";
import AUTH_CONTEXT from "../../../store";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { BASE_PATH_TO_HOME, BASE_PATH_TO_USER_DETAILS } from "../../../base_routes_paths/data_paths";
import { ActivityService } from "../../../services/challenge_services";
import { BASE_PATH_TO_CHALLENGE_DETAILS } from "../../../base_routes_paths/challenge_paths";

const userColumns = [
    {
        field: "UserID",
        headerName: "UserID",
        width: 230,
        renderCell: (params) => {
            return (
                <a target="_blank" rel="noreferrer" href={`${BASE_PATH_TO_USER_DETAILS}/` + params.row.UserID}>{params.row.UserID}</a>
            );
        },
    },
    {
        field: "ChallengeID",
        headerName: "ChallengeID",
        width: 230,
        renderCell: (params) => {
            return (
                <a target="_blank" rel="noreferrer" href={`${BASE_PATH_TO_CHALLENGE_DETAILS}/` + params.row.ChallengeID}>{params.row.ChallengeID}</a>
            );
        },
    },
    {
        field: "UserName",
        headerName: "UserName",
        width: 180,
    },
    {
        field: "IpAddress",
        headerName: "IpAddress",
        width: 180,
    },
    {
        field: "CorporateID",
        headerName: "CorporateID",
        width: 120,
    },
    {
        field: "ChallengeName",
        headerName: "Challenge Name",
        width: 200,
    },
    {
        field: "ChallengeCategory",
        headerName: "Challenge Category",
        width: 180,
    },
    {
        field: "Date",
        headerName: "Date",
        width: 150,
    },
    {
        field: "Time",
        headerName: "Time",
        width: 150,
    },
    {
        field: "AttemptResult",
        headerName: "Attempt Result",
        width: 150,
    },
];

const UserActivityList = (props) => {
    const { token, fileName } = AUTH_CONTEXT();
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [rowsCount, setRowsCount] = useState(1);
    const [filterModel, setFilterModel] = useState({});
    const [sort, setSort] = useState(-1);
    const EXPORT_FILENAME = `${fileName}_UserActivityList`;
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [data, setData] = useState([]);
    const navigate = useNavigate();

    const FetchResponseGetAllUserActivityDetails = async (
        page,
        pageSize,
        filterModel,
        sort,
        field
    ) => {
        try {
            setIsLoading(true);
            setIsDataFetched(false);
            let response = await ActivityService.getActivityData(token, navigate, page, pageSize, sort, field, filterModel);
            setIsLoading(true);
            setIsDataFetched(true);
            toast.success("Data fetched successfully!!", {
                position: "bottom-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setIsLoading(false);
            return response;
        } catch (e) {
            setIsLoading(false);
            setIsDataFetched(false);
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    const FetchResponseFilter = async (page, pageSize, filterModel, sort, field) => {
        try {
            setIsLoading(true);
            let response = await ActivityService.getActivityData(token, navigate, page, pageSize, sort, field, filterModel);
            setIsLoading(false);
            return response;
        } catch (e) {
            setIsLoading(false);
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            if (!isDataFetched) {
                try {
                    const result = await FetchResponseGetAllUserActivityDetails(
                        page,
                        pageSize,
                        filterModel,
                        sort
                    );
                    const res = result.data.UserActivity;
                    setRowsCount(result.data.TotalUsers);
                    let input = res.map(({ _id: id, ...result }) => ({
                        id,
                        ...result,
                    }));
                    setData((prevData) => [...prevData, ...input]);
                } catch (err) { }
            }
        };

        fetchData();
    }, [page, data, pageSize, isDataFetched]);


    const handleFilterModelChange = async (model) => {
        let items = model.items;

        const json = items.reduce((obj, item) => {
            if (
                item.value !== null &&
                item.value !== undefined &&
                item.value !== ""
            ) {
                const fieldValue =
                    item.field === "Mobile_Number" ||
                        item.field === "Age" ||
                        item.field === "Height" ||
                        item.field === "Weight" ||
                        item.field === "YearOfBirth" ||
                        item.field === "ActivityStatus" ||
                        item.field === "GetToKnowFrom" ||
                        item.field === "TimeOffSet"
                        ? +item.value
                        : item.value;

                if (item.field === "IsSubscribed") {
                    obj[item.field] = item.value.toLowerCase() === "true";
                } else {
                    obj[
                        item.field === "id"
                            ? "_id"
                            : item.field
                    ] = fieldValue;
                }
            }
            return obj;
        }, {});

        try {
            setFilterModel(json);
            setPage(1);

            setTimeout(async () => {
                const result = await FetchResponseFilter(1, pageSize, json, sort, null);

                if (result && result.data) {
                    const res = result.data.UserActivity;
                    setRowsCount(result.data.TotalUsers);
                    setData(res.map(({ _id: id, ...result }) => ({ id, ...result })));
                }
            }, 500);
        } catch (err) { }
    };

    const handleSortModelChange = (model) => {
        let field = model.some((item) => {
            return item.field;
        });

        const isSort = model.some((item) => item.sort === "asc");
        setSort(isSort ? 1 : -1)

        if (field) {
            setTimeout(async () => {
                const result = await FetchResponseFilter(
                    1,
                    pageSize,
                    filterModel,
                    isSort ? 1 : -1,
                    field
                );

                if (result && result.data) {
                    const res = result.data.UserActivity;
                    setRowsCount(result.data.TotalUsers);
                    setData(res.map(({ _id: id, ...result }) => ({ id, ...result })));
                }
            }, 100);

            field = "";
        }
    };

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport
                    printOptions={{ disableToolbarButton: true }}
                    csvOptions={{ fileName: EXPORT_FILENAME }}
                    excelOptions={{ fileName: EXPORT_FILENAME }}
                />
            </GridToolbarContainer>
        );
    }
    return (
        <div>
            {isLoading ? <Loader /> : (
                <div className={classes.datatable}>
                    <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 1 }}>
                        <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                            Dashboard
                        </Link>
                        <Typography color="text.primary">
                            Data
                        </Typography>
                        <Typography color="text.primary">All UserActivity</Typography>
                    </Breadcrumbs>
                    <DataGridPremium
                        slots={{
                            toolbar: CustomToolbar,
                        }}
                        rows={data}
                        rowCount={rowsCount}
                        columns={userColumns}
                        pageSize={pageSize}
                        pagination
                        initialState={{
                            pagination: {
                                paginationModel: { pageSize: pageSize, page: page - 1 },
                            },
                        }}
                        pageSizeOptions={[20, 50, 100, 500, 1000, 2000, 5000, 10000, 20000]}
                        disableRowSelectionOnClick
                        onPaginationModelChange={({ pageSize, page }) => {
                            setPage(page + 1);
                            setPageSize(pageSize);
                            FetchResponseGetAllUserActivityDetails(page + 1, pageSize, filterModel, sort, null);
                        }}
                        filterMode="server"
                        onFilterModelChange={(model) => {
                            handleFilterModelChange(model);
                            setPage(1);
                        }}
                        onSortModelChange={(model) => handleSortModelChange(model)}
                        loading={isLoading}
                        checkboxSelectionVisibleOnly
                        checkboxSelection
                    />
                </div>
            )}
        </div>
    );
};

export default UserActivityList;
