import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import classes from "../../Auth.module.css";
import { toast } from "react-toastify";
import AUTH_CONTEXT from "../../../../store";
import Loader from "../../../../components/Loader/Loader";
import Button from "../../../../components/Button/Button";
import Card from "../../../../components/Card/Card";
import { IPAddressServices } from "../../../../services/auth_services";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";
import { BASE_PATH_TO_ADD_IP_ADDRESS, BASE_PATH_TO_UPDATE_IP_ADDRESS } from "../../../../base_routes_paths/auth_paths";

const IpAddressList = () => {
    const { token } = AUTH_CONTEXT();
    const [isLoading, setIsLoading] = useState(false);
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const FetchResponseGetAllUserDetails = async () => {
        try {
            let response = await IPAddressServices.getAllIPAddress(token, navigate);

            setIsLoading(false);
            if (response.status === 207) {
                return toast.error(response.error, {
                    position: "bottom-left",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                })
            }
            setIsLoading(true);
            setIsDataFetched(true);
            toast.success("Data fetched successfully!!", {
                position: "bottom-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return response.data;
        } catch (e) {
            setIsLoading(false);
            setIsDataFetched(false);
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    useEffect(() => {
        if (!isDataFetched) {
            FetchResponseGetAllUserDetails()
                .then((result) => {
                    setData(result[0].IpAddressList);
                })
                .catch((err) => console.log(err));
        }
    }, []);

    return (
        !isLoading ? <Loader /> :
            <div className={classes.crumbs}>
                <Breadcrumbs aria-label="breadcrumb" >
                    <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                        Dashboard
                    </Link>
                    <Typography color="text.primary">
                        Auth
                    </Typography>
                    <Typography color="text.primary">IP Address List</Typography>
                </Breadcrumbs>
                <Card className={classes.input}>
                    <div className={classes.Main_div}>Allowed IP Address List</div>
                    <label htmlFor="IpAddressList">IpAddressList :</label>
                    {
                        data.map((data, i) => {
                            return <div className={classes.control}>
                                <input
                                    id="IpAddressList"
                                    value={data}
                                    disabled
                                />
                            </div>
                        })
                    }
                    <div style={{ display: "flex" }}>
                        <div>
                            <Button
                                onClick={() => {
                                    navigate(BASE_PATH_TO_UPDATE_IP_ADDRESS);
                                }}
                            >
                                Update IP Address List
                            </Button>
                        </div>
                        <div style={{ marginLeft: "1rem" }}>
                            <Button
                                onClick={() => {
                                    navigate(BASE_PATH_TO_ADD_IP_ADDRESS);
                                }}

                            >
                                Add IP Address
                            </Button>
                        </div>
                    </div>
                </Card>
            </div>
    )
}

export default IpAddressList