import { useState } from "react";
import { useNavigate } from "react-router-dom";
import classes from "../../Auth.module.css";
import { toast } from "react-toastify";
import AUTH_CONTEXT from "../../../../store";
import Button from "../../../../components/Button/Button";
import Card from "../../../../components/Card/Card";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { BASE_PATH_TO_VIEW_ONBOARDING_SCREEN_QUESTIONS } from "../../../../base_routes_paths/auth_paths";
import { OnboardinScreenQuestionsServices } from "../../../../services/auth_services";

const CreateOSQuestions = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [Points, setPoints] = useState("");
    const [QuestionArray, setQuestionArray] = useState([{
        QuestionID: "",
        Question: "",
        ValueArray: [{
            ValueID: "",
            Value: "",
        }],
        SortID: "",
        IsActive: true,
    }]);
    const { token } = AUTH_CONTEXT();
    const navigate = useNavigate();

    const submitHandler = (event) => {
        event.preventDefault();
        setIsLoading(true);

        const Data = {
            Points,
            QuestionArray
        }

        const FetchApi = async () => {
            try {
                let response = await OnboardinScreenQuestionsServices.addOnboardinScreenQuestions(token, navigate, Data);
                if (response.status === 207) {
                    return toast
                        .error(response.error, {
                            position: "bottom-left",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                        })
                }
                navigate(BASE_PATH_TO_VIEW_ONBOARDING_SCREEN_QUESTIONS);
                toast.success("Data added successfully!!", {
                    position: "bottom-left",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } catch (e) {
                setIsLoading(false);
                toast.error(e.message, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        };
        FetchApi();
    };

    const addValueField = (questionIndex) => {
        const updatedQuestionArray = [...QuestionArray];
        const newValueField = {
            ValueID: "",
            Value: "",
        };
        updatedQuestionArray[questionIndex].ValueArray.push(newValueField);
        setQuestionArray(updatedQuestionArray);
    };

    const removeValueField = (questionIndex, valueIndex) => {
        const updatedQuestionArray = [...QuestionArray];
        updatedQuestionArray[questionIndex].ValueArray = updatedQuestionArray[questionIndex].ValueArray.filter((_, i) => i !== valueIndex);
        setQuestionArray(updatedQuestionArray);
    };


    const handleQuestionChange = (index, field, value) => {
        const updatedQuestionArray = [...QuestionArray];

        if (field === 'IsActive') {
            updatedQuestionArray[index][field] = value;
        } else {
            updatedQuestionArray[index][field] = value;
        }

        setQuestionArray(updatedQuestionArray);
    };

    const handleValueChange = (questionIndex, valueIndex, field, value) => {
        const updatedQuestionArray = [...QuestionArray];
        updatedQuestionArray[questionIndex].ValueArray[valueIndex][field] = value;
        setQuestionArray(updatedQuestionArray);
    };

    const addQuestionFields = (event) => {
        event.preventDefault();
        let newField = {
            QuestionID: "",
            Question: "",
            ValueArray: [{
                ValueID: "",
                Value: "",
            }],
            SortID: "",
            IsActive: true,
        };
        setQuestionArray((prevSubscriptionFeatures) => [
            ...prevSubscriptionFeatures,
            newField
        ]);
    };

    const removeQuestionsFields = (index) => {
        setQuestionArray((prevSubscriptionFeatures) =>
            prevSubscriptionFeatures.filter((_, i) => i !== index)
        );
    };


    return (
        <div className={classes.crumbs}>
            <Breadcrumbs aria-label="breadcrumb" >
                <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                    Dashboard
                </Link>
                <Typography color="text.primary">
                    Auth
                </Typography>
                <Typography color="text.primary">Add New Onboarding Screen Questions</Typography>
            </Breadcrumbs>
            <form onSubmit={submitHandler}>
                <Card className={classes.input}>
                    <div>
                        <div className={classes.Main_div}>Add Onboarding Screen Questions</div>
                        <div className={classes.control}>
                            <label htmlFor="Points">Points :</label>
                            <input
                                type="number"
                                id="Points"
                                value={Points}
                                onChange={e => setPoints(parseInt(e.target.value))}
                            />
                        </div>

                        <div className={classes.subfields} style={{ marginBottom: "20px" }} >
                            <h3>Questions List</h3>
                            {QuestionArray.map((Questions, index) => (
                                <div key={index}>
                                    <div className={classes.subfields}>
                                        <div className={classes.control}>
                                            <label htmlFor="QuestionID">QuestionID :</label>
                                            <input
                                                type="number"
                                                id="QuestionID"
                                                value={Questions.QuestionID}
                                                onChange={(e) => handleQuestionChange(index, 'QuestionID', parseInt(e.target.value))}
                                            />
                                        </div>
                                        <div className={classes.control}>
                                            <label htmlFor="Question">Question :</label>
                                            <input
                                                type="text"
                                                id="Question"
                                                value={Questions.Question}
                                                onChange={(e) => handleQuestionChange(index, 'Question', e.target.value)}
                                            />
                                        </div>
                                        <h3 style={{ marginBottom: "10px" }}>Values :</h3>
                                        {Questions.ValueArray.map((ValueArray, valueIndex) => (
                                            <div className={classes.subfields} style={{ marginBottom: "20px", border: "2px solid black" }} key={valueIndex}>
                                                <div className={classes.control}>
                                                    <label htmlFor="ValueID">ValueID :</label>
                                                    <input
                                                        type="number"
                                                        id="ValueID"
                                                        value={ValueArray.ValueID}
                                                        onChange={(e) => handleValueChange(index, valueIndex, 'ValueID', parseInt(e.target.value))}
                                                    />
                                                </div>
                                                <div className={classes.control}>
                                                    <label htmlFor="Value">Value :</label>
                                                    <input
                                                        type="text"
                                                        id="Value"
                                                        value={ValueArray.Value}
                                                        onChange={(e) => handleValueChange(index, valueIndex, 'Value', e.target.value)}
                                                    />
                                                </div>
                                                <button
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        removeValueField(index, valueIndex)
                                                    }}
                                                >
                                                    Remove Values...
                                                </button>
                                            </div>
                                        ))}
                                        <div>
                                            <button
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    addValueField(index);
                                                }}
                                                style={{
                                                    marginTop: "10px",
                                                }}
                                            >
                                                Add More Values...
                                            </button>
                                        </div>
                                        <div className={classes.control}>
                                            <label htmlFor="SortID">SortID :</label>
                                            <input
                                                type="number"
                                                id="SortID"
                                                value={Questions.SortID}
                                                onChange={(e) => handleQuestionChange(index, 'SortID', parseInt(e.target.value))}
                                            />
                                        </div>
                                        <div className={classes.selectbox}>
                                            <label htmlFor="IsActive">IsActive : </label>
                                            <select
                                                name="IsActive"
                                                id="IsActive"
                                                value={Questions.IsActive}
                                                onChange={(e) => handleQuestionChange(index, 'IsActive', e.target.value)}
                                            >
                                                <option value={false}>False</option>
                                                <option value={true}>True</option>
                                            </select>
                                        </div>
                                        <button
                                            onClick={(event) => {
                                                event.preventDefault();
                                                removeQuestionsFields(index);
                                            }}
                                        >
                                            Remove Questions...
                                        </button>
                                    </div>
                                </div>
                            ))}
                            <div>
                                <button
                                    onClick={addQuestionFields}
                                    style={{
                                        marginTop: "10px",
                                    }}
                                >
                                    Add More Questions...
                                </button>
                            </div>
                        </div>
                    </div>
                    {!isLoading && <Button type="submit">Add</Button>}
                    {isLoading && <p color="white">Sending request...</p>}
                </Card >
            </form>
        </div>
    );
};

export default CreateOSQuestions;
