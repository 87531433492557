import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import classes from "./Table.module.css";
import IndianTimeFormat, { StatusCode } from "../../utils";
import AUTH_CONTEXT from "../../store";
import { BASE_PATH_TO_CHALLENGE_DETAILS } from "../../base_routes_paths/challenge_paths";
import { ChallenegeServices } from "../../services/challenge_services";

const ChallengesTable = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDataFetched, setIsDataFetched] = useState(false);
  let ChallengeData = [];
  const [rows, setData] = useState(ChallengeData);
  const { token } = AUTH_CONTEXT();
  const navigate = useNavigate();

  const FetchResponseGetAllUserDetails = async () => {
    try {
      let response = await ChallenegeServices.getAllChallenges(token, navigate)

      setIsLoading(false);
      StatusCode(response, navigate);
      setIsLoading(true);
      setIsDataFetched(true);
      return response.data;
    } catch (e) {
      setIsLoading(false);
      setIsDataFetched(false);
      console.log(e.message);
      alert(e.message);
    }
  };

  useEffect(() => {
    if (!isDataFetched) {
      FetchResponseGetAllUserDetails()
        .then((result) => {
          let [dd, mm, yyyy] = IndianTimeFormat().split("-");
          let ActiveChalleges = result.filter(
            (x) =>
              x.EndsIn >= `${yyyy}-${mm}-${dd}` &&
              x.CategoryName !== "practice" &&
              x.Starts <= `${yyyy}-${mm}-${dd}`
          );
          for (var i = 0; i < ActiveChalleges.length; i++) {
            if (ActiveChalleges[i].WorkoutID === 1) {
              ActiveChalleges[i].WorkoutID = "Push-up";
            } else if (ActiveChalleges[i].WorkoutID === 2) {
              ActiveChalleges[i].WorkoutID = "Plank";
            } else if (ActiveChalleges[i].WorkoutID === 3) {
              ActiveChalleges[i].WorkoutID = "Squat";
            } else if (ActiveChalleges[i].WorkoutID === 4) {
              ActiveChalleges[i].WorkoutID = "High knees";
            } else if (ActiveChalleges[i].WorkoutID === 5) {
              ActiveChalleges[i].WorkoutID = "Lunges";
            } else if (ActiveChalleges[i].WorkoutID === 6) {
              ActiveChalleges[i].WorkoutID = "Situps";
            } else if (ActiveChalleges[i].WorkoutID === 7) {
              ActiveChalleges[i].WorkoutID = "Knee Pushups";
            } else if (ActiveChalleges[i].WorkoutID === 8) {
              ActiveChalleges[i].WorkoutID = "Jumping Jacks";
            }
          }
          let input = ActiveChalleges.map(
            ({ _id: id, ...ActiveChalleges }) => ({
              id,
              ...ActiveChalleges,
            })
          );
          setData(input);
        })
        .catch((err) => console.log(err));
    }
  }, []);

  return (
    <TableContainer component={Paper} className={classes.table}>
      <Table sx={{ minWidth: 650, color: "black" }} aria-label="simple table">
        <TableHead>
          <TableRow sx={{ color: "black" }}>
            <TableCell className={classes.tableCell}>Challenge ID</TableCell>
            <TableCell className={classes.tableCell}>Challenge</TableCell>
            <TableCell className={classes.tableCell}>Category</TableCell>
            <TableCell className={classes.tableCell}>Type</TableCell>
            <TableCell className={classes.tableCell}>StartsTimeUtc</TableCell>
            <TableCell className={classes.tableCell}>StartDate</TableCell>
            <TableCell className={classes.tableCell}>StartTime</TableCell>
            <TableCell className={classes.tableCell}>EndsInTimeUtc</TableCell>
            <TableCell className={classes.tableCell}>EndDate</TableCell>
            <TableCell className={classes.tableCell}>EndTime</TableCell>
            <TableCell className={classes.tableCell}>Participants</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell className={classes.tableCell}>
                <Link to={`${BASE_PATH_TO_CHALLENGE_DETAILS}/` + row.id}>{row.id}</Link>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <div className={classes.cellWrapper}>
                  <img
                    src={row.ChallengeThumbURL}
                    alt=""
                    className={classes.image}
                  />
                  {row.Name}
                </div>
              </TableCell>
              <TableCell className={classes.tableCell}>
                {row.CategoryName}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {row.WorkoutID}
              </TableCell>
              <TableCell className={classes.tableCell}>{row.StartsTimeUtc}</TableCell>
              <TableCell className={classes.tableCell}>{row.StartDate}</TableCell>
              <TableCell className={classes.tableCell}>{row.StartTime}</TableCell>
              <TableCell className={classes.tableCell}>{row.EndsInTimeUtc}</TableCell>
              <TableCell className={classes.tableCell}>{row.EndDate}</TableCell>
              <TableCell className={classes.tableCell}>{row.EndTime}</TableCell>
              <TableCell className={classes.tableCell}>
                <span className={classes.status}>{row.Participents}</span>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ChallengesTable;
