import { useState, useEffect } from "react";
import {
    DataGridPremium,
    GridToolbarContainer,
    GridToolbarExport,
} from "@mui/x-data-grid-premium";
import { useNavigate } from "react-router-dom";
import classes from "./List.module.css";
import { toast } from "react-toastify";
import {
    Box,
    Breadcrumbs,
    CircularProgress,
    FormControl,
    Link,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import { StatusCode } from "../../../utils";
import ARENO_API_ENDPOINT from "../../../api";
import AUTH_CONTEXT from "../../../store";
import { BASE_PATH_TO_HOME, BASE_PATH_TO_USER_DETAILS } from "../../../base_routes_paths/data_paths";

const UserList = (props) => {
    const { token, fileName } = AUTH_CONTEXT();
    const [isLoading, setIsLoading] = useState(false);
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [data, setData] = useState([]);
    const [points, setPoints] = useState(false);
    const [pointsValue, setPointsValue] = useState({});
    const [streak, setStreak] = useState(false);
    const [streakValue, setStreakValue] = useState({});
    const [maxStreak, setMaxStreak] = useState(false);
    const [maxStreakValue, setMaxStreakValue] = useState({});
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [rowsCount, setRowsCount] = useState(1);
    const EXPORT_FILENAME = `${fileName}_SpecialReferralsList`;
    const [filterModel, setFilterModel] = useState({});
    const [sort, setSort] = useState(1);
    const navigate = useNavigate();

    const FetchResponseGetAllUserDetails = async (
        page,
        pageSize,
        filterModel,
        sort
    ) => {
        try {
            setIsLoading(true);
            setIsDataFetched(false);
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/auth/AdminGetAllUserData?page=${page}&limit=${pageSize}&sort=${sort}&TimeOffSet=${localStorage.getItem("TimeOffSet")}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        filters: filterModel,
                    }),
                }
            );
            StatusCode(response, navigate);
            response = await response.json();
            setIsLoading(true);
            setIsDataFetched(true);
            toast.success("Data fetched successfully!!", {
                position: "bottom-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setIsLoading(false);
            return response;
        } catch (e) {
            setIsLoading(false);
            setIsDataFetched(false);
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    const FetchResponseFilter = async (page, pageSize, filterModel, sort, field) => {
        try {
            setIsLoading(true);
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/auth/AdminGetAllUserData?page=${page}&limit=${pageSize}&sort=${sort}&field=${field}&TimeOffSet=${localStorage.getItem("TimeOffSet")}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        filters: filterModel,
                    }),
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            setIsLoading(false);
            return response;
        } catch (e) {
            setIsLoading(false);
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            if (!isDataFetched) {
                try {
                    const result = await FetchResponseGetAllUserDetails(
                        page,
                        pageSize,
                        filterModel,
                        sort
                    );
                    const res = result.data.UsersData;
                    setRowsCount(result.data.TotalUsers);
                    let input = res.map(({ _id: id, ...result }) => ({
                        id,
                        ...result,
                    }));
                    setData((prevData) => [...prevData, ...input]);
                } catch (err) { }
            }
        };

        fetchData();
    }, [page, data, pageSize, isDataFetched]);

    const fetchPoints = async (id) => {
        setPoints((prevPointsState) => ({
            ...prevPointsState,
            [id]: true,
        }));

        try {
            let response = await fetch(`${ARENO_API_ENDPOINT}/points/GetUserPoint`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ UserID: id }),
            });
            StatusCode(response, navigate);
            response = await response.json();
            setPoints((prevPointsState) => ({
                ...prevPointsState,
                [id]: false,
            }));

            const res = response.data.userPoint;
            setData((prevData) => {
                const updatedData = prevData.map((item) =>
                    item.id === id ? { ...item, Points: res } : item
                );
                return updatedData;
            });
            setPointsValue((prevPointsState) => ({
                ...prevPointsState,
                [id]: res,
            }));
        } catch (e) {
            setPoints((prevPointsState) => ({
                ...prevPointsState,
                [id]: false,
            }));
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    const fetchStreak = async (id) => {
        setStreak((prevPointsState) => ({
            ...prevPointsState,
            [id]: true,
        }));

        try {
            let response = await fetch(`${ARENO_API_ENDPOINT}/streaks/GetAllUsersStreak`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ UserID: id }),
            });
            StatusCode(response, navigate);
            response = await response.json();
            setStreak((prevPointsState) => ({
                ...prevPointsState,
                [id]: false,
            }));

            const res = response.data.CurrentStreak;
            setData((prevData) => {
                const updatedData = prevData.map((item) =>
                    item.id === id ? { ...item, Points: res } : item
                );
                return updatedData;
            });
            setStreakValue((prevPointsState) => ({
                ...prevPointsState,
                [id]: res,
            }));
        } catch (e) {
            setStreak((prevPointsState) => ({
                ...prevPointsState,
                [id]: false,
            }));
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    const fetchMaxStreak = async (id) => {
        setMaxStreak((prevPointsState) => ({
            ...prevPointsState,
            [id]: true,
        }));

        try {
            let response = await fetch(`${ARENO_API_ENDPOINT}/streaks/GetAllUsersStreak`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ UserID: id }),
            });
            StatusCode(response, navigate);
            response = await response.json();
            setMaxStreak((prevPointsState) => ({
                ...prevPointsState,
                [id]: false,
            }));

            const res = response.data.MaxStreak;
            setData((prevData) => {
                const updatedData = prevData.map((item) =>
                    item.id === id ? { ...item, Points: res } : item
                );
                return updatedData;
            });
            setMaxStreakValue((prevPointsState) => ({
                ...prevPointsState,
                [id]: res,
            }));
        } catch (e) {
            setMaxStreak((prevPointsState) => ({
                ...prevPointsState,
                [id]: false,
            }));
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    const userColumns = [
        {
            field: "id",
            headerName: "User ID",
            width: 230,
            renderCell: (params) => {
                return (
                    <a target="_blank" rel="noreferrer" href={`${BASE_PATH_TO_USER_DETAILS}/` + params.row.id}>{params.row.id}</a>
                );
            },
        },
        {
            field: "ProfileImage",
            headerName: "Profile",
            width: 80,
            renderCell: (params) => {
                return (
                    <div className={classes.cellWithImg}>
                        <img
                            className={classes.cellImg}
                            src={
                                params.row.ProfileImage ||
                                "https://www.areno.in/images/logo.png"
                            }
                            alt=""
                        />
                    </div>
                );
            },
        },
        {
            field: "Name",
            headerName: "Name",
            width: 130,
        },
        {
            field: "Email",
            headerName: "Email",
            width: 130,
        },
        {
            field: "CountryCode",
            headerName: "Country Code",
            width: 120,
        },
        {
            field: "Country",
            headerName: "Country",
            width: 120,
        },
        {
            field: "Mobile_Number",
            headerName: "Mobile Number",
            width: 120,
        },
        {
            field: "Age",
            headerName: "Age",
            width: 60,
        },
        {
            field: "Role",
            headerName: "Role",
            width: 80,
        },
        {
            field: "HeightFt",
            headerName: "HeightFt",
            width: 80,
        },
        {
            field: "HeightIn",
            headerName: "HeightIn",
            width: 80,
        },
        {
            field: "Wt_kg",
            headerName: "WeightKgs",
            width: 80,
        },
        {
            field: "IsEmailVerified",
            headerName: "IsEmailVerified",
            width: 150,
        },
        {
            field: "IsSubscribedByCorporate",
            headerName: "Corporate Subscribed",
            width: 150,
        },
        {
            field: "IsSubscribed",
            headerName: "Subscribed",
            width: 100,
        },
        {
            field: "SubscriptionStartsOn",
            headerName: "Subscription Start Date",
            width: 180,
        },
        {
            field: "SubscriptionEndsOn",
            headerName: "Subscription End Date",
            width: 180,
        },
        {
            field: "YearOfBirth",
            headerName: "Year Of Birth",
            width: 120,
        },
        {
            field: "Gender",
            headerName: "Gender",
            width: 80,
        },
        {
            field: "IsOnboardingComplete",
            headerName: "IsOnboardingComplete",
            width: 180,
        },
        {
            field: "IsQuestionnaireComplete",
            headerName: "IsQuestionnaireComplete",
            width: 160,
        },
        {
            field: "ShowOnboardingScreen",
            headerName: "ShowOnboardingScreen",
            width: 160,
        },
        {
            field: "CorporateID",
            headerName: "CorporateID",
            width: 120,
        },
        {
            field: "LastActivityTime",
            headerName: "LastActivityTime",
            width: 120,
        },
        {
            field: "TeamID",
            headerName: "TeamID",
            width: 120,
        },
        {
            field: "ClanID",
            headerName: "ClanID",
            width: 120,
        },
        {
            field: "Location",
            headerName: "Location",
            width: 160,
        },
        {
            field: "City",
            headerName: "City",
            width: 160,
        },
        {
            field: "DateOfRegistration",
            headerName: "Registration date",
            width: 160,
        },
        {
            field: "TimeOfRegistration",
            headerName: "Registration time",
            width: 160,
        },
        {
            field: "IsRatedApp",
            headerName: "Has Rated",
            width: 160,
        },
        {
            field: "RatingGiven",
            headerName: "Ratings given",
            width: 160,
            renderCell: (params) => {
                const { RatingGiven } = params.row;
                if (RatingGiven || RatingGiven === "") {
                    return <div>N/A</div>;
                } else {
                    return <div>{RatingGiven}</div>;
                }
            },
        },
        {
            field: "FeedBack",
            headerName: "FeedBack",
            width: 250,
        },
        {
            field: "TimeOffSet",
            headerName: "Time Off Set",
            width: 160,
        },
        {
            field: "Points",
            headerName: "Points",
            width: 120,
            renderCell: (params) => {
                const id = params.row.id;
                return !points[id] ? (
                    pointsValue[id] !== undefined ? (
                        pointsValue[id]
                    ) : (
                        <button
                            className={`${classes.button}`}
                            onClick={() => {
                                fetchPoints(id);
                            }}
                        >
                            Fetch Points
                        </button>
                    )
                ) : (
                    <Box sx={{ display: "flex" }}>
                        <CircularProgress />
                    </Box>
                );
            },
        },
        {
            field: "Current Streak",
            headerName: "Current Streak",
            width: 120,
            renderCell: (params) => {
                const id = params.row.id;
                return !streak[id] ? (
                    streakValue[id] !== undefined ? (
                        streakValue[id]
                    ) : (
                        <button
                            className={`${classes.button}`}
                            onClick={() => {
                                fetchStreak(id);
                            }}
                        >
                            Fetch Streak
                        </button>
                    )
                ) : (
                    <Box sx={{ display: "flex" }}>
                        <CircularProgress />
                    </Box>
                );
            },
        },
        {
            field: "Max Streak",
            headerName: "Max Streak",
            width: 120,
            renderCell: (params) => {
                const id = params.row.id;
                return !maxStreak[id] ? (
                    maxStreakValue[id] !== undefined ? (
                        maxStreakValue[id]
                    ) : (
                        <button
                            className={`${classes.button}`}
                            onClick={() => {
                                fetchMaxStreak(id);
                            }}
                        >
                            Fetch Streak
                        </button>
                    )
                ) : (
                    <Box sx={{ display: "flex" }}>
                        <CircularProgress />
                    </Box>
                );
            },
        },
        {
            field: "ActivityStatus",
            headerName: "Activity Status",
            width: 110,
            renderCell: (params) => {
                const ActivityStatus = params.row.ActivityStatus;
                return <>{ActivityStatus === -1 ? "N/A" : ActivityStatus}</>
            },
        },
        {
            field: "GetToKnowFrom",
            headerName: "Get To Know From",
            width: 140,
            renderCell: (params) => {
                const GetToKnowFrom = params.row.GetToKnowFrom;
                return <>{GetToKnowFrom === -1 ? "N/A" : GetToKnowFrom}</>
            },
        },
        {
            field: "ReferralCode",
            headerName: "ReferralCode",
            width: 120,
        },
        {
            field: "ReferedBy",
            headerName: "ReferedBy",
            width: 120,
        },
        {
            field: "BuildNumber",
            headerName: "BuildNumber",
            width: 120,
        },
        {
            field: "OSVersion",
            headerName: "OS Version",
            width: 100,
        },
        {
            field: "AppVersion",
            headerName: "App Version",
            width: 120,
        },
        {
            field: "DeviceID",
            headerName: "Device ID",
            width: 180,
        },
        {
            field: "DeviceType",
            headerName: "Device Type",
            width: 140,
        },
    ];

    const actionColumn = [
        {
            field: "IsActive",
            headerName: "Status",
            width: 100,
            renderCell: (params) => {
                return (
                    <div className={classes.cellAction}>
                        <div>
                            {params.row.IsActive && (
                                <span style={{ color: "green" }}>Active</span>
                            )}
                            {!params.row.IsActive && (
                                <span style={{ color: "red" }}>Blocked</span>
                            )}
                        </div>
                    </div>
                );
            },
        },
        {
            field: "action",
            headerName: "Action",
            width: 100,
            renderCell: (params) => {
                return (
                    <div className={classes.cellAction}>
                        <a target="_blank" rel="noreferrer" href={`${BASE_PATH_TO_USER_DETAILS}/` + params.row.id}
                            style={{ textDecoration: "none" }}
                        >
                            <div className={classes.viewButton}>View</div>
                        </a>
                    </div>
                );
            },
        },
    ];

    const handleFilterModelChange = async (model) => {
        let items = model.items;

        const json = items.reduce((obj, item) => {
            if (
                item.value !== null &&
                item.value !== undefined &&
                item.value !== ""
            ) {
                const fieldValue =
                    item.field === "Mobile_Number" ||
                        item.field === "Age" ||
                        item.field === "Height" ||
                        item.field === "Weight" ||
                        item.field === "YearOfBirth" ||
                        item.field === "ActivityStatus" ||
                        item.field === "GetToKnowFrom" ||
                        item.field === "TimeOffSet"
                        ? +item.value
                        : item.value;

                if (item.field === "IsSubscribed") {
                    obj[item.field] = item.value.toLowerCase() === "true";
                } else {
                    obj[
                        item.field === "id"
                            ? "_id"
                            : item.field
                    ] = fieldValue;
                }
            }
            return obj;
        }, {});

        try {
            setFilterModel(json);
            setPage(1);

            setTimeout(async () => {
                const result = await FetchResponseFilter(1, pageSize, json, sort, null);

                if (result && result.data) {
                    const res = result.data.UsersData;
                    setRowsCount(result.data.TotalUsers);
                    setData(res.map(({ _id: id, ...result }) => ({ id, ...result })));
                }
            }, 500);
        } catch (err) { }
    };

    const handleSortModelChange = (model) => {
        let field = model.some((item) => {
            return item.field;
        });

        const isSort = model.some((item) => item.sort === "asc");

        if (field) {
            setTimeout(async () => {
                const result = await FetchResponseFilter(
                    1,
                    pageSize,
                    filterModel,
                    isSort ? 1 : -1,
                    field
                );

                if (result && result.data) {
                    const res = result.data.UsersData;
                    setRowsCount(result.data.TotalUsers);
                    setData(res.map(({ _id: id, ...result }) => ({ id, ...result })));
                }
            }, 100);

            field = "";
        }
    };

    function CustomToolbar() {
        const [selectedOption, setSelectedOption] = useState("_id");
        const [value, setValue] = useState("");

        const handleOptionChange = (event) => {
            setSelectedOption(event.target.value);
            setValue("");
        };

        const generateJson = () => {
            const json = { [selectedOption]: value };

            if (selectedOption === "Mobile_Number") {
                json[selectedOption] = +value;
            }

            return json;
        };

        const json = generateJson();

        return (
            <GridToolbarContainer
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                }}
            >
                <GridToolbarExport
                    printOptions={{ disableToolbarButton: true }}
                    csvOptions={{ fileName: EXPORT_FILENAME }}
                    excelOptions={{ fileName: EXPORT_FILENAME }}
                />
                <FormControl
                    style={{
                        display: "flex",
                        flexDirection: "row",
                    }}
                >
                    <Select
                        value={selectedOption}
                        onChange={handleOptionChange}
                        sx={{ width: "10rem" }}
                    >
                        <MenuItem value="_id">UserID</MenuItem>
                        <MenuItem value="Mobile_Number">Mobile Number</MenuItem>
                        <MenuItem value="ReferedBy">Refered By</MenuItem>
                    </Select>
                    <TextField
                        id="outlined-basic"
                        label={selectedOption === "_id" ? "UserID" : selectedOption}
                        variant="filled"
                        type="text"
                        value={value}
                        onChange={(e) => {
                            setValue(e.target.value);
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={async () => {
                                            const result = await FetchResponseFilter(
                                                1,
                                                pageSize,
                                                json,
                                                sort,
                                                null
                                            );

                                            if (result && result.data) {
                                                const res = result.data.UsersData;
                                                setRowsCount(result.data.TotalUsers);
                                                setData(
                                                    res.map(({ _id: id, ...result }) => ({
                                                        id,
                                                        ...result,
                                                    }))
                                                );
                                            }
                                        }}
                                    >
                                        <SendIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormControl>
            </GridToolbarContainer>
        );
    }

    return (
        <div className={classes.datatable}>
            <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 1 }}>
                <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                    Dashboard
                </Link>
                <Typography color="text.primary">
                    Data
                </Typography>
                <Typography color="text.primary">All Users List</Typography>
            </Breadcrumbs>
            <DataGridPremium
                slots={{
                    toolbar: CustomToolbar,
                }}
                rows={data}
                rowCount={rowsCount}
                columns={userColumns.concat(actionColumn)}
                pageSize={pageSize}
                pagination
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: pageSize, page: page - 1 },
                    },
                }}
                pageSizeOptions={[20, 50, 100, 500, 1000, 2000, 5000, 10000, 20000]}
                disableRowSelectionOnClick
                onPaginationModelChange={({ pageSize, page }) => {
                    setPage(page + 1);
                    setPageSize(pageSize);
                    FetchResponseGetAllUserDetails(page + 1, pageSize, filterModel, sort);
                }}
                filterMode="server"
                onFilterModelChange={(model) => {
                    handleFilterModelChange(model);
                    setPage(1);
                }}
                onSortModelChange={(model) => handleSortModelChange(model)}
                loading={isLoading}
                checkboxSelectionVisibleOnly
                checkboxSelection
            />
        </div>
    );
};

export default UserList;
