export const BASE_PATH_TO_CHALLENGE = "/challenge";

export const BASE_PATH_TO_CHALLENGES_LIST = `${BASE_PATH_TO_CHALLENGE}/challenges-list`;
export const BASE_PATH_TO_ADD_CHALLENGES = `${BASE_PATH_TO_CHALLENGE}/add-challenge`;
export const BASE_PATH_TO_UPDATE_CHALLENGES = `${BASE_PATH_TO_CHALLENGE}/update-challenge`;
export const BASE_PATH_TO_CHALLENGE_DETAILS = `${BASE_PATH_TO_CHALLENGE}/challenge-details`;

export const BASE_PATH_TO_CORPORATE_LIST = `${BASE_PATH_TO_CHALLENGE}/corporate-list`;
export const BASE_PATH_TO_ADD_CORPORATE = `${BASE_PATH_TO_CHALLENGE}/add-corporate`;
export const BASE_PATH_TO_UPDATE_CORPORATE = `${BASE_PATH_TO_CHALLENGE}/update-corporate`;

export const BASE_PATH_TO_WORKOUT_LIST = `${BASE_PATH_TO_CHALLENGE}/workout-list`;
export const BASE_PATH_TO_ADD_WORKOUT = `${BASE_PATH_TO_CHALLENGE}/add-workout`;
export const BASE_PATH_TO_UPDATE_WORKOUT = `${BASE_PATH_TO_CHALLENGE}/update-workout`;

export const BASE_PATH_TO_CATEGORY_LIST = `${BASE_PATH_TO_CHALLENGE}/category-list`;
export const BASE_PATH_TO_ADD_CATEGORY = `${BASE_PATH_TO_CHALLENGE}/add-category`;
export const BASE_PATH_TO_UPDATE_CATEGORY = `${BASE_PATH_TO_CHALLENGE}/update-category`;

export const BASE_PATH_TO_SOLO_CHALLENGE_LIST = `${BASE_PATH_TO_CHALLENGE}/solo-challenges-list`;
export const BASE_PATH_TO_SOLO_CHALLENGE_DETAILS = `${BASE_PATH_TO_CHALLENGE}/solo-challenges-details`;
export const BASE_PATH_TO_ADD_SOLO_CHALLENGE = `${BASE_PATH_TO_CHALLENGE}/add-solo-challenges`;
export const BASE_PATH_TO_UPDATE_SOLO_CHALLENGE = `${BASE_PATH_TO_CHALLENGE}/update-solo-challenges`;
export const BASE_PATH_TO_SOLO_CHALLENGE_ATTEMPTS = `${BASE_PATH_TO_CHALLENGE}/solo-challenges-attempts`;

export const BASE_PATH_TO_SOLO_CHALLENGE_MILESTONES_LIST = `${BASE_PATH_TO_CHALLENGE}/solo-challenges-milestones-list`;
export const BASE_PATH_TO_ADD_SOLO_CHALLENGE_MILESTONES = `${BASE_PATH_TO_CHALLENGE}/add-solo-challenges-milestones`;
export const BASE_PATH_TO_UPDATE_SOLO_CHALLENGE_MILESTONES = `${BASE_PATH_TO_CHALLENGE}/update-solo-challenges-milestones`;

export const BASE_PATH_TO_MEDITATIONS_LIST = `${BASE_PATH_TO_CHALLENGE}/meditations-list`;
export const BASE_PATH_TO_ADD_MEDITATIONS = `${BASE_PATH_TO_CHALLENGE}/add-meditations`;
export const BASE_PATH_TO_UPDATE_MEDITATIONS = `${BASE_PATH_TO_CHALLENGE}/update-meditations`;

export const BASE_PATH_TO_COMMUNITY_GOALS_LIST = `${BASE_PATH_TO_CHALLENGE}/community-goals-list`;
export const BASE_PATH_TO_ADD_COMMUNITY_GOALS = `${BASE_PATH_TO_CHALLENGE}/add-community-goals`;
export const BASE_PATH_TO_UPDATE_COMMUNITY_GOALS = `${BASE_PATH_TO_CHALLENGE}/update-community-goals`;

export const BASE_PATH_TO_CCR_LIST = `${BASE_PATH_TO_CHALLENGE}/country-conversion-rates-list`;
export const BASE_PATH_TO_ADD_CCR = `${BASE_PATH_TO_CHALLENGE}/add-country-conversion-rates`;
export const BASE_PATH_TO_UPDATE_CCR = `${BASE_PATH_TO_CHALLENGE}/update-country-conversion-rates`;

export const BASE_PATH_TO_ROUTINE_ACTIVITY_LIST = `${BASE_PATH_TO_CHALLENGE}/routine-activity-list`;
export const BASE_PATH_TO_ADD_ROUTINE_ACTIVITY = `${BASE_PATH_TO_CHALLENGE}/add-routine-activity`;
export const BASE_PATH_TO_UPDATE_ROUTINE_ACTIVITY = `${BASE_PATH_TO_CHALLENGE}/update-routine-activity`;

export const BASE_PATH_TO_ROUTINE_PLANS_LIST = `${BASE_PATH_TO_CHALLENGE}/routine-plan-list`;
export const BASE_PATH_TO_ADD_ROUTINE_PLAN = `${BASE_PATH_TO_CHALLENGE}/add-routine-plan`;
export const BASE_PATH_TO_UPDATE_ROUTINE_PLAN = `${BASE_PATH_TO_CHALLENGE}/update-routine-plan`;