import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import classes from "../../Auth.module.css";
import AUTH_CONTEXT from "../../../../store";
import Button from "../../../../components/Button/Button";
import Card from "../../../../components/Card/Card";
import { AppVersionServices } from "../../../../services/auth_services";
import { BASE_PATH_TO_VIEW_VERSION_CONFIG } from "../../../../base_routes_paths/auth_paths";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";

const AddVersionConfig = (props) => {
    const navigate = useNavigate();
    const { token } = AUTH_CONTEXT()
    const [isLoading, setIsLoading] = useState(false);
    const [latestBuildNumber, setLatestBuildNumber] = useState("");
    const [isUpdateRequired, setIsUpdateRequired] = useState(false);
    const SubmitHandler = (event) => {
        event.preventDefault();
        setIsLoading(true);

        const Data = {
            IsUpdateRequired: isUpdateRequired,
            LatestBuildNumber: latestBuildNumber,
        }

        const FetchResponseSetReferrelConfig = async () => {
            try {
                let response = await AppVersionServices.addAppVersionServices(token, navigate, Data);
                if (response.status === 207) {
                    return toast
                        .error(response.error, {
                            position: "bottom-left",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                        })
                }
                navigate(BASE_PATH_TO_VIEW_VERSION_CONFIG);
                toast.success("Data added successfully!!", {
                    position: "bottom-left",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } catch (e) {
                setIsLoading(false);
                toast.error(e.message, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        };
        FetchResponseSetReferrelConfig();
    };

    return (
        <div className={classes.crumbs}>
            <Breadcrumbs aria-label="breadcrumb" >
                <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                    Dashboard
                </Link>
                <Typography color="text.primary">
                    Auth
                </Typography>
                <Typography color="text.primary">Add Version Configuration</Typography>
            </Breadcrumbs>
            <Card className={classes.input}>
                <div className={classes.Main_div}>Add App Version Config</div>
                <form onSubmit={SubmitHandler}>
                    <div className={classes.selectbox} style={{ marginBottom: "1rem" }}>
                        <label htmlFor="isUpdateRequired">isUpdateRequired : </label>
                        <select
                            name="isUpdateRequired"
                            id="isUpdateRequired"
                            value={isUpdateRequired}
                            onChange={(e) => {
                                setIsUpdateRequired(e.target.value);
                            }}
                        >
                            <option value={false}>False</option>
                            <option value={true}>True</option>
                        </select>
                    </div>
                    <div className={classes.selectbox}>
                        <label htmlFor="latestBuildNumber">latestBuildNumber :</label>
                        <input
                            type="number"
                            id="latestBuildNumber"
                            value={latestBuildNumber}
                            onChange={(e) => setLatestBuildNumber(e.target.value)}
                            placeholder="LatestBuildNumber"
                        />
                    </div>
                    {!isLoading && <Button type="submit">Add</Button>}
                    {isLoading && <p color="white">Sending request...</p>}
                </form>
            </Card>
        </div>
    );
};

export default AddVersionConfig;
