import { useState, useEffect } from "react";
import classes from "../../Challenge.module.css";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import AUTH_CONTEXT from "../../../../store";
import Loader from "../../../../components/Loader/Loader";
import Card from "../../../../components/Card/Card";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";
import { SoloChallengeServices } from "../../../../services/challenge_services";

const SoloChallengeDetails = () => {
    const navigate = useNavigate();
    const params = useParams();
    const { token } = AUTH_CONTEXT();
    const [isLoading, setIsLoading] = useState(false);
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [data, setData] = useState();

    const FetchResponse = async () => {
        try {
            let response = await SoloChallengeServices.getAllSoloChallenge(token, navigate, params.ID);

            setIsLoading(false);
            if (response.status === 207) {
                return toast
                    .error(response.error, {
                        position: "bottom-left",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                    })
            }
            setIsLoading(true);
            setIsDataFetched(true);
            toast.success("Data fetched successfully!!", {
                position: "bottom-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            console.log(response.data)
            return response.data;
        } catch (e) {
            setIsLoading(false);
            setIsDataFetched(false);
            console.log(e.message);
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    useEffect(() => {
        if (!isDataFetched) {
            FetchResponse()
                .then((result) => {
                    setData(result);
                })
        }
    }, []);

    return (
        !isLoading ? <Loader /> :
            <div className={classes.crumbs}>
                <Breadcrumbs aria-label="breadcrumb" >
                    <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                        Dashboard
                    </Link>
                    <Typography color="text.primary">
                        Challenege
                    </Typography>
                    <Typography color="text.primary">Details Solo Challenge</Typography>
                </Breadcrumbs>
                <Card className={classes.input}>
                    <div className={classes.Main_div}>Solo Challenge Details</div>
                    <div className={classes.selectbox}>
                        <label htmlFor="_id">Solo Challenge ID : </label>
                        <input
                            id="_id"
                            value={data?._id}
                            disabled
                        />
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="WorkoutID">Workout ID : </label>
                        <input
                            disabled
                            id="Level"
                            value={
                                data?.WorkoutID === 1 ? `${data?.WorkoutID}.Push-ups`
                                    : data?.WorkoutID === 2 ? `${data?.WorkoutID}.Planks`
                                        : data?.WorkoutID === 3 ? `${data?.WorkoutID}.Squats`
                                            : data?.WorkoutID === 4 ? `${data?.WorkoutID}.High Knees`
                                                : data?.WorkoutID === 5 ? `${data?.WorkoutID}.Lunges`
                                                    : data?.WorkoutID === 6 ? `${data?.WorkoutID}.Situps`
                                                        : data?.WorkoutID === 7 ? `${data?.WorkoutID}.Knee Pushups`
                                                            : data?.WorkoutID === 8 ? `${data?.WorkoutID}.Jumping Jacks`
                                                                : ``
                            }
                        />
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="Level">Level :</label>
                        <input
                            disabled
                            id="Level"
                            value={data?.Level}
                        />
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="SetPoint">SetPoint :</label>
                        <input
                            disabled
                            id="SetPoint"
                            value={data?.SetPoint}
                        />
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="Duration">Duration :</label>
                        <input
                            disabled
                            id="Duration"
                            value={data?.Duration}
                        />
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="TimeDuration">TimeDuration :</label>
                        <input
                            disabled
                            id="TimeDuration"
                            value={data?.TimeDuration}
                        />
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="Goal">Goal :</label>
                        <input
                            disabled
                            id="Goal"
                            value={data?.Goal}
                        />
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="Threshold">Threshold :</label>
                        <input
                            disabled
                            id="Threshold"
                            value={data?.Threshold}
                        />
                    </div>

                    <div className={classes.control}>
                        <label htmlFor="ChallengeThumbURL">Challenge Thumb URL : </label>
                        <input
                            id="ChallengeThumbURL"
                            disabled
                            value={data?.ChallengeThumbURL}
                        />
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="Rewards">Rewards :</label>
                        <input
                            disabled
                            id="Rewards"
                            value={data?.Rewards}
                        />
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="IsPaid">IsPaid : </label>
                        <select
                            name="IsPaid"
                            id="IsPaid"
                            value={data?.IsPaid}
                            disabled
                        >
                            <option value="false">No</option>
                            <option value="true">Yes</option>
                        </select>
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="IsActive">IsActive : </label>
                        <select
                            name="IsActive"
                            disabled
                            id="IsActive"
                            value={data?.IsActive}
                        >
                            <option value="false">No</option>
                            <option value="true">Yes</option>
                        </select>
                    </div>

                    <label htmlFor="Descriptions">Descriptions</label>
                    <textarea
                        disabled
                        style={{
                            height: "10rem",
                            maxWidth: "100%",
                            minWidth: "100%",
                            minHeight: "5rem",
                        }}
                        id="Descriptions"
                        value={data?.Descriptions}
                    />
                </Card>
            </div>
    );
};

export default SoloChallengeDetails;
