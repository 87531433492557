import { useState, useEffect } from "react";
import {
    DataGridPremium,
    GridToolbarContainer,
    GridToolbarExport,
} from "@mui/x-data-grid-premium";
import { useNavigate } from "react-router-dom";
import classes from "../../Data.module.css";
import { toast } from "react-toastify";
import {
    Breadcrumbs,
    FormControl,
    Link,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { BASE_PATH_TO_ANDROID_USER_SUBSCRIPTIONS_DETAILS, BASE_PATH_TO_HOME, BASE_PATH_TO_USER_DETAILS } from "../../../../base_routes_paths/data_paths";
import AUTH_CONTEXT from "../../../../store";
import { SubscriptionsDetailsServices } from "../../../../services/redeem_services";
import { BASE_PATH_TO_UPDATE_SUBSCRIPTIONS } from "../../../../base_routes_paths/redeem_paths";

const AndroidUserSubscriptionsList = () => {
    const { token, fileName } = AUTH_CONTEXT();
    const [isLoading, setIsLoading] = useState(false);
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [rowsCount, setRowsCount] = useState(1);
    const navigate = useNavigate();
    const EXPORT_FILENAME = `${fileName}_UserSubscriptionsList`;
    const [filterModel, setFilterModel] = useState({});

    const FetchResponse = async (
        page,
        pageSize,
        filterModel,
        sort,
        field
    ) => {
        try {
            setIsLoading(true);
            setIsDataFetched(false);
            let response = await SubscriptionsDetailsServices.androidUserSubscriptions(token, navigate, page, pageSize, sort, field, filterModel);
            if (response.status === 207) {
                return toast
                    .error(response.error, {
                        position: "bottom-left",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                    })
            }
            setIsLoading(true);
            setIsDataFetched(true);
            toast.success("Data fetched successfully!!", {
                position: "bottom-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setIsLoading(false);
            return response;
        } catch (e) {
            setIsLoading(false);
            setIsDataFetched(false);
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    const FetchResponseFilter = async (page, pageSize, filterModel, sort, field) => {
        try {
            setIsLoading(true);
            let response = await SubscriptionsDetailsServices.androidUserSubscriptions(token, navigate, page, pageSize, sort, field, filterModel);
            if (response.status === 207) {
                return toast
                    .error(response.error, {
                        position: "bottom-left",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                    })
            }
            toast.success("Data fetched successfully!!", {
                position: "bottom-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setIsLoading(false);
            return response;
        } catch (e) {
            setIsLoading(false);
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            if (!isDataFetched) {
                try {
                    const result = await FetchResponse(
                        page,
                        pageSize,
                        filterModel,
                        1
                    );
                    const res = result.data.data;
                    setRowsCount(result.data.length);
                    let input = res.map(({ _id: id, ...result }) => ({
                        id,
                        ...result,
                    }));
                    setData((prevData) => [...prevData, ...input]);
                } catch (err) { }
            }
        };

        fetchData();
    }, [page, data, pageSize, isDataFetched]);

    const userColumns = [
        {
            field: "UserID",
            headerName: "User ID",
            width: 230,
            renderCell: (params) => {
                return (
                    <a target="_blank" rel="noreferrer" href={`${BASE_PATH_TO_USER_DETAILS}/` + params.row.UserID}>{params.row.UserID}</a>
                );
            },
        },
        {
            field: "SubscriptionID",
            headerName: "SubscriptionID",
            width: 230,
            renderCell: (params) => {
                return (
                    <a target="_blank" rel="noreferrer" href={`${BASE_PATH_TO_UPDATE_SUBSCRIPTIONS}/` + params.row.SubscriptionID}>{params.row.SubscriptionID}</a>
                );
            },
        },
        {
            field: "SubscriptionName",
            headerName: "SubscriptionName",
            width: 230,
        },
        {
            field: "orderId",
            headerName: "Order Id",
            width: 230,
        },
        {
            field: "packageName",
            headerName: "Package Name",
            width: 120,
        },
        {
            field: "productId",
            headerName: "Product Id",
            width: 150,
        },
        {
            field: "purchaseTime",
            headerName: "Purchase Time (UTC)",
            width: 220,
        },
        {
            field: "PurchaseDate",
            headerName: "PurchaseDate",
            width: 220,
        },
        {
            field: "PurchaseTime",
            headerName: "PurchaseTime",
            width: 220,
        },
        {
            field: "purchaseState",
            headerName: "Purchase State",
            width: 120,
        },
        {
            field: "quantity",
            headerName: "Quantity",
            width: 80,
        },
        {
            field: "purchaseToken",
            headerName: "Purchase Token",
            width: 180,
            renderCell: (params) => {
                const link = params.row.purchaseToken;
                return (
                    <>
                        <ContentCopyIcon
                            sx={{ marginRight: "10px", cursor: "pointer" }}
                            onClick={() => {
                                navigator.clipboard.writeText(link);
                                toast.success("Copied Token!!");
                            }}
                            titleAccess="Copy"
                            fontSize="small"
                        />
                        {link}
                    </>
                );
            },
        },
        {
            field: "serverVerificationDataToken",
            headerName: "ServerVerificationDataToken",
            width: 300,
            renderCell: (params) => {
                const link = params.row.serverVerificationDataToken;
                return (
                    <>
                        <ContentCopyIcon
                            sx={{ marginRight: "10px", cursor: "pointer" }}
                            onClick={() => {
                                navigator.clipboard.writeText(link);
                                toast.success("Copied Token!!");
                            }}
                            fontSize="small"
                            titleAccess="Copy"
                        />
                        {link}
                    </>
                );
            },
        },
        {
            field: "autoRenewing",
            headerName: "Auto Renewing",
            width: 150,
        },
        {
            field: "acknowledged",
            headerName: "Acknowledged",
            width: 180,
        },
    ];

    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: 100,
            renderCell: (params) => {
                return (
                    <div className={classes.cellAction}>
                        <a target="_blank" rel="noreferrer" href={`${BASE_PATH_TO_ANDROID_USER_SUBSCRIPTIONS_DETAILS}/` + params.row.id}
                            style={{ textDecoration: "none" }}
                        >
                            <div className={classes.viewButton}>View</div>
                        </a>
                    </div>
                );
            },
        },
    ];

    const handleFilterModelChange = async (model) => {
        let items = model.items;

        const json = items.reduce((obj, item) => {
            if (
                item.value !== null &&
                item.value !== undefined &&
                item.value !== ""
            ) {
                if (item.field === "id") {
                    obj["_id"] = item.value;
                } else if (item.field === "quantity" || item.field === "purchaseState") {
                    obj[item.field] = +item.value;
                } else if (item.field === "acknowledged" || item.field === "autoRenewing") {
                    obj[item.field] = item.value.toLowerCase() === 'true';
                } else {
                    obj[item.field] = item.value;
                }
            }
            return obj;
        }, {});


        try {
            setFilterModel(json);
            setPage(1);

            setTimeout(async () => {
                const result = await FetchResponseFilter(1, pageSize, json, 1);

                if (result && result.data.data) {
                    const res = result.data.data;
                    setRowsCount(result.data.length);
                    setData(
                        res.map(({ _id: id, ...result }) => ({
                            id,
                            ...result,
                        }))
                    );
                }
            }, 500);
        } catch (err) { }
    };

    const handleSortModelChange = (model) => {
        let field = model.map((item) => {
            return item.field
        });

        const isSort = model.some((item) => item.sort === "asc");

        if (field) {
            setTimeout(async () => {
                const result = await FetchResponseFilter(
                    1,
                    pageSize,
                    filterModel,
                    isSort ? 1 : -1,
                    field
                );

                if (result && result.data) {
                    const res = result.data.data;
                    setRowsCount(result.data.length);
                    let input = res.map(({ _id: id, ...result }) => ({
                        id,
                        ...result,
                    }));
                    setData((prevData) => [...prevData, ...input]);
                }
            }, 100);
        }
    };

    function CustomToolbar() {
        const [selectedOption, setSelectedOption] = useState("UserID");
        const [value, setValue] = useState("");

        const handleOptionChange = (event) => {
            setSelectedOption(event.target.value);
            setValue("");
        };

        const generateJson = () => {
            const json = { [selectedOption]: value };

            if (selectedOption === "Mobile_Number") {
                json[selectedOption] = +value;
            }

            return json;
        };

        const json = generateJson();

        return (
            <GridToolbarContainer
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                }}
            >
                <GridToolbarExport
                    printOptions={{ disableToolbarButton: true }}
                    csvOptions={{ disableToolbarButton: true, fileName: EXPORT_FILENAME }}
                    excelOptions={{ fileName: EXPORT_FILENAME }}
                />
                <FormControl
                    style={{
                        display: "flex",
                        flexDirection: "row",
                    }}
                >
                    <Select
                        value={selectedOption}
                        onChange={handleOptionChange}
                        sx={{ width: "10rem" }}
                    >
                        <MenuItem value="UserID">UserID</MenuItem>
                        <MenuItem value="SubscriptionID">SubscriptionID</MenuItem>
                    </Select>
                    <TextField
                        id="outlined-basic"
                        label={selectedOption}
                        variant="filled"
                        type="text"
                        value={value}
                        onChange={(e) => {
                            setValue(e.target.value);
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={async () => {
                                            const result = await FetchResponseFilter(
                                                1,
                                                pageSize,
                                                json,
                                                1,
                                                ""
                                            );

                                            if (result && result.data.data) {
                                                const res = result.data.data;
                                                setRowsCount(result.data.length);
                                                setData(
                                                    res.map(({ _id: id, ...result }) => ({
                                                        id,
                                                        ...result,
                                                    }))
                                                );
                                            }
                                        }}
                                    >
                                        <SendIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormControl>
            </GridToolbarContainer >
        );
    }

    return (
        <div className={classes.datatable}>
            <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 1 }}>
                <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                    Dashboard
                </Link>
                <Typography color="text.primary">
                    Data
                </Typography>
                <Typography color="text.primary">Android User Subscriptions List</Typography>
            </Breadcrumbs>
            <DataGridPremium
                slots={{
                    toolbar: CustomToolbar,
                }}
                rows={data}
                rowCount={rowsCount}
                columns={userColumns.concat(actionColumn)}
                pageSize={pageSize}
                pagination
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: pageSize, page: page - 1 },
                    },
                }}
                pageSizeOptions={[20, 50, 100]}
                disableRowSelectionOnClick
                onPaginationModelChange={({ pageSize, page }) => {
                    setPage(page + 1);
                    setPageSize(pageSize);
                    FetchResponse(page + 1, pageSize, filterModel, 1);
                }}
                filterMode="server"
                onFilterModelChange={(model) => {
                    handleFilterModelChange(model);
                    setPage(1);
                }}
                onSortModelChange={(model) => handleSortModelChange(model)}
                loading={isLoading}
                checkboxSelectionVisibleOnly
                checkboxSelection
            />
        </div>
    );
};

export default AndroidUserSubscriptionsList;
