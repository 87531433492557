import { useState } from "react";
import { useNavigate } from "react-router-dom";
import classes from "../../Redeem.module.css";
import { toast } from "react-toastify";
import Button from "../../../../components/Button/Button";
import Card from "../../../../components/Card/Card";
import ARENO_API_ENDPOINT from "../../../../api";
import AUTH_CONTEXT from "../../../../store";
import { StatusCode } from "../../../../utils";
import { Breadcrumbs, CircularProgress, Link, Typography } from "@mui/material";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";
import { BASE_PATH_TO_VIEW_SUBSCRIPTIONS_DETAILS } from "../../../../base_routes_paths/redeem_paths";
import { SubscriptionsDetailsServices } from "../../../../services/redeem_services";

const CreateSubscriptionDetails = () => {
    const navigate = useNavigate();

    const [Subscription, setSubscription] = useState([{
        KeyID: "",
        SubscriptionID: "",
        SubscriptionMrp: "",
        SubscriptionName: "",
        SubscriptionDescriptions: {
            "US": "",
            "IN": "",
            "CA": ""
        },
    }]);
    const [Descriptions, setDescriptions] = useState({
        "en-US": "",
        "en-IN": "",
        "en-CA": ""
    });
    const [SubscriptionFeatures, setSubscriptionFeatures] = useState([{
        FeatureName: "",
        FeatureProps: [{
            KeyID: "",
            KeyValue: ""
        }]
    }]);
    const [isLoading, setIsLoading] = useState(false);

    const { token } = AUTH_CONTEXT();
    const [isSubscriptionIDLoading, setIsSubscriptionIDLoading] = useState(false);
    const [subscriptionDetails, setSubscriptionDetails] = useState([]);

    const fetchSubscriptionDetails = async () => {
        setIsSubscriptionIDLoading(true)
        try {
            const response = await fetch(
                `${ARENO_API_ENDPOINT}/redeem/GetAllSubscriptionDetailsForApp`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );
            StatusCode(response, navigate);
            const data = await response.json();
            setSubscriptionDetails(data.data);
            setIsSubscriptionIDLoading(false)
        } catch (e) {
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    const handleSelectClick = () => {
        if (subscriptionDetails.length === 0) {
            fetchSubscriptionDetails();
        }
    };

    const submitHandler = (event) => {
        event.preventDefault();
        setIsLoading(true);

        const Data = {
            Subscription: Subscription,
            SubscriptionFeatures: SubscriptionFeatures,
            Descriptions: Descriptions
        }

        const FetchApi = async () => {
            try {
                let response = await SubscriptionsDetailsServices.addSubscriptionDetails(token, navigate, Data);

                if (response.status === 207) {
                    return toast
                        .error(response.error, {
                            position: "bottom-left",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                        })
                }
                navigate(BASE_PATH_TO_VIEW_SUBSCRIPTIONS_DETAILS)
                toast.success("Data added successfully!!", {
                    position: "bottom-left",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } catch (e) {
                setIsLoading(false);
                toast.error(e.message, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        };
        FetchApi();
    };

    const handleFormChangeSubscription = (name, event, index) => {
        const value = event.target.value;
        setSubscription((prevSubscriptions) => {
            return prevSubscriptions.map((subscription, i) => {
                if (i === index) {
                    return {
                        ...subscription,
                        [name]: value,
                    };
                }
                return subscription;
            });
        });
    };

    const addFieldsSubscription = (event) => {
        event.preventDefault();
        let newField = {
            KeyID: "",
            SubscriptionID: "",
            SubscriptionName: "",
            SubscriptionDescriptions: {
                "US": "",
                "IN": "",
                "CA": ""
            },
            SubscriptionMrp: "",
        };
        setSubscription((prevSubscriptionFeatures) => [
            ...prevSubscriptionFeatures,
            newField,
        ]);
    };


    const removeFieldsSubscription = (index) => {
        setSubscription((prevSubscription) => {
            const data = [...prevSubscription];
            data.splice(index, 1);
            return data;
        });
    };

    const handleFormChangeSubscriptionFeatureName = (index, event) => {
        const { value } = event.target;

        setSubscriptionFeatures((prevSubscriptionFeatures) => {
            const updatedFeatures = [...prevSubscriptionFeatures];
            updatedFeatures[index] = {
                ...updatedFeatures[index],
                FeatureName: value
            };
            return updatedFeatures;
        });
    };

    const handleFormChangeSubscriptionFeatures = (featureIndex, propIndex, event) => {
        const { name, value } = event.target;

        setSubscriptionFeatures((prevSubscriptionFeatures) => {
            const updatedFeatures = [...prevSubscriptionFeatures];
            updatedFeatures[featureIndex] = {
                ...updatedFeatures[featureIndex],
                FeatureProps: updatedFeatures[featureIndex].FeatureProps.map((prop, pIndex) => {
                    if (pIndex === propIndex) {
                        return {
                            ...prop,
                            [name]: value,
                        };
                    }
                    return prop;
                }),
            };
            return updatedFeatures;
        });
    };

    const addFieldsSubscriptionFeatures = (event) => {
        event.preventDefault();
        let newField = {
            FeatureName: "",
            FeatureProps: [{
                KeyID: "",
                KeyValue: ""
            }]
        };
        setSubscriptionFeatures((prevSubscriptionFeatures) => [
            ...prevSubscriptionFeatures,
            newField
        ]);
    };

    const removeFieldsSubscriptionFeatures = (index) => {
        setSubscriptionFeatures((prevSubscriptionFeatures) =>
            prevSubscriptionFeatures.filter((_, i) => i !== index)
        );
    };

    const addFieldsFeatureProps = (featureIndex) => {
        setSubscriptionFeatures((prevSubscriptionFeatures) => {
            return prevSubscriptionFeatures.map((feature, index) => {
                if (index === featureIndex) {
                    return {
                        ...feature,
                        FeatureProps: [...(feature.FeatureProps || []), { KeyID: "", KeyValue: "" }]
                    };
                }
                return feature;
            });
        });
    };


    const removeFieldsFeatureProps = (featureIndex, propIndex) => {
        setSubscriptionFeatures((prevSubscriptionFeatures) => {
            const updatedFeatures = [...prevSubscriptionFeatures];
            updatedFeatures[featureIndex] = {
                ...updatedFeatures[featureIndex],
                FeatureProps: updatedFeatures[featureIndex].FeatureProps.filter((_, i) => i !== propIndex)
            };
            return updatedFeatures;
        });
    };

    const handleSubsDescriptionChange = (e, locale, index) => {
        const { value } = e.target;
        setSubscription((prevSubscription) => {
            return prevSubscription.map((sub, idx) => {
                if (idx === index) {
                    return {
                        ...sub,
                        SubscriptionDescriptions: {
                            ...sub.SubscriptionDescriptions,
                            [locale]: value,
                        },
                    };
                }
                return sub;
            });
        });
    };

    const handleDescriptionChange = (e, locale) => {
        setDescriptions(prevState => ({
            ...prevState,
            [locale]: e.target.value
        }));
    };

    return (
        <div className={classes.crumbs}>
            <Breadcrumbs aria-label="breadcrumb" >
                <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                    Dashboard
                </Link>
                <Typography color="text.primary">
                    Redeem
                </Typography>
                <Typography color="text.primary">Create Subscription Details</Typography>
            </Breadcrumbs>
            <Card className={classes.input}>
                <div className={classes.Main_div}>Create Subscription Details</div>
                <form onSubmit={submitHandler}>

                    <div className={classes.subfields}>
                        <h3>Subscription</h3>
                        {Subscription.map((Subs, index) => (
                            <div key={index}>
                                <div className={classes.subfields}>
                                    <div className={classes.subfieldsControl}>
                                        <div className={classes.control}>
                                            <label htmlFor="KeyID">KeyID : </label>
                                            <input
                                                type="text"
                                                name="KeyID"
                                                placeholder="Enter KeyID"
                                                value={Subs.KeyID}
                                                required
                                                onChange={(e) => handleFormChangeSubscription("KeyID", e, index)}
                                            />
                                        </div>
                                        <div className={classes.selectbox}>
                                            <label htmlFor="SubscriptionID">Subscription ID : </label>
                                            <select
                                                name="SubscriptionID"
                                                id="SubscriptionID"
                                                placeholder="Select SubscriptionID"
                                                onClick={handleSelectClick}
                                                style={{ width: "300px" }}
                                                value={Subs.SubscriptionID}
                                                onChange={(e) => handleFormChangeSubscription("SubscriptionID", e, index)}
                                            >
                                                <option value="">Free</option>
                                                {subscriptionDetails.map((subscription) => (
                                                    <option key={subscription._id} value={subscription._id}>
                                                        {subscription.Name} ({subscription._id})
                                                    </option>
                                                ))}
                                            </select>
                                            <span style={{ marginLeft: "1rem", marginTop: "1px" }}>
                                                {isSubscriptionIDLoading && (
                                                    <CircularProgress size={25} thickness={5} />
                                                )}
                                            </span>
                                        </div>
                                        <div className={classes.control}>
                                            <label htmlFor="SubscriptionMrp">SubscriptionMrp : </label>
                                            <input
                                                type="text"
                                                name="SubscriptionMrp"
                                                placeholder="Enter SubscriptionMrp"
                                                value={Subs.SubscriptionMrp}
                                                onChange={(e) => handleFormChangeSubscription("SubscriptionMrp", e, index)}
                                            />
                                        </div>
                                        <div className={classes.control}>
                                            <label htmlFor="SubscriptionName">SubscriptionName: </label>
                                            <input
                                                type="text"
                                                name="SubscriptionName"
                                                placeholder="Enter SubscriptionName"
                                                value={Subs.SubscriptionName}
                                                onChange={(e) => handleFormChangeSubscription("SubscriptionName", e, index)}
                                            />
                                        </div>
                                        <div className={classes.detailItem}>
                                            <span className={classes.itemKey}>SubscriptionDescription : </span>
                                            <div className={classes.subfieldsControl}>
                                                <div className={classes.subfieldsControl}>
                                                    <div className={classes.control}>
                                                        <label htmlFor="US">Description (US)</label>
                                                        <input
                                                            type="text"
                                                            id="US"
                                                            value={Subs.SubscriptionDescriptions["US"]}
                                                            onChange={(e) => handleSubsDescriptionChange(e, "US", index)}
                                                            placeholder="Enter Subscription Descriptions for US"
                                                        />
                                                    </div>
                                                    <div className={classes.control}>
                                                        <label htmlFor="IN">Description (IN)</label>
                                                        <input
                                                            type="text"
                                                            id="IN"
                                                            value={Subs.SubscriptionDescriptions["IN"]}
                                                            onChange={(e) => handleSubsDescriptionChange(e, "IN", index)}
                                                            placeholder="Enter Subscription Descriptions for IN"
                                                        />
                                                    </div>
                                                    <div className={classes.control}>
                                                        <label htmlFor="CA">Description (CA)</label>
                                                        <input
                                                            type="text"
                                                            id="CA"
                                                            value={Subs.SubscriptionDescriptions["CA"]}
                                                            onChange={(e) => handleSubsDescriptionChange(e, "CA", index)}
                                                            placeholder="Enter Subscription Descriptions for CA"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button
                                    onClick={(event) => {
                                        event.preventDefault();
                                        removeFieldsSubscription(index);
                                    }}
                                >
                                    Remove
                                </button>
                            </div>
                        ))}
                        <button
                            onClick={addFieldsSubscription}
                            style={{
                                marginTop: "10px",
                            }}
                        >
                            Add More Subscription Info....
                        </button>
                    </div>

                    <div className={classes.subfields} style={{ marginBottom: "20px" }} >
                        <h3 style={{ marginBottom: "10px" }}>Subscription Features</h3>
                        <div className={classes.subfieldsControl}>
                            {SubscriptionFeatures.map((Features, index) => (
                                <>
                                    <div className={classes.subfields} style={{ marginBottom: "20px" }}>
                                        <div key={index}>
                                            <div className={classes.control}>
                                                <label htmlFor="FeatureName">FeatureName : </label>
                                                <input
                                                    type="text"
                                                    name="FeatureName"
                                                    placeholder="Enter FeatureName"
                                                    value={Features.FeatureName}
                                                    onChange={(event) => handleFormChangeSubscriptionFeatureName(index, event)}
                                                />
                                            </div>
                                            <h3 style={{ marginBottom: "10px", marginLeft: "15px" }}>FeatureProps</h3>
                                            {Features.FeatureProps.map((FeatureProp, propIndex) => (
                                                <div className={classes.subfields} style={{ marginBottom: "10px" }}>
                                                    <div className={classes.subcontrol}>
                                                        <label htmlFor={`KeyID`} style={{ width: "500px" }}>
                                                            KeyID :
                                                        </label>
                                                        <input
                                                            width={"100%"}
                                                            type="text"
                                                            placeholder="Enter KeyID"
                                                            value={FeatureProp.KeyID}
                                                            name="KeyID"
                                                            required
                                                            onChange={(event) => handleFormChangeSubscriptionFeatures(index, propIndex, event)}
                                                        />
                                                    </div>
                                                    <div className={classes.subcontrol}>
                                                        <label htmlFor={`KeyValue`} style={{ width: "500px" }}>
                                                            KeyValue :
                                                        </label>
                                                        <input
                                                            type="text"
                                                            placeholder="Enter KeyValue"
                                                            value={FeatureProp.KeyValue}
                                                            name="KeyValue"
                                                            onChange={(event) => handleFormChangeSubscriptionFeatures(index, propIndex, event)}
                                                        />
                                                    </div>
                                                    <button
                                                        onClick={(event) => {
                                                            event.preventDefault();
                                                            removeFieldsFeatureProps(index, propIndex);
                                                        }}
                                                    >
                                                        Remove FeatureProps
                                                    </button>
                                                </div>
                                            ))}
                                            <button
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    addFieldsFeatureProps(index);
                                                }}
                                            >
                                                Add More FeatureProps...
                                            </button>

                                        </div>
                                    </div>
                                    <button
                                        onClick={(event) => {
                                            event.preventDefault();
                                            removeFieldsSubscriptionFeatures(index);
                                        }}
                                    >
                                        Remove Subscription Feature
                                    </button>
                                </>
                            ))}
                            <div>
                                <button
                                    onClick={addFieldsSubscriptionFeatures}
                                    style={{
                                        marginTop: "10px",
                                    }}
                                >
                                    Add More Subscription Features...
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className={classes.subfields}>
                        <h3>Descriptions</h3>
                        <div className={classes.subfields}>
                            <div className={classes.control}>
                                <label htmlFor="en-US">Description (en-US)</label>
                                <input
                                    type="text"
                                    id="en-US"
                                    value={Descriptions["en-US"]}
                                    onChange={(e) => handleDescriptionChange(e, "en-US")}
                                    placeholder="Enter Description for en-US"
                                />
                            </div>
                            <div className={classes.control}>
                                <label htmlFor="en-IN">Description (en-IN)</label>
                                <input
                                    type="text"
                                    id="en-IN"
                                    value={Descriptions["en-IN"]}
                                    onChange={(e) => handleDescriptionChange(e, "en-IN")}
                                    placeholder="Enter Description for en-IN"
                                />
                            </div>
                            <div className={classes.control}>
                                <label htmlFor="en-CA">Description (en-CA)</label>
                                <input
                                    type="text"
                                    id="en-CA"
                                    value={Descriptions["en-CA"]}
                                    onChange={(e) => handleDescriptionChange(e, "en-CA")}
                                    placeholder="Enter Description for en-CA"
                                />
                            </div>
                        </div>
                    </div>

                    {!isLoading && <Button type="submit">Add</Button>}
                    {isLoading && <p color="white">Sending request...</p>}
                </form>
            </Card >
        </div>
    );
};

export default CreateSubscriptionDetails;
