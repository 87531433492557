import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    palette: {
        primary: {
            // Main Theme Color
            main: "#7060F2",
            // ContrastText used for subtitles
            contrastText: "#8480ae",
        },
        secondary: {
            main: "#9E6EFF",
        },
        error: {
            main: "#f44336",
        },
        success: {
            main: "#4caf50",
        },
    },
    shape: {
        borderRadius: 8,
    },
    background: {
        // For subContainers
        main: "#F3F6FD",
        // For inputs bg
        secondary: "#fafafa",
    },
    typography: {
        // For Form labels
        fontSize: 14,
        // PageTitle
        h1: {
            fontWeight: 500,
            color: "#7060F2",
            fontSize: 36,
        },
        h2: {
            fontWeight: 500,
            color: "#7060F2",
            fontSize: 24,
        },
        h3: {
            fontSize: 18,
            color: "#7060F2",
        },
        h4: {
            fontSize: 14,
            color: "#8480ae",
            fontWeight: 400,
        },
        // FormLabel and others
        body1: {
            fontSize: 16,
            color: "#8480ae",
            fontWeight: 400,
        },
    },
    components: {
        MuiIconButton: {
            styleOverrides: {
                root: {
                    "&:hover": {
                        backgroundColor: "transparent",
                    },
                },
            },
        },
    },
    breakpoints: {
        values: {
            xs: 300,
            sm: 600,
            md: 994,
            lg: 1200,
            xl: 1536,
        },
    },
});

export default theme;
