import classes from "./Chart.module.css";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { StatusCode } from "../../utils";
import AUTH_CONTEXT from "../../store";
import ARENO_API_ENDPOINT from "../../api";

const NewRegisteredUserChart = ({ aspect, title }) => {
  const { token } = AUTH_CONTEXT();
  const [isDataFetched, setIsDataFetched] = useState(false);
  let initialData = [];
  const [data, setData] = useState(initialData);
  const [TimeDuration, setTimeDuration] = useState("week");

  const TimeDurationHandler = (event) => {
    event.preventDefault();
    setTimeDuration(event.target.value);
    setIsDataFetched(false);
  };

  const FetchApi = async () => {
    try {
      let response = await fetch(`${ARENO_API_ENDPOINT}/auth/GetAllUsers`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer" + " " + token,
        },
        body: JSON.stringify({
          TimeDuration: TimeDuration,
        }),
      });

      StatusCode(response, Navigate);
      response = await response.json();
      setIsDataFetched(true);
      return response.data.GraphData;
    } catch (e) {
      setIsDataFetched(false);
      console.log(e.message);
      alert(e.message);
    }
  };
  useEffect(() => {
    async function FetchData() {
      try {
        if (!isDataFetched) {
          let result = await FetchApi();
          setData(result);
        }
      } catch (err) {
        console.log(err.message);
      }
    }
    FetchData();
  }, [TimeDuration]);

  return (
    <div className={classes.chart}>
      <div className={classes.title}>
        <div className={classes.subtitle}>{title}</div>
        <div className={classes.selectbox} style={{ width: "200px" }}>
          <label htmlFor="CategoryName" style={{ fontSize: "1rem" }}>
            Duration :{" "}
          </label>
          <select
            name="CategoryName"
            id="CategoryName"
            value={TimeDuration}
            onChange={(e) => TimeDurationHandler(e)}
            style={{ width: "100px" }}
          >
            <option value="week">Week</option>
            <option value="month">Month</option>
            <option value="halfyear">6 months</option>
            <option value="year">1 Year</option>
          </select>
        </div>
      </div>
      <div>
        <ResponsiveContainer width="100%" aspect={aspect}>
          <AreaChart
            className={classes.AreaChart}
            width={650}
            height={250}
            data={data}
            margin={{ top: 1, right: 20, left: -20, bottom: 0 }}
          >
            <defs>
              <linearGradient id="total" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="black" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis dataKey="Day" stroke="black" />
            <YAxis dataKey="Count" stroke="black"></YAxis>
            <CartesianGrid
              strokeDasharray="3 3"
              className={classes.chartGrid}
            />
            <Tooltip />
            <Area
              type="monotone"
              dataKey="Count"
              stroke="#8884d8"
              fillOpacity={1}
              fill="url(#total)"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
      <div className={classes.BottomHeading}>(Date)</div>
    </div>
  );
};

export default NewRegisteredUserChart;
