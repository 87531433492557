import { toast } from "react-toastify";
import ARENO_API_ENDPOINT from "../../api";
import { StatusCode } from "../../utils";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const MenuURLServices = {
    addMenuURL: async (token, navigate, Data) => {
        try {
            let response = await fetch(`${ARENO_API_ENDPOINT}/dashboard/SetMenuUrl`, {
                method: "POST",
                body: JSON.stringify(Data),
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${token}`,
                },
            });

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    updateMenuURL: async (token, navigate, Data) => {
        try {
            let response = await fetch(`${ARENO_API_ENDPOINT}/dashboard/AdminUpdateMenuURL`, {
                method: "POST",
                body: JSON.stringify(Data),
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${token}`,
                },
            });

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    getAllMenuURL: async (token, navigate) => {
        try {
            let response = await fetch(`${ARENO_API_ENDPOINT}/dashboard/AdminGetMenuUrl`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${token}`,
                },
            });

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    getMenuURLByID: async (token, navigate, ID) => {
        try {
            let response = await fetch(`${ARENO_API_ENDPOINT}/dashboard/AdminGetMenuUrlByID`, {
                method: "POST",
                body: JSON.stringify({
                    MenuUrlID: ID
                }),
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${token}`,
                },
            });

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    deleteMenuURL: async (id, token) => {
        confirmAlert({
            title: "Confirm to delete",
            message: "Are you sure you want to delete this?",
            buttons: [
                {
                    label: "Yes",
                    onClick: async () => {
                        try {
                            let response = await fetch(`${ARENO_API_ENDPOINT}/dashboard/DeleteMenuUrl`, {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                    authorization: token,
                                },
                                body: JSON.stringify({
                                    MenuUrlID: id,
                                }),
                            });

                            StatusCode(response);

                            const data = await response.json();

                            if (response.status === 200) {
                                toast.success("Menu URL deleted successfully!", {
                                    position: "bottom-left",
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                                window.location.reload();
                            } else {
                                toast.error(data.error || "Failed to delete.", {
                                    position: "bottom-left",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                            }
                        } catch (error) {
                            toast.error(error.message || "An error occurred while deleting.", {
                                position: "bottom-left",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                        }
                    },
                },
                {
                    label: "No",
                },
            ],
        });
    },
}

const DeviceURLServices = {
    addPointConfig: async (token, navigate, key, Data) => {
        try {
            let response = await fetch(`${ARENO_API_ENDPOINT}/dashboard/SetDeviceUrls`, {
                method: "POST",
                body: JSON.stringify({
                    Key: +key,
                    Data: Data,
                }),
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${token}`,
                },
            });

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    getAllDeviceURL: async (token, navigate) => {
        try {
            let response = await fetch(`${ARENO_API_ENDPOINT}/dashboard/AdminGetAllDeviceUrls`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${token}`,
                },
            });

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    deleteDeviceURL: async (id, token) => {
        confirmAlert({
            title: "Confirm to delete",
            message: "Are you sure you want to delete this?",
            buttons: [
                {
                    label: "Yes",
                    onClick: async () => {
                        try {
                            let response = await fetch(`${ARENO_API_ENDPOINT}/dashboard/AdminDeleteDeviceUrl`, {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                    authorization: token,
                                },
                                body: JSON.stringify({
                                    Key: +id,
                                }),
                            });

                            StatusCode(response);

                            const data = await response.json();

                            if (response.status === 200) {
                                toast.success("Device URL deleted successfully!", {
                                    position: "bottom-left",
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                                window.location.reload();
                            } else {
                                toast.error(data.error || "Failed to delete.", {
                                    position: "bottom-left",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                            }
                        } catch (error) {
                            toast.error(error.message || "An error occurred while deleting.", {
                                position: "bottom-left",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                        }
                    },
                },
                {
                    label: "No",
                },
            ],
        });
    },
}

const UserGoalsServices = {
    getAllGoals: async (token, navigate, page, pageSize, sort, field, filterModel) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/dashboard/AdminGetAllUserGoals?page=${page}&limit=${pageSize}&sort=${sort}&field=${field} `,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token} `,
                    },
                    body: JSON.stringify({
                        filters: filterModel,
                    }),
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
}

const TestimonialsServices = {
    getTestimonialByID: async (token, navigate, ID) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/dashboard/AdminGetTestimonialByUserID`,
                {
                    method: "POST",
                    body: JSON.stringify({
                        UserID: ID,
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    getAllTestimonials: async (token, navigate) => {
        try {
            let response = await fetch(`${ARENO_API_ENDPOINT}/dashboard/AdminGetAllTestimonials`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${token}`,
                },
            });

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    updateTestimonials: async (token, navigate, Data) => {
        try {
            let response = await fetch(`${ARENO_API_ENDPOINT}/dashboard/AdminUpdateTestimonial`, {
                method: "POST",
                body: JSON.stringify(Data),
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${token}`,
                },
            });

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    deleteTestimonial: async (id, token) => {
        confirmAlert({
            title: "Confirm to delete",
            message: "Are you sure you want to delete this?",
            buttons: [
                {
                    label: "Yes",
                    onClick: async () => {
                        try {
                            let response = await fetch(
                                `${ARENO_API_ENDPOINT}/dashboard/AdminDeleteTestimonialByUserID`,
                                {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                        authorization: `Bearer ${token}`,
                                    },
                                    body: JSON.stringify({
                                        _id: id,
                                    }),
                                }
                            );

                            StatusCode(response);

                            const data = await response.json();

                            if (response.status === 200) {
                                toast.success("Testimonial deleted successfully!", {
                                    position: "bottom-left",
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                                window.location.reload();
                            } else {
                                toast.error(data.error || "Failed to delete.", {
                                    position: "bottom-left",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                            }
                        } catch (error) {
                            toast.error(error.message || "An error occurred while deleting.", {
                                position: "bottom-left",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                        }
                    },
                },
                {
                    label: "No",
                },
            ],
        });
    },
}

export { MenuURLServices, DeviceURLServices, UserGoalsServices, TestimonialsServices }