import { useState } from "react";
import { useNavigate } from "react-router-dom";
import classes from "../../Challenge.module.css";
import { toast } from "react-toastify";
import AUTH_CONTEXT from "../../../../store";
import Card from "../../../../components/Card/Card";
import Button from "../../../../components/Button/Button";
import { CCRServices } from "../../../../services/challenge_services";
import { BASE_PATH_TO_CCR_LIST } from "../../../../base_routes_paths/challenge_paths";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";

const AddCCR = () => {
    const navigate = useNavigate();
    const [Country, setCountry] = useState("");
    const [CountryCurrency, setCountryCurrency] = useState("");
    const [ConversionRate, setConversionRate] = useState("");
    const [ConversionRatePerPoint, setConversionRatePerPoint] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const { token } = AUTH_CONTEXT()

    const SubmitHandler = (event) => {
        event.preventDefault();
        setIsLoading(true);

        const Data = {
            Country: Country,
            CountryCurrency: CountryCurrency,
            ConversionRate: +ConversionRate,
            ConversionRatePerPoint: +ConversionRatePerPoint
        }

        const FetchResponse = async () => {
            try {
                let response = await CCRServices.addCCR(token, navigate, Data);

                setIsLoading(false);
                if (response.status === 207) {
                    return toast
                        .error(response.error, {
                            position: "bottom-left",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                        })
                }
                navigate(BASE_PATH_TO_CCR_LIST);
                toast.success("Data fetched successfully!!", {
                    position: "bottom-left",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } catch (e) {
                setIsLoading(false);
                toast.error(e.message, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        };
        FetchResponse();
    };

    return (
        <div className={classes.crumbs}>
            <Breadcrumbs aria-label="breadcrumb" >
                <Link underline="hover" color="inherit" to={BASE_PATH_TO_HOME}>
                    Dashboard
                </Link>
                <Typography color="text.primary">
                    Challenege
                </Typography>
                <Typography color="text.primary">Add Country Conversion Rate</Typography>
            </Breadcrumbs>
            <Card className={classes.input}>
                <div className={classes.Main_div}>Add New Country Conversion Rate</div>
                <form onSubmit={SubmitHandler}>
                    <div className={classes.control}>
                        <label htmlFor="Country">Country</label>
                        <input
                            type="text"
                            id="Country"
                            required
                            value={Country}
                            onChange={(e) => setCountry(e.target.value)}
                            placeholder="Enter Name for Country"
                        />
                    </div>
                    <div className={classes.control}>
                        <label htmlFor="CountryCurrency">CountryCurrency : </label>
                        <input
                            required
                            id="CountryCurrency"
                            type="text"
                            placeholder="Enter CountryCurrency"
                            value={CountryCurrency}
                            onChange={(e) => setCountryCurrency(e.target.value)}
                        />
                    </div>
                    <div className={classes.control}>
                        <label htmlFor="ConversionRate">ConversionRate : </label>
                        <input
                            id="ConversionRate"
                            type="number"
                            placeholder="Enter ConversionRate"
                            value={ConversionRate}
                            onChange={(e) => setConversionRate(e.target.value)}
                        />
                    </div>
                    <div className={classes.control}>
                        <label htmlFor="ConversionRatePerPoint">ConversionRatePerPoint : </label>
                        <input
                            id="ConversionRatePerPoint"
                            type="number"
                            placeholder="Enter ConversionRatePerPoint"
                            value={ConversionRatePerPoint}
                            onChange={(e) => setConversionRatePerPoint(e.target.value)}
                        />
                    </div>

                    {!isLoading && <Button type="submit">Add</Button>}
                    {isLoading && <p color="white">Sending request...</p>}
                </form>
            </Card>
        </div>
    );
};

export default AddCCR;
