import { useState } from "react";
import { useNavigate } from "react-router-dom";
import classes from "../../Redeem.module.css";
import AUTH_CONTEXT from "../../../../store";
import { toast } from "react-toastify";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";
import { PaymentInvoiceService } from "../../../../services/redeem_services";
import Button from "../../../../components/Button/Button";
import Card from "../../../../components/Card/Card";
import { BASE_PATH_TO_PAYMENT_INVOICE_CONFIG_LIST } from "../../../../base_routes_paths/redeem_paths";

const AddSubPaymentInvoice = () => {
    const navigate = useNavigate();
    const { token } = AUTH_CONTEXT();

    const [UserID, setUserID] = useState('');
    const [InvoiceNumber, setInvoiceNumber] = useState('');
    const [transactionId, setTransactionId] = useState('');
    const [SubscriptionID, setSubscriptionID] = useState('');
    const [InvoiceUrl, setInvoiceUrl] = useState('');
    const [MessageID, setMessageID] = useState('');
    const [IsSharedViaMail, setIsSharedViaMail] = useState(false);
    const [IsNewSub, setIsNewSub] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const SubmitHandler = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        const data = {
            UserID,
            InvoiceNumber,
            transactionId,
            SubscriptionID,
            InvoiceUrl,
            MessageID,
            IsSharedViaMail,
            IsNewSub
        };

        try {
            const response = await PaymentInvoiceService.addInvoice(token, navigate, data);

            setIsLoading(false);
            if (response.status === 207) {
                return toast.error(response.error, {
                    position: 'bottom-left',
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: 'light'
                });
            }

            navigate(BASE_PATH_TO_PAYMENT_INVOICE_CONFIG_LIST);
            toast.success('SubPaymentInvoice added successfully!', {
                position: 'bottom-left',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light'
            });
        } catch (error) {
            setIsLoading(false);
            toast.error(error.message, {
                position: 'bottom-left',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light'
            });
        }
    };

    return (
        <div className={classes.crumbs}>
            <Breadcrumbs aria-label="breadcrumb" >
                <Link underline="hover" color="inherit" to={BASE_PATH_TO_HOME}>
                    Dashboard
                </Link>
                <Typography color="text.primary">
                    Redeem
                </Typography>
                <Typography color="text.primary">Add Sub Payment Invoice Config</Typography>
            </Breadcrumbs>
            <Card className={classes.input}>
                <div className={classes.Main_div}>Add New Sub Payment Invoice Config</div>
                <form onSubmit={SubmitHandler}>
                    <div className={classes.control}>
                        <label htmlFor="UserID">UserID</label>
                        <input
                            type="text"
                            id="UserID"
                            value={UserID}
                            onChange={(e) => setUserID(e.target.value)}
                            placeholder="Enter Name for Country"
                        />
                    </div>

                    <div className={classes.control}>
                        <label htmlFor="InvoiceNumber">InvoiceNumber</label>
                        <input
                            type="text"
                            id="InvoiceNumber"
                            value={InvoiceNumber}
                            onChange={(e) => setInvoiceNumber(e.target.value)}
                            placeholder="Enter Name for Country"
                        />
                    </div>

                    <div className={classes.control}>
                        <label htmlFor="transactionId">transactionId</label>
                        <input
                            type="text"
                            id="transactionId"
                            value={transactionId}
                            onChange={(e) => setTransactionId(e.target.value)}
                            placeholder="Enter Name for Country"
                        />
                    </div>

                    <div className={classes.control}>
                        <label htmlFor="SubscriptionID">SubscriptionID</label>
                        <input
                            type="text"
                            id="SubscriptionID"
                            value={SubscriptionID}
                            onChange={(e) => setSubscriptionID(e.target.value)}
                            placeholder="Enter Name for Country"
                        />
                    </div>

                    <div className={classes.control}>
                        <label htmlFor="InvoiceUrl">InvoiceUrl</label>
                        <input
                            type="text"
                            id="InvoiceUrl"
                            value={InvoiceUrl}
                            onChange={(e) => setInvoiceUrl(e.target.value)}
                            placeholder="Enter Name for Country"
                        />
                    </div>

                    <div className={classes.control}>
                        <label htmlFor="MessageID">MessageID</label>
                        <input
                            type="text"
                            id="MessageID"
                            value={MessageID}
                            onChange={(e) => setMessageID(e.target.value)}
                            placeholder="Enter Name for Country"
                        />
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="IsSharedViaMail">IsSharedViaMail : </label>
                        <select
                            name="IsSharedViaMail"
                            id="IsSharedViaMail"
                            value={IsSharedViaMail}
                            onChange={(e) => setIsSharedViaMail(e.target.value)}
                        >
                            <option value={false}>False</option>
                            <option value={true}>True</option>
                        </select>
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="IsNewSub">IsNewSub : </label>
                        <select
                            name="IsNewSub"
                            id="IsNewSub"
                            value={IsNewSub}
                            onChange={(e) => setIsNewSub(e.target.value)}
                        >
                            <option value={false}>False</option>
                            <option value={true}>True</option>
                        </select>
                    </div>

                    {!isLoading && <Button type="submit">Add</Button>}
                    {isLoading && <p color="white">Sending request...</p>}
                </form>
            </Card>
        </div>
    );
};

export default AddSubPaymentInvoice;
