import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import classes from "./UserSnapShots.module.css";
import { ImageList, ImageListItem } from "@mui/material";
import AUTH_CONTEXT from "../../../store";
import { StatusCode } from "../../../utils";
import ARENO_API_ENDPOINT from "../../../api";
import Loader from "../../../components/Loader/Loader";

const UserSnapShotsConfig = () => {
  const { token } = AUTH_CONTEXT()
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();
  const navigate = useNavigate();

  const FetchResponseGetAllUserDetails = async () => {
    try {
      let response = await fetch(`${ARENO_API_ENDPOINT}/challenges/GetAllSnapShotsByUserID`, {
        method: "POST",
        body: JSON.stringify({
          UserID: params.UserID,
          ChallengeID: params.ChallengeID,
        }),
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer" + " " + token,
        },
      });
      setIsLoading(false);
      StatusCode(response, navigate);
      response = await response.json();
      let result = response.data;
      let input = result.map(({ _id: id, ...result }) => ({
        id,
        ...result,
      }));
      setIsLoading(true);
      setData(input);
      setIsDataFetched(true);
    } catch (e) {
      setIsLoading(false);
      setIsDataFetched(false);
      console.log(e.message);
      alert(e.message);
    }
  };

  useEffect(() => {
    if (!isDataFetched) {
      FetchResponseGetAllUserDetails();
    }
  }, []);

  return (
    <>
      {!isLoading && <Loader />}
      <div className={classes.single}>
        {isDataFetched && (
          <div className={classes.mainContainer}>
            <div className={classes.centerContent}>
              <ImageList cols={3} gap={30}>
                {data.map((item, index) => (
                  <ImageListItem key={index} sx={{ gap: 16 }}>
                    <img src={data[index].Url} alt="Areno" loading="lazy" />;
                  </ImageListItem>
                ))}
              </ImageList>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default UserSnapShotsConfig;
