import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import classes from "../../Redeem.module.css";
import { toast } from "react-toastify";
import Button from "../../../../components/Button/Button";
import Loader from "../../../../components/Loader/Loader";
import Card from "../../../../components/Card/Card";
import AUTH_CONTEXT from "../../../../store";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";
import { BASE_PATH_TO_ADD_SUBSCRIPTIONS_DETAILS, BASE_PATH_TO_UPDATE_SUBSCRIPTIONS_DETAILS } from "../../../../base_routes_paths/redeem_paths";
import { SubscriptionsDetailsServices } from "../../../../services/redeem_services";

const SubscriptionsDetails = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isDataFetched, setIsDataFetched] = useState(false);

    const [Subscription, setSubscription] = useState([]);
    const [ID, setID] = useState("");
    const [Descriptions, setDescriptions] = useState({
        "en-US": "",
        "en-IN": "",
        "en-CA": ""
    });
    const [SubscriptionFeatures, setSubscriptionFeatures] = useState([]);
    const { token } = AUTH_CONTEXT()
    const navigate = useNavigate();

    const FetchResponse = async () => {
        try {
            let response = await SubscriptionsDetailsServices.getSubscriptionDetails(token, navigate);
            if (response.status === 207) {
                return toast
                    .error(response.error, {
                        position: "bottom-left",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                    })
            }
            setIsLoading(true);
            setIsDataFetched(true);
            toast.success("Data fetched successfully!!", {
                position: "bottom-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return response.data;
        } catch (e) {
            setIsLoading(false);
            setIsDataFetched(false);
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    useEffect(() => {
        if (!isDataFetched) {
            FetchResponse()
                .then((result) => {
                    setSubscription(result.Subscription);
                    setSubscriptionFeatures(result.SubscriptionFeatures);
                    setID(result._id)
                    setDescriptions(result.Descriptions)
                })
                .catch((err) => console.log(err));
        }
    }, []);

    return (
        <div className={classes.crumbs}>
            <Breadcrumbs aria-label="breadcrumb" >
                <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                    Dashboard
                </Link>
                <Typography color="text.primary">
                    Redeem
                </Typography>
                <Typography color="text.primary">View Subscription Details</Typography>
            </Breadcrumbs>
            <Card className={classes.input}>
                {isLoading && (
                    <div>
                        <div className={classes.Main_div}>Subscription Details</div>
                        <div className={classes.subfields}>
                            <h3>Subscription</h3>
                            {Subscription.map((Subs, index) => (
                                <div key={index}>
                                    <div className={classes.subfields} style={{ marginBottom: "20px" }}>
                                        <div className={classes.subfieldsControl}>
                                            <div className={classes.detailItem}>
                                                <span className={classes.itemKey}>KeyID : </span>
                                                <span className={classes.itemValue}>{Subs.KeyID}</span>
                                            </div>
                                            <div className={classes.detailItem}>
                                                <span className={classes.itemKey}>SubscriptionID : </span>
                                                <span className={classes.itemValue}>{Subs.SubscriptionID}</span>
                                            </div>
                                            <div className={classes.detailItem}>
                                                <span className={classes.itemKey}>SubscriptionMrp : </span>
                                                <span className={classes.itemValue}>{Subs.SubscriptionMrp}</span>
                                            </div>
                                            <div className={classes.detailItem}>
                                                <span className={classes.itemKey}>SubscriptionName : </span>
                                                <span className={classes.itemValue}>{Subs.SubscriptionName}</span>
                                            </div>
                                            <div className={classes.detailItem}>
                                                <span className={classes.itemKey}>SubscriptionDescription : </span>
                                                <div className={classes.subfields}>
                                                    <div className={classes.control}>
                                                        <label htmlFor="US">Description (US)</label>
                                                        <input
                                                            disabled
                                                            value={Subs.SubscriptionDescriptions["US"]}
                                                        />
                                                    </div>
                                                    <div className={classes.control}>
                                                        <label htmlFor="IN">Description (IN)</label>
                                                        <input
                                                            disabled
                                                            value={Subs.SubscriptionDescriptions["IN"]}
                                                        />
                                                    </div>
                                                    <div className={classes.control}>
                                                        <label htmlFor="CA">Description (CA)</label>
                                                        <input
                                                            disabled
                                                            value={Subs.SubscriptionDescriptions["CA"]}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className={classes.subfields} style={{ marginBottom: "20px" }} >
                            <h3>Subscription Features</h3>
                            {SubscriptionFeatures.map((Features, index) => (
                                <div key={index}>
                                    <div className={classes.subfields}>
                                        <div className={classes.detailItem}>
                                            <span className={classes.itemKey}>FeatureName : </span>
                                            <span className={classes.itemValue}>{Features.FeatureName}</span>
                                        </div>
                                        <h3 style={{ marginBottom: "10px" }}>FeatureProps :</h3>
                                        {Features.FeatureProps.map((FeatureProp) =>
                                        (
                                            <div className={classes.subfields} style={{ marginBottom: "20px", border: "2px solid black" }}>
                                                <div className={classes.detailItem}>
                                                    <span className={classes.itemKey}>KeyID : </span>
                                                    <span className={classes.itemValue}>{FeatureProp.KeyID}</span>
                                                </div>
                                                <div className={classes.detailItem}>
                                                    <span className={classes.itemKey}>KeyValue : </span>
                                                    <span className={classes.itemValue}>{FeatureProp.KeyValue}</span>
                                                </div>
                                            </div>
                                        ))
                                        }
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className={classes.subfields}>
                            <h3>Descriptions</h3>
                            <div className={classes.subfields}>
                                <div className={classes.control}>
                                    <label htmlFor="en-US">Description (en-US)</label>
                                    <input
                                        disabled
                                        value={Descriptions["en-US"]}
                                        placeholder="Enter Description for en-US"
                                    />
                                </div>
                                <div className={classes.control}>
                                    <label htmlFor="en-IN">Description (en-IN)</label>
                                    <input
                                        disabled
                                        value={Descriptions["en-IN"]}
                                        placeholder="Enter Description for en-IN"
                                    />
                                </div>
                                <div className={classes.control}>
                                    <label htmlFor="en-CA">Description (en-CA)</label>
                                    <input
                                        disabled
                                        value={Descriptions["en-CA"]}
                                        placeholder="Enter Description for en-CA"
                                    />
                                </div>
                            </div>
                        </div>

                        <div style={{ display: "flex" }}>
                            <div>
                                <Button
                                    onClick={() => {
                                        navigate(`${BASE_PATH_TO_UPDATE_SUBSCRIPTIONS_DETAILS}/${ID}`);
                                    }}
                                >
                                    Edit Subscription Details
                                </Button>
                            </div>
                            <div style={{ marginLeft: "1rem" }}>
                                <Button
                                    onClick={() => {
                                        navigate(BASE_PATH_TO_ADD_SUBSCRIPTIONS_DETAILS);
                                    }}

                                >
                                    Add New Subscription Details
                                </Button>
                            </div>
                        </div>
                    </div>
                )
                }
            </Card >
            {!isLoading && <Loader />}
        </div>
    );
};

export default SubscriptionsDetails;
