export const BASE_PATH_TO_SUNDRIES = "/sundries";

// ==============================|| REMINDER IMAGES ||============================== //

export const BASE_PATH_TO_ADD_REMINDER_IMAGES = `${BASE_PATH_TO_SUNDRIES}/add-reminder-images`
export const BASE_PATH_TO_UPDATE_REMINDER_IMAGES = `${BASE_PATH_TO_SUNDRIES}/update-reminder-images`
export const BASE_PATH_TO_REMINDER_IMAGES_LIST = `${BASE_PATH_TO_SUNDRIES}/all-reminder-images`

// ==============================|| ANNOUNCEMNTS ||============================== //

export const BASE_PATH_TO_ANNOUNCEMENTS_LIST = `${BASE_PATH_TO_SUNDRIES}/all-announcement`
export const BASE_PATH_TO_ADD_ANNOUNCEMENTS = `${BASE_PATH_TO_SUNDRIES}/add-announcement`
export const BASE_PATH_TO_UPDATE_ANNOUNCEMENTS = `${BASE_PATH_TO_SUNDRIES}/update-announcement`

// ==============================|| ENQUIRIES ||============================== //

export const BASE_PATH_TO_ENQUIRY_LIST = `${BASE_PATH_TO_SUNDRIES}/all-enquiries`