import { toast } from "react-toastify";
import ARENO_API_ENDPOINT from "../../api";
import { StatusCode } from "../../utils";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const CommunityURLServices = {
    getCommunityLink: async (token, navigate) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/auth/AdminGetCommunityURL`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    addCommunityLink: async (token, navigate, URL) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/auth/AddCommunityUrlConfig`,
                {
                    method: "POST",
                    body: JSON.stringify({
                        Url: URL,
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );
            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
};

const AppVersionServices = {
    getAppVersionServices: async (token, navigate) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/auth/GetAppVersionConfig`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    addAppVersionServices: async (token, navigate, Data) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/auth/CreateAppVersionConfig`,
                {
                    method: "POST",
                    body: JSON.stringify(Data),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
};

const SpecialReferraServices = {
    getAllSpecialReferral: async (token, navigate, ID) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/auth/GetAllSpecialReferralCode`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        ReferralCode: ID,
                    }),
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    addSpecialReferral: async (token, navigate, Data) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/auth/AddSpecialReferralCode`,
                {
                    method: "POST",
                    body: JSON.stringify(Data),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    updateSpecialReferral: async (token, navigate, Data) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/auth/UpdateSpecialReferralCode`,
                {
                    method: "POST",
                    body: JSON.stringify(Data),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    deletePointPurchaseVouchers: async (ReferralCode, token) => {
        confirmAlert({
            title: "Confirm to delete",
            message: "Are you sure you want to delete this?",
            buttons: [
                {
                    label: "Yes",
                    onClick: async () => {
                        try {
                            let response = await fetch(
                                `${ARENO_API_ENDPOINT}/auth/DeleteSpecialReferralCodeConfig`,
                                {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                        authorization: `Bearer ${token}`,
                                    },
                                    body: JSON.stringify({
                                        ReferralCode: ReferralCode,
                                    }),
                                }
                            );

                            StatusCode(response);

                            const data = await response.json();

                            if (response.status === 200) {
                                toast.success("Special Referral deleted successfully!", {
                                    position: "bottom-left",
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                                window.location.reload();
                            } else {
                                toast.error(data.error || "Failed to delete.", {
                                    position: "bottom-left",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                            }
                        } catch (error) {
                            toast.error(
                                error.message || "An error occurred while deleting.",
                                {
                                    position: "bottom-left",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                }
                            );
                        }
                    },
                },
                {
                    label: "No",
                },
            ],
        });
    },
};

const OnboardinScreenQuestionsServices = {
    getOnboardinScreenQuestions: async (token, navigate) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/auth/AdminGetAllOnboardingScreenQuestions`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    getOnboardinScreenQuestionsyID: async (token, navigate, ID) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/auth/AdminGetAllOnboardingScreenQuestions`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        _id: ID,
                    }),
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    addOnboardinScreenQuestions: async (token, navigate, Data) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/auth/AddOnboardingScreenQuestions`,
                {
                    method: "POST",
                    body: JSON.stringify(Data),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    updateOnboardinScreenQuestions: async (token, navigate, Data) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/auth/AdminUpdateOnboardingScreenQuestions`,
                {
                    method: "POST",
                    body: JSON.stringify(Data),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
};

const OnboardinScreenRecommendationsServices = {
    getAllOnboardinScreenRecommendations: async (token, navigate) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/auth/AdminGetAllOnboardingScreenRecommendation`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    getOnboardinScreenQuestionsByID: async (token, navigate, ID) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/auth/AdminGetAllOnboardingScreenRecommendation`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        _id: ID,
                    }),
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    getAllUsersOnboardinScreenRecommendations: async (token, navigate) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/auth/AdmingGetAllUserOnboardingRecommendationData`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    getUsersOnboardinScreenRecommendationsByID: async (
        token,
        navigate,
        UserID
    ) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/auth/GetUserOnboardingRecommendationData`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        UserID: UserID,
                    }),
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    addOnboardinScreenRecommendations: async (token, navigate, Data) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/auth/AddOnboardingScreenRecommendation`,
                {
                    method: "POST",
                    body: JSON.stringify(Data),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    updateOnboardinScreenRecommendations: async (token, navigate, Data) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/auth/AdminUpdateOnboardingScreenRecommendation`,
                {
                    method: "POST",
                    body: JSON.stringify(Data),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    deleteOnboardinScreenRecommendations: async (id, token) => {
        confirmAlert({
            title: "Confirm to delete",
            message: "Are you sure you want to delete this?",
            buttons: [
                {
                    label: "Yes",
                    onClick: async () => {
                        try {
                            let response = await fetch(
                                `${ARENO_API_ENDPOINT}/auth/AdminDeletOnboardingScreenRecommendation`,
                                {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                        authorization: `Bearer ${token}`,
                                    },
                                    body: JSON.stringify({
                                        _id: id,
                                    }),
                                }
                            );

                            StatusCode(response);

                            const data = await response.json();

                            if (response.status === 200) {
                                toast.success("Voucher deleted successfully!", {
                                    position: "bottom-left",
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                                window.location.reload();
                            } else {
                                toast.error(data.error || "Failed to delete.", {
                                    position: "bottom-left",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                            }
                        } catch (error) {
                            toast.error(
                                error.message || "An error occurred while deleting.",
                                {
                                    position: "bottom-left",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                }
                            );
                        }
                    },
                },
                {
                    label: "No",
                },
            ],
        });
    },
};

const IPAddressServices = {
    getAllIPAddress: async (token, navigate) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/auth/AdminGetAllIpAddress`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    addIpAddress: async (token, navigate, IPAddress) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/auth/AddIpAddressInAllowedList`,
                {
                    method: "POST",
                    body: JSON.stringify({
                        IpAddress: IPAddress,
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    updateIpAddress: async (token, navigate, IPAddress) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/auth/AdminUpdateIpAddress`,
                {
                    method: "POST",
                    body: JSON.stringify({
                        IpAddress: IPAddress,
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
};

const UserServices = {
    getAllIPAddress: async (token, navigate) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/auth/AdminGetAllIpAddress`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    updateIpAddress: async (token, navigate, IPAddress) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/auth/AdminUpdateIpAddress`,
                {
                    method: "POST",
                    body: JSON.stringify({
                        IpAddress: IPAddress,
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    blockUser: async (id, flag, token) => {
        confirmAlert({
            title: "Confirm to submit",
            message:
                "Are you sure to block this User, this user will no longer be visible to others.",
            buttons: [
                {
                    label: "Yes",
                    onClick: async () => {
                        try {
                            let response = await fetch(
                                `${ARENO_API_ENDPOINT}/auth/HandleUserStatus`,
                                {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                        authorization: token,
                                    },
                                    body: JSON.stringify({
                                        UserID: id,
                                        IsActive: flag,
                                    }),
                                }
                            );

                            StatusCode(response);

                            const data = await response.json();

                            if (response.status === 200) {
                                toast.success("Voucher deleted successfully!", {
                                    position: "bottom-left",
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                                window.location.reload();
                            } else {
                                toast.error(data.error || "Failed to delete.", {
                                    position: "bottom-left",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                            }
                        } catch (error) {
                            toast.error(
                                error.message || "An error occurred while deleting.",
                                {
                                    position: "bottom-left",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                }
                            );
                        }
                    },
                },
                {
                    label: "No",
                },
            ],
        });
    },
};

const SnapshotsService = {
    deleteSnapshots: async (id, token) => {
        confirmAlert({
            title: "Confirm to submit",
            message: "Are you sure to delete these?",
            buttons: [
                {
                    label: "Yes",
                    onClick: async () => {
                        try {
                            let response = await fetch(
                                `${ARENO_API_ENDPOINT}/challenges/DeleteUserAttemptForAChallenge`,
                                {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                        authorization: token,
                                    },
                                    body: JSON.stringify({
                                        _ids: id,
                                    }),
                                }
                            );

                            StatusCode(response);

                            const data = await response.json();

                            if (response.status === 200) {
                                toast.success("Voucher deleted successfully!", {
                                    position: "bottom-left",
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                                window.location.reload();
                            } else {
                                toast.error(data.error || "Failed to delete.", {
                                    position: "bottom-left",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                            }
                        } catch (error) {
                            toast.error(
                                error.message || "An error occurred while deleting.",
                                {
                                    position: "bottom-left",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                }
                            );
                        }
                    },
                },
                {
                    label: "No",
                },
            ],
        });
    },
};

const FeedbackServices = {
    getAllFeedbacks: async (token, navigate, UserID) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/auth/AdminGetFeedbackByUserID`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        UserID: UserID,
                    }),
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    updateFeedback: async (token, navigate, Data) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/auth/AdminUpdateFeedbackList`,
                {
                    method: "POST",
                    body: JSON.stringify(Data),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
};

export {
    CommunityURLServices,
    AppVersionServices,
    SpecialReferraServices,
    OnboardinScreenQuestionsServices,
    OnboardinScreenRecommendationsServices,
    IPAddressServices,
    UserServices,
    SnapshotsService,
    FeedbackServices,
};
