import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import classes from "../../Data.module.css";
import {
    DataGridPremium,
    GridToolbarContainer,
    GridToolbarExport,
} from "@mui/x-data-grid-premium";
import { toast } from "react-toastify";
import AUTH_CONTEXT from "../../../../store";
import Loader from "../../../../components/Loader/Loader";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { BASE_PATH_TO_HOME, BASE_PATH_TO_UPDATE_TESTIMONIAL, BASE_PATH_TO_USER_DETAILS } from "../../../../base_routes_paths/data_paths";
import { TestimonialsServices } from "../../../../services/dashboard_services";

const userColumns = [
    {
        field: "id",
        headerName: "Testimonial ID",
        width: 230,
    },
    {
        field: "UserID",
        headerName: "UserID",
        width: 230,
        renderCell: (params) => {
            return (
                <a target="_blank" rel="noreferrer" href={`${BASE_PATH_TO_USER_DETAILS}/` + params.row.UserID}>
                    {params.row.UserID}
                </a>
            );
        },
    },
    {
        field: "UserName",
        headerName: "UserName",
        width: 180,
    },
    {
        field: "Age",
        headerName: "Age",
        width: 180,
    },
    {
        field: "Gender",
        headerName: "Gender",
        width: 180,
    },
    {
        field: "ProfileImage",
        headerName: "Profile",
        width: 80,
        renderCell: (params) => {
            return (
                <div className={classes.cellWithImg}>
                    <img
                        className={classes.cellImg}
                        src={
                            params.row.ProfileImage || "https://www.areno.in/images/logo.png"
                        }
                        alt=""
                    />
                </div>
            );
        },
    },
    {
        field: "VideoURL",
        headerName: "VideoURL",
        width: 250,
        renderCell: (params) => {
            const link = params.row.VideoURL;
            return (
                <a href={link} target="_blank" rel="noopener noreferrer">
                    {link}
                </a>
            );
        },
    },
    {
        field: "Thumbnail",
        headerName: "Thumbnail",
        width: 250,
        renderCell: (params) => {
            const link = params.row.Thumbnail;
            return (
                <a href={link} target="_blank" rel="noopener noreferrer">
                    {link}
                </a>
            );
        },
    },
    {
        field: "IsVerified",
        headerName: "IsVerified",
        width: 100,
    },
    {
        field: "Description",
        headerName: "Description",
        width: 180,
    },
];

const Testimonials = () => {
    const [isLoaderLoading, setIsLoaderLoading] = useState(false);
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [data, setData] = useState([]);
    const { token, fileName } = AUTH_CONTEXT()
    const EXPORT_FILENAME = `${fileName}_TestimonialList`;
    const navigate = useNavigate();

    useEffect(() => {
        const functionCall = async () => {
            try {
                let response = await TestimonialsServices.getAllTestimonials(token, navigate);
                if (response.status === 207) {
                    return toast
                        .error(response.error, {
                            position: "bottom-left",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                        })
                }
                let result = response.data;
                let input = result.map((result) => ({
                    id: result._id,
                    ...result,
                }));
                setIsDataFetched(true);
                setData(input);
                toast.success("Data fetched successfully!!", {
                    position: "bottom-left",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setIsLoaderLoading(false);
            } catch (e) {
                toast.error(e.message, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        };

        if (!isDataFetched) {
            setIsLoaderLoading(true);
            functionCall();
        } else {
            return;
        }
    }, [isDataFetched, token]);

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport
                    printOptions={{ disableToolbarButton: true }}
                    csvOptions={{ fileName: EXPORT_FILENAME }}
                    excelOptions={{ fileName: EXPORT_FILENAME }}
                />
            </GridToolbarContainer>
        );
    }

    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: 200,
            renderCell: (params) => {
                return (
                    <div className={classes.cellAction}>
                        <a target="_blank" rel="noreferrer" href={`${BASE_PATH_TO_UPDATE_TESTIMONIAL}/` + params.row.UserID}
                            style={{ textDecoration: "none" }}
                        >
                            <div className={classes.viewButton}>Update</div>
                        </a>
                        <div
                            className={classes.deleteButton}
                            onClick={() => TestimonialsServices.deleteTestimonial(params.row.id, token)}
                        >
                            Delete
                        </div>
                    </div>
                );
            },
        },
    ];

    return (
        <div>
            {isLoaderLoading === true ? (
                <Loader />
            ) : (
                <>
                    {isDataFetched && (
                        <div className={classes.datatable}>
                            <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 1 }}>
                                <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                                    Dashboard
                                </Link>
                                <Typography color="text.primary">
                                    Data
                                </Typography>
                                <Typography color="text.primary">Testimonials</Typography>
                            </Breadcrumbs>
                            <DataGridPremium
                                checkboxSelection
                                rows={data}
                                columns={userColumns.concat(actionColumn)}
                                pageSize={20}
                                rowsPerPageOptions={[20]}
                                slots={{
                                    toolbar: CustomToolbar,
                                }}
                                initialState={{
                                    pagination: {
                                        paginationModel: { pageSize: 25, page: 0 },
                                    },
                                }}
                                pagination
                                disableRowSelectionOnClick
                                checkboxSelectionVisibleOnly
                            />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default Testimonials;
