import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import classes from "../../Challenge.module.css";
import { toast } from "react-toastify";
import AUTH_CONTEXT from "../../../../store";
import ARENO_API_ENDPOINT from "../../../../api";
import { StatusCode } from "../../../../utils";
import Card from "../../../../components/Card/Card";
import Button from "../../../../components/Button/Button";
import { Breadcrumbs, CircularProgress, Link, Typography } from "@mui/material";
import { CorporateServices } from "../../../../services/challenge_services";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";
import { BASE_PATH_TO_CORPORATE_LIST } from "../../../../base_routes_paths/challenge_paths";

const NewCorporateID = () => {
    const navigate = useNavigate();
    const { token } = AUTH_CONTEXT();
    const CorporateIDRef = useRef("");
    const CorporateNameRef = useRef("");
    const CorporateImageRef = useRef("");
    const CorporateImageDetailedScreenRef = useRef("");
    const EmailDomainRef = useRef("");
    const [isEmailRequired, setIsEmailRequired] = useState(false);
    const IsActiveRef = useRef("");
    const [isSubscribedRef, setIsSubscribedRef] = useState(false);
    const IsConsolidatedLeaderBoardRequiredRef = useRef("");
    const IsReferralPointCountRef = useRef("");
    const IsRegistrationPointCountRef = useRef("");
    const IsDailyRewardPointCountRef = useRef("");
    const IsCaloriesBasedLeaderboardRef = useRef("");
    const SubscriptionStartsOnRef = useRef("");
    const SubscriptionEndsOnRef = useRef("");
    const SubscriptionIDForUserRef = useRef("");
    const [isLoading, setIsLoading] = useState(false);
    const [inputFields, setInputFields] = useState([
        { TeamID: "", TeamName: "", TeamProfileUrl: "" },
    ]);
    const [isSubscriptionIDLoading, setIsSubscriptionIDLoading] = useState(false);
    const [inputCategoryIdPointCountFields, setInputCategoryIdPointCountFields] =
        useState([{ CategoryID: "" }]);
    const [subscriptionDetails, setSubscriptionDetails] = useState([]);

    const fetchSubscriptionDetails = async () => {
        setIsSubscriptionIDLoading(true)
        try {
            const response = await fetch(
                `${ARENO_API_ENDPOINT}/redeem/GetAllSubscriptionDetailsForApp`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );
            StatusCode(response, navigate);
            const data = await response.json();
            setSubscriptionDetails(data.data);
            setIsSubscriptionIDLoading(false)
        } catch (e) {
            setIsSubscriptionIDLoading(false)
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    const handleSelectClick = () => {
        if (subscriptionDetails.length === 0) {
            fetchSubscriptionDetails();
        }
    };

    const SubmitHandler = (event) => {
        event.preventDefault();
        setIsLoading(true);

        const Data = {
            CorporateID: CorporateIDRef.current.value,
            CorporateName: CorporateNameRef.current.value,
            EmailDomain: EmailDomainRef.current.value,
            IsEmailRequired: isEmailRequired,
            CorporateTeamIDs: inputFields,
            CorporateImage: CorporateImageRef.current.value,
            CorporateImageDetailedScreen: CorporateImageDetailedScreenRef.current.value,
            SubscriptionStartsOn: SubscriptionStartsOnRef.current.value,
            SubscriptionEndsOn: SubscriptionEndsOnRef.current.value,
            CategoryIdPointCount: inputCategoryIdPointCountFields,
            IsConsolidatedLeaderBoardRequired: IsConsolidatedLeaderBoardRequiredRef.current.value,
            IsReferralPointCount: IsReferralPointCountRef.current.value,
            IsRegistrationPointCount: IsRegistrationPointCountRef.current.value,
            IsDailyRewardPointCount: IsDailyRewardPointCountRef.current.value,
            IsCaloriesBasedLeaderboard: IsCaloriesBasedLeaderboardRef.current.value,
            IsSubscribed: isSubscribedRef,
            IsActive: IsActiveRef.current.value,
            SubscriptionIDForUser: SubscriptionIDForUserRef.current.value,
        }

        const FetchApi = async () => {
            try {
                let response = await CorporateServices.addCorporate(token, navigate, Data);

                setIsLoading(false);
                if (response.status === 207) {
                    return toast
                        .error(response.error, {
                            position: "bottom-left",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                        })
                }
                if (response.status === 207) {
                    return toast
                        .error(response.error, {
                            position: "bottom-left",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                        })
                }
                navigate(BASE_PATH_TO_CORPORATE_LIST);
                toast.success("Data added successfully!!", {
                    position: "bottom-left",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } catch (e) {
                setIsLoading(false);
                console.log(e.message);
                toast.error(e.message, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        };
        FetchApi();
    };

    const handleFormChange = (index, event) => {
        let data = [...inputFields];
        data[index][event.target.name] = event.target.value;
        setInputFields(data);
    };

    const addFields = (event) => {
        event.preventDefault();
        let newfield = { TeamID: "", TeamName: "", TeamProfileUrl: "" };
        setInputFields([...inputFields, newfield]);
    };

    const removeFields = (index) => {
        let data = [...inputFields];
        data.splice(index, 1);
        setInputFields(data);
    };

    const handleFormChangeCategory = (index, event) => {
        let data = [...inputCategoryIdPointCountFields];
        const { name, value } = event.target;
        data[index][name] = name === "CategoryID" ? parseInt(value, 10) : value;
        setInputCategoryIdPointCountFields(data);
    };

    const addFieldsCategory = (event) => {
        event.preventDefault();
        let newfield = { CategoryID: "" };
        setInputCategoryIdPointCountFields([
            ...inputCategoryIdPointCountFields,
            newfield,
        ]);
    };

    const removeFieldsCategory = (index) => {
        let data = [...inputCategoryIdPointCountFields];
        data.splice(index, 1);
        setInputCategoryIdPointCountFields(data);
    };

    return (
        <div className={classes.crumbs}>
            <Breadcrumbs aria-label="breadcrumb" >
                <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                    Dashboard
                </Link>
                <Typography color="text.primary">
                    Challenege
                </Typography>
                <Typography color="text.primary">Add Corporate</Typography>
            </Breadcrumbs>
            <Card className={classes.input}>
                <div className={classes.Main_div}>Add Corporate Details</div>
                <form onSubmit={SubmitHandler}>
                    <div className={classes.selectbox}>
                        <label htmlFor="CorporateID">ID :</label>
                        <input
                            type="text"
                            id="CorporateID"
                            required
                            ref={CorporateIDRef}
                            placeholder="Enter CorporateID"
                        />
                    </div>
                    <div className={classes.control}>
                        <label htmlFor="CorporateName">Corporate Name :</label>
                        <input
                            type="text"
                            id="CorporateName"
                            required
                            ref={CorporateNameRef}
                            placeholder="Enter Corporate name"
                        />
                    </div>
                    <div className={classes.control}>
                        <label htmlFor="CorporateImage">CorporateImage :</label>
                        <input
                            type="text"
                            id="CorporateImage"
                            ref={CorporateImageRef}
                            placeholder="Enter Corporate Image URL"
                        />
                    </div>
                    <div className={classes.control}>
                        <label htmlFor="CorporateImageDetailedScreen">
                            CorporateImageDetailedScreen :
                        </label>
                        <input
                            type="text"
                            id="CorporateImageDetailedScreen"
                            ref={CorporateImageDetailedScreenRef}
                            placeholder="Enter Corporate Image Detailed Screen URL"
                        />
                    </div>
                    <div className={classes.subfields}>
                        <h3>Team details</h3>
                        {inputFields.map((input, index) => {
                            return (
                                <div key={index} className={classes.subfieldsControl}>
                                    <div className={classes.control}>
                                        <label htmlFor="TeamID">ID</label>
                                        <input
                                            name="TeamID"
                                            placeholder="TeamID"
                                            value={input.TeamID}
                                            onChange={(event) => handleFormChange(index, event)}
                                        />
                                    </div>
                                    <div className={classes.control}>
                                        <label htmlFor="TeamName">Name</label>
                                        <input
                                            name="TeamName"
                                            placeholder="TeamName"
                                            value={input.TeamName}
                                            onChange={(event) => handleFormChange(index, event)}
                                        />
                                    </div>
                                    <div className={classes.control}>
                                        <label htmlFor="TeamProfileUrl">Profile Link</label>
                                        <input
                                            name="TeamProfileUrl"
                                            placeholder="TeamProfileUrl"
                                            value={input.TeamProfileUrl}
                                            onChange={(event) => handleFormChange(index, event)}
                                        />
                                    </div>
                                    <button onClick={() => removeFields(index)}>Remove</button>
                                </div>
                            );
                        })}
                        <button onClick={addFields}>Add More...</button>
                    </div>
                    <div className={classes.subfields}>
                        <h3>CategoryIdPointCount</h3>
                        {inputCategoryIdPointCountFields.map((input, index) => {
                            return (
                                <div key={index} className={classes.subfieldsControl}>
                                    <div className={classes.control}>
                                        <label htmlFor="CategoryID">CategoryID</label>
                                        <input
                                            name="CategoryID"
                                            placeholder="CategoryID"
                                            value={input.CategoryID}
                                            onChange={(event) => handleFormChangeCategory(index, event)}
                                        />
                                    </div>
                                    <button onClick={() => removeFieldsCategory(index)}>
                                        Remove
                                    </button>
                                </div>
                            );
                        })}
                        <button onClick={addFieldsCategory}>Add More...</button>
                    </div>
                    <div>
                        <div className={classes.selectbox}>
                            <label htmlFor="IsEmailRequired">IsEmailRequired</label>
                            <select
                                name="IsEmailRequired"
                                id="Category"
                                value={isEmailRequired}
                                onChange={(e) => setIsEmailRequired(e.target.value === "true")}
                            >
                                <option value="false">False</option>
                                <option value="true">True</option>
                            </select>
                        </div>
                        {isEmailRequired === true && (
                            <div className={classes.control}>
                                <label htmlFor="EmailDomain">Email domain</label>
                                <input
                                    type="text"
                                    id="EmailDomain"
                                    ref={EmailDomainRef}
                                    placeholder="Enter domain for email"
                                />
                            </div>
                        )}
                    </div>
                    <div className={classes.selectbox}>
                        <label htmlFor="IsActive">IsActive</label>
                        <select name="IsActive" id="Category" ref={IsActiveRef}>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div className={classes.selectbox}>
                        <label htmlFor="IsConsolidatedLeaderBoardRequired">
                            IsConsolidatedLeaderBoardRequired
                        </label>
                        <select
                            name="IsConsolidatedLeaderBoardRequired"
                            id="Category"
                            ref={IsConsolidatedLeaderBoardRequiredRef}
                        >
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div className={classes.selectbox}>
                        <label htmlFor="IsReferralPointCount">IsReferralPointCount</label>
                        <select
                            name="IsReferralPointCount"
                            id="Category"
                            ref={IsReferralPointCountRef}
                        >
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div className={classes.selectbox}>
                        <label htmlFor="IsRegistrationPointCount">
                            IsRegistrationPointCount
                        </label>
                        <select
                            name="IsRegistrationPointCount"
                            id="Category"
                            ref={IsRegistrationPointCountRef}
                        >
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div className={classes.selectbox}>
                        <label htmlFor="IsDailyRewardPointCount">
                            IsDailyRewardPointCount
                        </label>
                        <select
                            name="IsDailyRewardPointCount"
                            id="Category"
                            ref={IsDailyRewardPointCountRef}
                        >
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div className={classes.selectbox}>
                        <label htmlFor="IsCaloriesBasedLeaderboard">
                            IsCaloriesBasedLeaderboard
                        </label>
                        <select
                            name="IsCaloriesBasedLeaderboard"
                            id="Category"
                            ref={IsCaloriesBasedLeaderboardRef}
                        >
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="SubscriptionID">Subscription ID : </label>
                        <select
                            name="SubscriptionID"
                            id="SubscriptionID"
                            placeholder="Select One"
                            required
                            onClick={handleSelectClick}
                            style={{ width: "300px" }}
                            onChange={(event) => {
                                SubscriptionIDForUserRef.current.value = event.target.value;
                            }}
                            ref={SubscriptionIDForUserRef}
                        >
                            <option value="">Select SubscriptionID</option>
                            {subscriptionDetails.map((subscription) => (
                                <option key={subscription._id} value={subscription._id}>
                                    {subscription.Name} ({subscription._id})
                                </option>
                            ))}
                        </select>
                        <span style={{ marginLeft: "1rem", marginTop: "1px" }}>
                            {isSubscriptionIDLoading && (
                                <CircularProgress size={25} thickness={5} />
                            )}
                        </span>
                    </div>

                    <div
                        className={classes.selectbox}
                        style={{
                            marginBottom:
                                isSubscribedRef === false ? "2rem"
                                    : 0,
                        }}
                    >
                        <label htmlFor="IsSubscribed">IsSubscribed</label>
                        <select
                            name="IsSubscribed"
                            id="Category"
                            onChange={(e) => {
                                setIsSubscribedRef(e.target.value === "true");
                            }}
                        >
                            <option value={"false"}>No</option>
                            <option value={"true"}>Yes</option>
                        </select>
                    </div>

                    {isSubscribedRef === true && (
                        <>
                            <div className={classes.selectbox}>
                                <label htmlFor="SubscriptionStartsOn">
                                    SubscriptionStartsOn:{" "}
                                </label>
                                <input
                                    id="SubscriptionStartsOn"
                                    type="date"
                                    placeholder="Enter date"
                                    ref={SubscriptionStartsOnRef}
                                />
                            </div>
                            <div className={classes.selectbox}>
                                <label htmlFor="SubscriptionEndsOn">SubscriptionEndsOn: </label>
                                <input
                                    id="SubscriptionEndsOn"
                                    type="date"
                                    placeholder="Enter date"
                                    ref={SubscriptionEndsOnRef}
                                />
                            </div>
                        </>
                    )}
                    {!isLoading && <Button type="submit">Add</Button>}
                    {isLoading && <p color="white">Sending request...</p>}
                </form>
            </Card>
        </div>
    );
};

export default NewCorporateID;
