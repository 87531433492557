import DashboardPageLayout from "../layouts/dashboard_layout";
import { ProtectedRoutes } from "./protected_route";

const StreakRoutes = {
    path: "/sundries",
    element: (
        <ProtectedRoutes>
            <DashboardPageLayout />
        </ProtectedRoutes>
    ),
    children: [
        {
            path: "",
            // element: <AddAnnouncement />,
        }
    ],
};

export default StreakRoutes;
