import React, { useEffect, useState } from "react";
import classes from "../../Data.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AUTH_CONTEXT from "../../../../store";
import Loader from "../../../../components/Loader/Loader";
import Card from "../../../../components/Card/Card";
import Button from "../../../../components/Button/Button";
import { TestimonialsServices } from "../../../../services/dashboard_services";
import { BASE_PATH_TO_HOME, BASE_PATH_TO_TESTIMONIALS_LIST } from "../../../../base_routes_paths/data_paths";
import { Breadcrumbs, Link, Typography } from "@mui/material";

const UpdateTestimonial = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [TestimonialID, setTestimonialID] = useState("");
    const [UserID, setUserID] = useState("");
    const [UserName, setUserName] = useState("");
    const [ProfileImage, setProfileImage] = useState("");
    const [Thumbnail, setThumbnail] = useState("");
    const [VideoURL, setVideoURL] = useState("");
    const [Description, setDescription] = useState("");
    const [IsVerified, setIsVerified] = useState(false);
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoaderLoading, setIsLoaderLoading] = useState(false);
    const { token } = AUTH_CONTEXT();

    const FetchResponse = async () => {
        try {
            let response = await TestimonialsServices.getTestimonialByID(token, navigate, params.ID);
            setIsDataFetched(true);
            setIsLoaderLoading(false);
            toast.success("Data Fetched successfully!!", {
                position: "bottom-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return response.data;
        } catch (e) {
            setIsDataFetched(false);
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    const submitHandler = (event) => {
        event.preventDefault();
        setIsLoading(true);

        const Data = {
            _id: TestimonialID,
            Data: {
                Description,
                IsVerified,
            },
        }

        const FetchApi = async () => {
            try {
                let response = await TestimonialsServices.updateTestimonials(token, navigate, Data);

                setIsLoading(false);
                if (!response.ok) {
                    throw new Error("Authentication failed!");
                } else {
                    response = await response.json();
                }
                if (response.status !== 200) {
                    throw new Error("Somthing went Wrong!");
                }
                navigate(BASE_PATH_TO_TESTIMONIALS_LIST);
                toast.success("Data updated successfully!!", {
                    position: "bottom-left",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } catch (e) {
                setIsLoading(false);
                toast.error(e.message, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        };
        FetchApi();
    };

    useEffect(() => {
        const FetchData = async () => {
            try {
                if (!isDataFetched) {
                    let result = await FetchResponse();
                    setTestimonialID(result[0]._id);
                    setUserID(result[0].UserID);
                    setProfileImage(result[0].ProfileImage);
                    setUserName(result[0].UserName);
                    setIsVerified(result[0].IsVerified);
                    setDescription(result[0].Description);
                    setVideoURL(result[0].VideoURL);
                    setThumbnail(result[0].Thumbnail);
                }
            } catch (error) {
                console.log(error.message);
            }
        };

        if (params.ID) {
            setIsLoaderLoading(true);
            FetchData();
        } else {
            return;
        }
    }, []);

    return (
        <>
            {isLoaderLoading === true ? (
                <Loader />
            ) : (
                <>
                    <div className={classes.crumbs}>
                        <Breadcrumbs aria-label="breadcrumb" >
                            <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                                Dashboard
                            </Link>
                            <Typography color="text.primary">
                                Data
                            </Typography>
                            <Typography color="text.primary">Update Testimonial</Typography>
                        </Breadcrumbs>
                        <Card className={classes.input}>
                            <form onSubmit={submitHandler}>
                                <div className={classes.Main_div}>Update Testimonial</div>
                                <div>
                                    <label htmlFor="Testimonial ID">Testimonial ID :</label>
                                    <input
                                        type="text"
                                        id="Name"
                                        disabled
                                        value={TestimonialID}
                                        onChange={(e) => setTestimonialID(e.target.value)}
                                        placeholder="Enter TestimonialID"
                                    />
                                </div>

                                <div className={classes.control}>
                                    <label htmlFor="User ID">User ID :</label>
                                    <input
                                        type="text"
                                        id="Name"
                                        value={UserID}
                                        disabled
                                        onChange={(e) => setUserID(e.target.value)}
                                        placeholder="Enter User ID "
                                    />
                                </div>

                                <div className={classes.control}>
                                    <label htmlFor="UserName">UserName :</label>
                                    <input
                                        type="text"
                                        id="Name"
                                        value={UserName}
                                        disabled
                                        onChange={(e) => setUserName(e.target.value)}
                                        placeholder="Enter UserName"
                                    />
                                </div>

                                <div className={classes.control}>
                                    <label htmlFor="ProfileImage">ProfileImage :</label>
                                    <input
                                        type="url"
                                        id="ProfileImage"
                                        value={ProfileImage}
                                        disabled
                                        onChange={(e) => setProfileImage(e.target.value)}
                                        placeholder="Enter ProfileImage URL"
                                    />
                                </div>

                                <div className={classes.detailItem}>
                                    <span className={classes.itemKey}>Media_img:</span>
                                    <span className={classes.Media_img}>
                                        <img
                                            style={{ height: "80vh" }}
                                            src={Thumbnail}
                                            alt="Areno"
                                        />
                                    </span>
                                </div>

                                <div className={classes.detailItem}>
                                    <span className={classes.itemKey}>Media_video:</span>
                                    <span className={classes.Media_video}>
                                        <video width="750" height="500" controls>
                                            <source src={VideoURL} alt="Areno" type="video/mp4" />
                                        </video>
                                    </span>
                                </div>

                                <div className={classes.selectbox}>
                                    <label htmlFor="IsVerified">IsVerified : </label>
                                    <select
                                        name="IsVerified"
                                        id="IsVerified"
                                        value={IsVerified}
                                        onChange={(e) => setIsVerified(e.target.value === "true")}
                                    >
                                        <option value="false">No</option>
                                        <option value="true">Yes</option>
                                    </select>
                                </div>

                                <label htmlFor="Description">Description :</label>
                                <textarea
                                    style={{
                                        height: "10rem",
                                        maxWidth: "100%",
                                        minWidth: "100%",
                                        minHeight: "5rem",
                                    }}
                                    id="Description"
                                    type="text"
                                    placeholder="Enter descriptions for Testimonial"
                                    value={Description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />

                                {!isLoading && <Button type="submit">Update</Button>}
                                {isLoading && <p color="white">Sending request...</p>}
                            </form>
                        </Card>
                    </div>
                </>
            )}
        </>
    );
};

export default UpdateTestimonial;
