import { Navigate } from "react-router";
import AUTH_CONTEXT from "../store";
import { BASE_PATH_TO_AUTH } from "../base_routes_paths/auth_paths";

export const ProtectedRoutes = ({ children }) => {
    const { isLoggedIn } = AUTH_CONTEXT();
    if (!isLoggedIn) {
        return <Navigate to={BASE_PATH_TO_AUTH} />;
    }
    return children;
};