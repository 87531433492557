import React, { useState, useEffect } from "react";
import classes from "../../Sundries.module.css";
import {
    DataGridPremium
} from "@mui/x-data-grid-premium";
import { toast } from "react-toastify";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useNavigate } from "react-router-dom";
import AUTH_CONTEXT from "../../../../store";
import Loader from "../../../../components/Loader/Loader";
import CustomToolbar from "../../../../components/toolbar";
import { ReminderImagesServices } from "../../../../services/sundries_services";
import { BASE_PATH_TO_ADD_REMINDER_IMAGES, BASE_PATH_TO_UPDATE_REMINDER_IMAGES } from "../../../../base_routes_paths/sundries_paths";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";

const userColumns = [
    {
        field: "Link",
        headerName: "Link",
        width: 600,
        renderCell: (params) => {
            const link = params.row.Link;
            return (
                <>
                    <ContentCopyIcon
                        sx={{ marginRight: "10px", cursor: "pointer" }}
                        onClick={() => {
                            navigator.clipboard.writeText(link);
                            toast.success("Copied Link!!");
                        }}
                        titleAccess="Copy"
                    />
                    <a
                        href={link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.copyIcon}
                    >
                        {link}
                    </a>
                </>
            );
        },
    }
];

const ReminderImages = () => {
    const [isLoaderLoading, setIsLoaderLoading] = useState(false);
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [data, setData] = useState([]);
    const [id, setID] = useState([]);
    const { token, fileName } = AUTH_CONTEXT();
    const EXPORT_FILENAME = `${fileName}_ReminderImagesList`;
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            setIsLoaderLoading(true);
            try {
                const response = await ReminderImagesServices.getAllReminderImages(token, navigate);
                if (response.status === 207) {
                    toast.error(response.error, {
                        position: "bottom-left",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                    });
                    return;
                }
                setID(response.data._id);
                const result = response.data.ImagesLinks.map((result) => ({
                    id: result._id,
                    ...result,
                }));
                setIsDataFetched(true);
                setData(result);
                toast.success("Data fetched successfully!!", {
                    position: "bottom-left",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } catch (e) {
                toast.error(e.message, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } finally {
                setIsLoaderLoading(false);
            }
        };

        fetchData();
    }, []);

    return (
        <div>
            {isLoaderLoading ? (
                <Loader />
            ) : (
                <>
                    {isDataFetched && (
                        <div className={classes.datatable}>
                            <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 1 }}>
                                <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                                    Dashboard
                                </Link>
                                <Typography color="text.primary">
                                    Sundries
                                </Typography>
                                <Typography color="text.primary">ReminderImagesList</Typography>
                            </Breadcrumbs>
                            <DataGridPremium
                                checkboxSelection
                                rows={data}
                                columns={userColumns}
                                pageSize={20}
                                rowsPerPageOptions={[20]}
                                slots={{
                                    toolbar: () => (
                                        <CustomToolbar
                                            EXPORT_FILENAME={EXPORT_FILENAME}
                                            onClickCreate={() => {
                                                navigate(BASE_PATH_TO_ADD_REMINDER_IMAGES);
                                            }}
                                            displayCreate={"block"}
                                            onClickUpdate={() => {
                                                navigate(`${BASE_PATH_TO_UPDATE_REMINDER_IMAGES}/${id}`);
                                            }}
                                            displayUpdate={""}
                                        />
                                    )
                                }}
                                initialState={{
                                    pagination: {
                                        paginationModel: { pageSize: 25, page: 0 },
                                    },
                                }}
                                pagination
                                disableRowSelectionOnClick
                                checkboxSelectionVisibleOnly
                            />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default ReminderImages;
