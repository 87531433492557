import { useState } from "react";
import { useNavigate } from "react-router-dom";
import classes from "../../Challenge.module.css";
import { toast } from "react-toastify";
import { Breadcrumbs, CircularProgress, Link, Typography } from "@mui/material";
import AUTH_CONTEXT from "../../../../store";
import ARENO_API_ENDPOINT from "../../../../api";
import Card from "../../../../components/Card/Card";
import Button from "../../../../components/Button/Button";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";
import { StatusCode } from "../../../../utils";
import { BASE_PATH_TO_CHALLENGES_LIST } from "../../../../base_routes_paths/challenge_paths";
import { ChallenegeServices } from "../../../../services/challenge_services";

const ChallengeCreationForm = () => {
    const navigate = useNavigate();

    const [WorkoutID, setWorkoutID] = useState("");
    const [Name, setName] = useState("");
    const [Threshold, SetThreshold] = useState("");
    const [JoinByTimeUtc, setJoinByTimeUtc] = useState("");
    const [EndsInTimeUtc, setEndsInTimeUtc] = useState("");
    const [StartsTimeUtc, setStartsTimeUtc] = useState("");
    const [NoOfAttempts, setNoOfAttempts] = useState("");
    const [SetPoint, setSetPoint] = useState("");
    const [TimeDuration, setTimeDuration] = useState("");
    const [ChallengeThumbURL, setChallengeThumbURL] = useState("");
    const [CorporateID, setCorporateID] = useState("");
    const [SmsVideoUrl, setSmsVideoUrl] = useState("");
    const [IsSponsored, setIsSponsored] = useState(true);
    const [DeviceType, setDeviceType] = useState("All");
    const [Description, setDescription] = useState({
        "en-US": "",
        "en-IN": "",
        "en-CA": ""
    });
    const [IsCumulative, setIsCumulative] = useState(true);
    const [subscriberOnly, setSubscriberOnly] = useState(false);
    const [gender, setGender] = useState("All");
    const [isActive, setIsActive] = useState(true);
    const [selectedCategoryID, setSelectedCategoryID] = useState("");
    const [inputSponseredReward, setinputSponseredReward] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isWorkoutIDLoading, setIsWorkoutIDLoading] = useState(false);
    const [isCategoryIDLoading, setIsCategoryIDLoading] = useState(false);
    const { token } = AUTH_CONTEXT();

    const [WorkoutIDAPI, setWorkoutIDAPI] = useState([]);
    const [CategoryIDAPI, setCategoryIDAPI] = useState([]);

    const fetchWorkoutIDDetails = async () => {
        setIsWorkoutIDLoading(true)
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/challenges/GetWorkoutID`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );
            StatusCode(response, navigate);
            response = await response.json();
            setWorkoutIDAPI(response.data);
            setIsWorkoutIDLoading(false)
        } catch (e) {
            setIsWorkoutIDLoading(false)
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    const handleWorkoutIDSelectClick = () => {
        if (WorkoutIDAPI.length === 0) {
            fetchWorkoutIDDetails();
        }
    };

    const fetchCategoryIDDetails = async () => {
        setIsCategoryIDLoading(true)
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/challenges/GetChallengeCategory`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );
            StatusCode(response, navigate);
            response = await response.json();
            setCategoryIDAPI(response.data);
            setIsCategoryIDLoading(false)
        } catch (e) {
            setIsCategoryIDLoading(false)
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    const handleCategoryIDSelectClick = () => {
        if (CategoryIDAPI.length === 0) {
            fetchCategoryIDDetails();
        }
    };

    const submitHandler = (event) => {
        event.preventDefault();
        setIsLoading(true);

        const FetchApi = async () => {
            try {
                let NewCorporateID = CorporateID.toUpperCase();

                const Data = {
                    WorkoutID: WorkoutID,
                    Name: Name,
                    Threshold: +Threshold,
                    JoinByTimeUtc: JoinByTimeUtc,
                    EndsInTimeUtc: EndsInTimeUtc,
                    StartsTimeUtc: StartsTimeUtc,
                    NoOfAttempts: +NoOfAttempts,
                    SetPoint: +SetPoint,
                    TimeDuration: +TimeDuration,
                    ChallengeThumbURL: ChallengeThumbURL,
                    CorporateID: NewCorporateID,
                    SmsVideoUrl: SmsVideoUrl,
                    IsSponsored: IsSponsored,
                    SponsoredReward: inputSponseredReward,
                    IsCumulative: IsCumulative,
                    CategoryID: selectedCategoryID,
                    Gender: gender,
                    SubscriberOnly: subscriberOnly,
                    IsActive: isActive,
                    Description: Description,
                    DeviceType: DeviceType
                }

                let response = await ChallenegeServices.addChallenges(token, navigate, Data);

                setIsLoading(true);
                if (response.status === 207) {
                    return toast
                        .error(response.error, {
                            position: "bottom-left",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                        })
                }
                if (response.data.WorkoutID === 1) {
                    localStorage.setItem("Push-ups", JSON.stringify(response.data));
                } else if (response.data.WorkoutID === 2) {
                    localStorage.setItem("Planks", JSON.stringify(response.data));
                } else if (response.data.WorkoutID === 3) {
                    localStorage.setItem("Squats", JSON.stringify(response.data));
                } else if (response.data.WorkoutID === 4) {
                    localStorage.setItem("High Knees", JSON.stringify(response.data));
                } else if (response.data.WorkoutID === 5) {
                    localStorage.setItem("Lunges", JSON.stringify(response.data));
                } else if (response.data.WorkoutID === 6) {
                    localStorage.setItem("Situps", JSON.stringify(response.data));
                } else if (response.data.WorkoutID === 7) {
                    localStorage.setItem("Knee Pushups", JSON.stringify(response.data));
                } else if (response.data.WorkoutID === 8) {
                    localStorage.setItem("Jumping Jacks", JSON.stringify(response.data));
                }
                navigate(BASE_PATH_TO_CHALLENGES_LIST);
                toast.success("Data added successfully!!", {
                    position: "bottom-left",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } catch (e) {
                setIsLoading(false);
                toast.error(e.message, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        };
        FetchApi();
    };

    const handleFormChangeWorkoutID = (event) => {
        event.preventDefault();
        setWorkoutID(+event.target.value);
        let ChallengePrevData;

        if (event.target.value === "1") {
            ChallengePrevData = JSON.parse(localStorage.getItem("Push-ups"));
        } else if (event.target.value === "2") {
            ChallengePrevData = JSON.parse(localStorage.getItem("Planks"));
        } else if (event.target.value === "3") {
            ChallengePrevData = JSON.parse(localStorage.getItem("Squats"));
        } else if (event.target.value === "4") {
            ChallengePrevData = JSON.parse(localStorage.getItem("High Knees"));
        } else if (event.target.value === "5") {
            ChallengePrevData = JSON.parse(localStorage.getItem("Lunges"));
        } else if (event.target.value === "6") {
            ChallengePrevData = JSON.parse(localStorage.getItem("Situps"));
        } else if (event.target.value === "7") {
            ChallengePrevData = JSON.parse(localStorage.getItem("Knee Pushups"));
        } else if (event.target.value === "8") {
            ChallengePrevData = JSON.parse(localStorage.getItem("Jumping Jacks"));
        }

        if (ChallengePrevData !== null) {
            setName(ChallengePrevData.Name);
            SetThreshold(ChallengePrevData.Threshold);
            setJoinByTimeUtc(new Date(ChallengePrevData.JoinByTimeUtc).toISOString().slice(0, 16));
            setEndsInTimeUtc(new Date(ChallengePrevData.EndsInTimeUtc).toISOString().slice(0, 16));
            setStartsTimeUtc(new Date(ChallengePrevData.StartsTimeUtc).toISOString().slice(0, 16));
            setNoOfAttempts(ChallengePrevData.NoOfAttempts);
            setSetPoint(ChallengePrevData.SetPoint);
            setTimeDuration(ChallengePrevData.TimeDuration);
            setChallengeThumbURL(ChallengePrevData.ChallengeThumbURL);
            setCorporateID(ChallengePrevData.CorporateID);
            setSmsVideoUrl(ChallengePrevData.SmsVideoUrl);
            setIsSponsored(ChallengePrevData.IsSponsored);
            setinputSponseredReward(ChallengePrevData.SponsoredReward);
            setIsCumulative(ChallengePrevData.IsCumulative);
            setSubscriberOnly(ChallengePrevData.SubscriberOnly);
            setIsActive(ChallengePrevData.IsActive)
            setDescription(ChallengePrevData.Description)
        } else {
            setName("");
            SetThreshold("");
            setJoinByTimeUtc("");
            setEndsInTimeUtc("");
            setStartsTimeUtc("");
            setNoOfAttempts("");
            setSetPoint("");
            setTimeDuration("");
            setChallengeThumbURL("");
            setCorporateID("");
            setSmsVideoUrl("");
            setIsSponsored(false);
            setinputSponseredReward([{ Ranks: "", Rewards: "" }]);
            setIsCumulative(false);
            setSubscriberOnly(false);
            setSelectedCategoryID();
            setDescription({
                "en-US": "",
                "en-IN": "",
                "en-CA": ""
            });
        }
    };

    const handleFormChangeCategoryID = (e) => {
        const selectedValue = parseInt(e.target.value);
        setSelectedCategoryID(selectedValue);
    };

    const handleFormChangeRewards = (index, event) => {
        let data = [...inputSponseredReward];
        data[index][event.target.name] = event.target.value;
        setinputSponseredReward(data);
    };

    const addFieldsRewards = (event) => {
        event.preventDefault();
        let newfield = { Ranks: "", Rewards: "" };
        setinputSponseredReward([...inputSponseredReward, newfield]);
    };

    const removeFieldsRewards = (index) => {
        let data = [...inputSponseredReward];
        data.splice(index, 1);
        setinputSponseredReward(data);
    };

    const handleDescriptionChange = (e, locale) => {
        setDescription(prevState => ({
            ...prevState,
            [locale]: e.target.value
        }));
    };

    return (
        <div className={classes.crumbs}>
            <Breadcrumbs aria-label="breadcrumb" >
                <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                    Dashboard
                </Link>
                <Typography color="text.primary">
                    Challenege
                </Typography>
                <Typography color="text.primary">Create New Challenge</Typography>
            </Breadcrumbs>
            <Card className={classes.input}>
                <div className={classes.Main_div}>Create New Challenge</div>
                <form onSubmit={submitHandler}>
                    <div className={classes.selectbox} style={{ alignItems: "center" }}>
                        <label htmlFor="WorkoutID">Workout ID : </label>
                        <select
                            name="WorkoutID"
                            id="WorkoutID"
                            required
                            onClick={handleWorkoutIDSelectClick}
                            style={{ width: "300px" }}
                            onChange={(e) => handleFormChangeWorkoutID(e)}
                            value={WorkoutID}
                        >
                            <option value="">Select WorkoutID</option>
                            {WorkoutIDAPI.map((Workout) => (
                                <option key={Workout.WorkoutID} value={Workout.WorkoutID}>
                                    {Workout.WorkoutID}.{Workout.WorkoutName}
                                </option>
                            ))}
                        </select>
                        <span style={{ marginLeft: "1rem", marginTop: "1px" }}>
                            {isWorkoutIDLoading && (
                                <CircularProgress size={25} thickness={5} />
                            )}
                        </span>
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="CategoryID">Category ID : </label>
                        <select
                            name="CategoryID"
                            id="CategoryID"
                            required
                            onClick={handleCategoryIDSelectClick}
                            style={{ width: "300px" }}
                            onChange={(e) => handleFormChangeCategoryID(e)}
                            value={selectedCategoryID}
                        >
                            <option value="">Select CategoryID</option>
                            {CategoryIDAPI.map((Category) => (
                                <option key={Category.CategoryID} value={Category.CategoryID}>
                                    {Category.CategoryID}.{Category.CategoryName}
                                </option>
                            ))}
                        </select>
                        <span style={{ marginLeft: "1rem", marginTop: "1px" }}>
                            {isCategoryIDLoading && (
                                <CircularProgress size={25} thickness={5} />
                            )}
                        </span>
                    </div>

                    <div className={classes.control}>
                        <label htmlFor="Name">Name :</label>
                        <input
                            type="text"
                            id="Name"
                            required
                            value={Name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Enter name of challenge"
                        />
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="Threshold">Threshold :</label>
                        <input
                            type="number"
                            id="Threshold"
                            required
                            value={Threshold}
                            onChange={(e) => SetThreshold(e.target.value)}
                            placeholder="Enter threshold for challenge"
                        />
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="StartsTimeUtc">StartsTimeUtc : </label>
                        <input
                            id="StartsTimeUtc"
                            type="datetime-local"
                            value={StartsTimeUtc}
                            required
                            onChange={(e) => setStartsTimeUtc(e.target.value)}
                        />
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="JoinByTimeUtc">JoinByTimeUtc : </label>
                        <input
                            id="JoinByTimeUtc"
                            type="datetime-local"
                            value={JoinByTimeUtc}
                            required
                            onChange={(e) => setJoinByTimeUtc(e.target.value)}
                        />
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="EndsInTimeUtc">EndsInTimeUtc : </label>
                        <input
                            id="EndsInTimeUtc"
                            type="datetime-local"
                            value={EndsInTimeUtc}
                            required
                            onChange={(e) => setEndsInTimeUtc(e.target.value)}
                        />
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="NoOfAttempts">No Of Attempts : </label>
                        <input
                            required
                            id="NoOfAttempts"
                            type="number"
                            placeholder="Enter maximum number of attempts"
                            value={NoOfAttempts}
                            onChange={(e) => setNoOfAttempts(e.target.value)}
                        />
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="setPoint">Set Point : </label>
                        <input
                            required
                            id="setPoint"
                            type="number"
                            placeholder="Enter joining points"
                            value={SetPoint}
                            onChange={(e) => setSetPoint(e.target.value)}
                        />
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="TimeDuration">Time Duration : </label>
                        <input
                            required
                            id="TimeDuration"
                            type="number"
                            placeholder="Enter time duration (in sec)"
                            value={TimeDuration}
                            onChange={(e) => setTimeDuration(e.target.value)}
                        />
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="CorporateID">CorporateID : </label>
                        <input
                            id="CorporateID"
                            type="text"
                            placeholder="Corporate ID"
                            value={CorporateID}
                            style={{ textTransform: "uppercase" }}
                            onChange={(e) => setCorporateID(e.target.value)}
                        />
                    </div>

                    <div className={classes.control}>
                        <label htmlFor="ChallengeThumbURL">Challenge Thumb URL : </label>
                        <input
                            id="ChallengeThumbURL"
                            type="text"
                            placeholder="Enter ChallengeThumbURL"
                            value={ChallengeThumbURL}
                            onChange={(e) => setChallengeThumbURL(e.target.value)}
                        />
                    </div>

                    <div className={classes.control}>
                        <label htmlFor="SmsVideoUrl">Sms Video Url : </label>
                        <input
                            id="SmsVideoUrl"
                            type="text"
                            placeholder="Enter SmsVideoUrl"
                            value={SmsVideoUrl}
                            onChange={(e) => setSmsVideoUrl(e.target.value)}
                        />
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="Gender">Gender : </label>
                        <select
                            name="Gender"
                            id="Gender"
                            value={gender}
                            onChange={(e) => setGender(e.target.value)}
                        >
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="All">All</option>
                        </select>
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="IsSponsored">Is Sponsored: </label>
                        <select
                            name="IsSponsored"
                            id="IsSponsored"
                            value={IsSponsored.toString()}
                            onChange={(e) => {
                                const value = e.target.value === 'true';
                                setIsSponsored(value);
                                if (!value) {
                                    setinputSponseredReward([]);
                                } else {
                                    setinputSponseredReward([{ Ranks: "", Rewards: "" }]);
                                }
                            }}
                        >
                            <option value="false">False</option>
                            <option value="true">True</option>
                        </select>
                    </div>

                    {IsSponsored && (
                        <div className={classes.subfields}>
                            <h3>Sponsored Rewards</h3>
                            {inputSponseredReward.map((input, index) => (
                                <div key={index} className={classes.subfieldsControl}>
                                    <div className={classes.control}>
                                        <label htmlFor="Ranks">Rank</label>
                                        <input
                                            type="text"
                                            name="Ranks"
                                            placeholder="Enter rank"
                                            value={input.Ranks}
                                            onChange={(event) =>
                                                handleFormChangeRewards(index, event)
                                            }
                                        />
                                    </div>
                                    <div className={classes.control}>
                                        <label htmlFor="Rewards">Rewards</label>
                                        <input
                                            type="text"
                                            name="Rewards"
                                            placeholder="Enter Rewards"
                                            value={input.Rewards}
                                            onChange={(event) =>
                                                handleFormChangeRewards(index, event)
                                            }
                                        />
                                    </div>
                                    <button
                                        onClick={(event) => {
                                            event.preventDefault();
                                            removeFieldsRewards(index);
                                        }}
                                    >
                                        Remove
                                    </button>
                                </div>
                            ))}
                            <button onClick={addFieldsRewards}>Add More...</button>
                        </div>
                    )}

                    <div className={classes.selectbox}>
                        <label htmlFor="SubscriberOnly">SubscriberOnly : </label>
                        <select
                            name="SubscriberOnly"
                            id="SubscriberOnly"
                            value={subscriberOnly}
                            onChange={(e) => setSubscriberOnly(e.target.value)}
                        >
                            <option value={false}>False</option>
                            <option value={true}>True</option>
                        </select>
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="IsCumulative">IsCumulative : </label>
                        <select
                            name="IsCumulative"
                            id="IsCumulative"
                            value={IsCumulative}
                            onChange={(e) => setIsCumulative(e.target.value)}
                        >
                            <option value={false}>False</option>
                            <option value={true}>True</option>
                        </select>
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="IsActive">IsActive : </label>
                        <select
                            name="IsActive"
                            id="IsActive"
                            value={isActive}
                            onChange={(e) => setIsActive(e.target.value)}
                        >
                            <option value={false}>False</option>
                            <option value={true}>True</option>
                        </select>
                    </div>

                    <div className={classes.selectbox} >
                        <label htmlFor="DeviceType">DeviceType : </label>
                        <select
                            name="DeviceType"
                            id="DeviceType"
                            value={DeviceType}
                            onChange={(e) => setDeviceType(e.target.value)}
                        >
                            <option value="All">All</option>
                            <option value="IOS">IOS</option>
                            <option value="Android">Android</option>
                        </select>
                    </div>

                    <div className={classes.subfields}>
                        <h3>Descriptions</h3>
                        <div className={classes.subfields}>
                            <div className={classes.control}>
                                <label htmlFor="en-US">Description (en-US)</label>
                                <input
                                    type="text"
                                    id="en-US"
                                    value={Description["en-US"]}
                                    onChange={(e) => handleDescriptionChange(e, "en-US")}
                                    placeholder="Enter Description for en-US"
                                />
                            </div>
                            <div className={classes.control}>
                                <label htmlFor="en-IN">Description (en-IN)</label>
                                <input
                                    type="text"
                                    id="en-IN"
                                    value={Description["en-IN"]}
                                    onChange={(e) => handleDescriptionChange(e, "en-IN")}
                                    placeholder="Enter Description for en-IN"
                                />
                            </div>
                            <div className={classes.control}>
                                <label htmlFor="en-CA">Description (en-CA)</label>
                                <input
                                    type="text"
                                    id="en-CA"
                                    value={Description["en-CA"]}
                                    onChange={(e) => handleDescriptionChange(e, "en-CA")}
                                    placeholder="Enter Description for en-CA"
                                />
                            </div>
                        </div>
                    </div>

                    {!isLoading && <Button type="submit">Add</Button>}
                    {isLoading && <p color="white">Sending request...</p>}
                </form>
            </Card>
        </div>
    );
};

export default ChallengeCreationForm;
