import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import classes from "../../Point.module.css";
import { toast } from "react-toastify";
import AUTH_CONTEXT from "../../../../store";
import Card from "../../../../components/Card/Card";
import Button from "../../../../components/Button/Button";
import { PointServices } from "../../../../services/point_services";
import { BASE_PATH_TO_POINT_CONFIG_LIST } from "../../../../base_routes_paths/points_paths";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";

const AddPointConfig = () => {
    const navigate = useNavigate();

    const DefaultPointRef = useRef();
    const IsPromotionalRef = useRef();
    const IsDatePromotionalRef = useRef();
    const IsNoOfPPLPromotionalRef = useRef();
    const PromotionEndDateRef = useRef();
    const PromotionStartDateRef = useRef();
    const NoofPPLRef = useRef();
    const PromotionalPointRef = useRef();
    const PriorityRef = useRef();

    const { token } = AUTH_CONTEXT();
    const [isLoading, setIsLoading] = useState(false);

    const SubmitHandler = (event) => {
        event.preventDefault();
        let EnteredDefaultPoint = DefaultPointRef.current.value;
        let EnteredIsPromotional = IsPromotionalRef.current.value;
        let EnteredIsDatePromotional = IsDatePromotionalRef.current.value;
        let EnteredIsNoOfPPLPromotional = IsNoOfPPLPromotionalRef.current.value;
        let EnteredPromotionEndDate = PromotionEndDateRef.current.value;
        let EnteredPromotionStartDate = PromotionStartDateRef.current.value;
        let EnteredNoofPPL = NoofPPLRef.current.value;
        let EnteredPromotionalPoint = PromotionalPointRef.current.value;
        let EnteredPriority = PriorityRef.current.value;
        setIsLoading(true);

        const FetchResponseSetReferrelConfig = async () => {
            try {
                let response = await PointServices.addPointConfig(token, navigate, EnteredDefaultPoint, EnteredIsPromotional, EnteredIsDatePromotional, EnteredIsNoOfPPLPromotional, EnteredPromotionEndDate, EnteredPromotionStartDate, EnteredNoofPPL, EnteredPromotionalPoint, EnteredPriority);
                if (response.status === 207) {
                    return toast
                        .error(response.error, {
                            position: "bottom-left",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                        })
                }
                setIsLoading(false);
                navigate(BASE_PATH_TO_POINT_CONFIG_LIST);
            } catch (e) {
                setIsLoading(false);
                console.log(e.message);
                alert(e.message);
            }
        };
        FetchResponseSetReferrelConfig();
    };

    return (
        <div className={classes.crumbs}>
            <Breadcrumbs aria-label="breadcrumb" >
                <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                    Dashboard
                </Link>
                <Typography color="text.primary">
                    Points
                </Typography>
                <Typography color="text.primary">Add Point Configration</Typography>
            </Breadcrumbs>
            <Card className={classes.input}>
                <div className={classes.Main_div}>Add Point Configration</div>
                <form onSubmit={SubmitHandler}>
                    <div className={classes.control}>
                        <label htmlFor="DefaultPoint">Default point</label>
                        <input
                            type="number"
                            id="DefaultPoint"
                            required
                            ref={DefaultPointRef}
                            placeholder="Enter default points"
                        />
                    </div>
                    <div className={classes.selectbox} style={{ marginBottom: "2rem" }}>
                        <label htmlFor="IsPromotional">IsPromotional</label>
                        <select
                            name="IsPromotional"
                            id="IsPromotional"
                            ref={IsPromotionalRef}
                            required
                        >
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div className={classes.selectbox} style={{ marginBottom: "2rem" }}>
                        <label htmlFor="IsDatePromotional">IsDatePromotional</label>
                        <select
                            name="IsDatePromotional"
                            id="IsDatePromotional"
                            ref={IsDatePromotionalRef}
                            required
                        >
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div className={classes.selectbox} style={{ marginBottom: "2rem" }}>
                        <label htmlFor="IsNoOfPPLPromotional">IsNoOfPPLPromotional</label>
                        <select
                            name="IsNoOfPPLPromotional"
                            id="IsNoOfPPLPromotional"
                            ref={IsNoOfPPLPromotionalRef}
                            required
                        >
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div className={classes.selectbox}>
                        <label htmlFor="PromotionEndDate">PromotionEndDate : </label>
                        <input
                            id="PromotionEndDate"
                            type="date"
                            placeholder="Enter ending date"
                            ref={PromotionEndDateRef}
                        />
                    </div>
                    <div className={classes.selectbox}>
                        <label htmlFor="PromotionStartDate">PromotionStartDate : </label>
                        <input
                            id="PromotionStartDate"
                            type="date"
                            placeholder="Enter start date"
                            ref={PromotionStartDateRef}
                        />
                    </div>
                    <div className={classes.control}>
                        <label htmlFor="NoofPPL">Number of People</label>
                        <input
                            type="number"
                            id="NoofPPL"
                            required
                            ref={NoofPPLRef}
                            placeholder="Enter Number of people"
                        />
                    </div>
                    <div className={classes.control}>
                        <label htmlFor="PromotionalPoint">Promotional point</label>
                        <input
                            type="number"
                            id="PromotionalPoint"
                            required
                            ref={PromotionalPointRef}
                            placeholder="Enter the point for promotional"
                        />
                    </div>
                    <div className={classes.control}>
                        <label htmlFor="Priority">Priority</label>
                        <input
                            type="number"
                            id="Priority"
                            required
                            ref={PriorityRef}
                            placeholder="Enter Priority"
                        />
                    </div>
                    {!isLoading && <Button type="submit">Add</Button>}
                    {isLoading && <p color="white">Sending request...</p>}
                </form>
            </Card>
        </div>
    );
};

export default AddPointConfig;
