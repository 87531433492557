import { useEffect, useState } from "react";
import classes from "../../Challenge.module.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import AUTH_CONTEXT from "../../../../store";
import Card from "../../../../components/Card/Card";
import Button from "../../../../components/Button/Button";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";
import { RoutineActivitiesServices, RoutinePlansServices } from "../../../../services/challenge_services";
import { BASE_PATH_TO_ROUTINE_PLANS_LIST } from "../../../../base_routes_paths/challenge_paths";

const AddRoutinePlans = () => {
    const navigate = useNavigate();
    const { token } = AUTH_CONTEXT();
    const [isLoading, setIsLoading] = useState(false);
    const [ObjectIDAPI, setObjectIDAPI] = useState([]);
    const [routinePlan, setRoutinePlan] = useState({
        RoutinePlanName: {
            "en-US": '',
            "en-IN": '',
            "en-CA": ''
        },
        RoutinePlanTaxonomy: {
            ActivityStatus: 1,
            Purpose: '',
            BodyPart: '',
            Duration: ''
        },
        RoutineBodyTargetArea: {
            "en-US": '',
            "en-IN": '',
            "en-CA": ''
        },
        IsActive: true,
        IsPaid: true,
        SortID: 0,
        RoutinePlanImageUrl: '',
        Description: {
            "en-US": '',
            "en-IN": '',
            "en-CA": ''
        },
        RoutineActivityPlans: [
            {
                SortID: 0,
                PlanActivityObjectID: "",
                RoutineActivitySecs: 0,
                RoutineActivityReps: 0
            }
        ]
    });


    const fetchObjectIDAPIDetails = async () => {
        try {
            let response = await RoutineActivitiesServices.getAllRoutineActivity(token, navigate);
            if (response.status === 207) {
                toast.error(response.error, {
                    position: "bottom-left",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                });
                return;
            }
            toast.success("Data fetched successfully!!", {
                position: "bottom-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setObjectIDAPI(response.data);
        } catch (e) {
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    const handleObjectIDAPISelectClick = () => {
        if (ObjectIDAPI.length === 0) {
            fetchObjectIDAPIDetails();
        }
    };


    useEffect(() => {
        handleObjectIDAPISelectClick();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let response = await RoutinePlansServices.addRoutinePlan(token, navigate, routinePlan);
            if (response.status === 207) {
                return toast.error(response.error, {
                    position: "bottom-left",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                });
            }
            navigate(BASE_PATH_TO_ROUTINE_PLANS_LIST);
            toast.success("Data added successfully!!", {
                position: "bottom-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } catch (e) {
            setIsLoading(false);
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    const handleInputChange = (event, field, locale = '') => {
        const { name, value } = event.target;
        setRoutinePlan(prevState => {
            if (locale) {
                return {
                    ...prevState,
                    [field]: {
                        ...prevState[field],
                        [locale]: value
                    }
                };
            } else {
                return {
                    ...prevState,
                    [name]: value
                };
            }
        });
    };

    const handlePlanInputChange = (e, index, field) => {
        const { value } = e.target;
        setRoutinePlan(prevState => {
            const updatedPlans = [...prevState.RoutineActivityPlans];
            updatedPlans[index][field] = value;
            return {
                ...prevState,
                RoutineActivityPlans: updatedPlans
            };
        });
    };

    const handleAddPlan = () => {
        setRoutinePlan(prevState => ({
            ...prevState,
            RoutineActivityPlans: [
                ...prevState.RoutineActivityPlans,
                {
                    SortID: 0,
                    PlanActivityObjectID: "",
                    RoutineActivitySecs: 0,
                    RoutineActivityReps: 0
                }
            ]
        }));
    };

    const handleRemovePlan = (index) => {
        setRoutinePlan(prevState => {
            const updatedPlans = [...prevState.RoutineActivityPlans];
            updatedPlans.splice(index, 1);
            return {
                ...prevState,
                RoutineActivityPlans: updatedPlans
            };
        });
    };

    const handleSortIDChange = (e) => {
        const { value } = e.target;
        setRoutinePlan(prevState => ({
            ...prevState,
            SortID: parseInt(value)
        }));
    };

    const handleImageUrlChange = (e) => {
        const { value } = e.target;
        setRoutinePlan(prevState => ({
            ...prevState,
            RoutinePlanImageUrl: value
        }));
    };

    return (
        <div>
            <div className={classes.crumbs}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                        Dashboard
                    </Link>
                    <Typography color="text.primary">Challenge</Typography>
                    <Typography color="text.primary">Create Routine Plan</Typography>
                </Breadcrumbs>
            </div>
            <Card className={classes.input}>
                <div className={classes.Main_div}>Create Routine Plan</div>
                <form onSubmit={handleSubmit}>
                    {/* RoutinePlanName inputs */}
                    <div className={classes.subfields}>
                        <h3>Routine Plan Name</h3>
                        {Object.keys(routinePlan.RoutinePlanName).map(locale => (
                            <div className={classes.control} key={locale}>
                                <label htmlFor={`RoutinePlanName-${locale}`}>Name ({locale})</label>
                                <input
                                    type="text"
                                    id={`RoutinePlanName-${locale}`}
                                    value={routinePlan.RoutinePlanName[locale]}
                                    onChange={(e) => handleInputChange(e, 'RoutinePlanName', locale)}
                                    placeholder={`Enter name for ${locale}`}
                                />
                            </div>
                        ))}
                    </div>
                    {/* RoutinePlanTaxonomy inputs */}
                    <div className={classes.subfields}>
                        <h3>Routine Plan Taxonomy</h3>
                        <div className={classes.selectbox}>
                            <label htmlFor="ActivityStatus">Activity Status:</label>
                            <input
                                type="number"
                                id="ActivityStatus"
                                value={routinePlan.RoutinePlanTaxonomy.ActivityStatus}
                                onChange={(e) => handleInputChange(e, 'RoutinePlanTaxonomy', 'ActivityStatus')}
                                placeholder="Enter Activity Status"
                            />
                        </div>
                        <div className={classes.selectbox}>
                            <label htmlFor="Purpose">Purpose:</label>
                            <input
                                type="text"
                                id="Purpose"
                                value={routinePlan.RoutinePlanTaxonomy.Purpose}
                                onChange={(e) => handleInputChange(e, 'RoutinePlanTaxonomy', 'Purpose')}
                                placeholder="Enter Purpose"
                            />
                        </div>
                        <div className={classes.selectbox}>
                            <label htmlFor="BodyPart">Body Part:</label>
                            <input
                                type="text"
                                id="BodyPart"
                                value={routinePlan.RoutinePlanTaxonomy.BodyPart}
                                onChange={(e) => handleInputChange(e, 'RoutinePlanTaxonomy', 'BodyPart')}
                                placeholder="Enter Body Part"
                            />
                        </div>
                        <div className={classes.selectbox}>
                            <label htmlFor="Duration">Duration:</label>
                            <input
                                type="text"
                                id="Duration"
                                value={routinePlan.RoutinePlanTaxonomy.Duration}
                                onChange={(e) => handleInputChange(e, 'RoutinePlanTaxonomy', 'Duration')}
                                placeholder="Enter Duration"
                            />
                        </div>
                    </div>
                    {/* IsActive input */}
                    <div className={classes.selectbox}>
                        <label htmlFor="IsActive">Is Active:</label>
                        <select
                            name="IsActive"
                            id="IsActive"
                            required
                            value={routinePlan.IsActive}
                            onChange={(e) => setRoutinePlan(prevState => ({
                                ...prevState,
                                IsActive: e.target.value
                            }))}
                        >
                            <option value={false}>False</option>
                            <option value={true}>True</option>
                        </select>
                    </div>
                    {/* IsPaid input */}
                    <div className={classes.selectbox}>
                        <label htmlFor="IsPaid">Is Paid:</label>
                        <select
                            name="IsPaid"
                            id="IsPaid"
                            required
                            value={routinePlan.IsPaid}
                            onChange={(e) => setRoutinePlan(prevState => ({
                                ...prevState,
                                IsPaid: e.target.value
                            }))}
                        >
                            <option value={false}>False</option>
                            <option value={true}>True</option>
                        </select>
                    </div>
                    <div className={classes.selectbox}>
                        <label htmlFor="SortID">Sort ID:</label>
                        <input
                            type="number"
                            id="SortID"
                            required
                            value={routinePlan.SortID}
                            onChange={handleSortIDChange}
                            placeholder="Enter Sort ID"
                        />
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="RoutinePlanImageUrl">Image URL:</label>
                        <input
                            type="text"
                            id="RoutinePlanImageUrl"
                            value={routinePlan.RoutinePlanImageUrl}
                            onChange={handleImageUrlChange}
                            placeholder="Enter Image URL"
                        />
                    </div>
                    {/* Description inputs */}
                    <div className={classes.subfields}>
                        <h3>Description</h3>
                        {Object.keys(routinePlan.Description).map(locale => (
                            <div className={classes.control} key={locale}>
                                <label htmlFor={`Description-${locale}`}>Description ({locale})</label>
                                <input
                                    type="text"
                                    id={`Description-${locale}`}
                                    value={routinePlan.Description[locale]}
                                    onChange={(e) => handleInputChange(e, 'Description', locale)}
                                    placeholder={`Enter description for ${locale}`}
                                />
                            </div>
                        ))}
                    </div>
                    {/* RoutineBodyTargetArea inputs */}
                    <div className={classes.subfields}>
                        <h3>Routine Body Target Area</h3>
                        {Object.keys(routinePlan.RoutineBodyTargetArea).map(locale => (
                            <div className={classes.control} key={locale}>
                                <label htmlFor={`RoutineBodyTargetArea-${locale}`}>Target Area ({locale})</label>
                                <input
                                    type="text"
                                    id={`RoutineBodyTargetArea-${locale}`}
                                    value={routinePlan.RoutineBodyTargetArea[locale]}
                                    onChange={(e) => handleInputChange(e, 'RoutineBodyTargetArea', locale)}
                                    placeholder={`Enter target area for ${locale}`}
                                />
                            </div>
                        ))}
                    </div>

                    <div className={classes.subfields}>
                        <h3>Routine Activity Plans</h3>
                        {routinePlan.RoutineActivityPlans.map((plan, index) => (
                            <div className={classes.subfields}>
                                <div key={index}>
                                    <div className={classes.control}>
                                        <label htmlFor={`SortID-${index}`}>Sort ID:</label>
                                        <input
                                            type="number"
                                            id={`SortID-${index}`}
                                            value={plan.SortID}
                                            onChange={(e) => handlePlanInputChange(e, index, 'SortID')}
                                            placeholder="Enter Sort ID"
                                        />
                                    </div>
                                    <div className={classes.selectbox} style={{ alignItems: "center" }}>
                                        <label htmlFor={`PlanActivityObjectID-${index}`}>Activity Object ID:</label>
                                        <select
                                            name={`PlanActivityObjectID-${index}`}
                                            id={`PlanActivityObjectID-${index}`}
                                            required
                                            style={{ width: "300px" }}
                                            onChange={(e) => handlePlanInputChange(e, index, 'PlanActivityObjectID')}
                                            value={plan.PlanActivityObjectID}
                                        >
                                            <option value="">Select ObjectID</option>
                                            {ObjectIDAPI.map((Object) => (
                                                <option key={Object._id} value={Object._id}>
                                                    {Object.RoutineActivityID}.{Object.RoutineActivityName["en-IN"]}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className={classes.control}>
                                        <label htmlFor={`RoutineActivitySecs-${index}`}>Activity In Seconds:</label>
                                        <input
                                            type="number"
                                            id={`RoutineActivitySecs-${index}`}
                                            value={plan.RoutineActivitySecs}
                                            onChange={(e) => handlePlanInputChange(e, index, 'RoutineActivitySecs')}
                                            placeholder="Enter Activity Seconds"
                                        />
                                    </div>
                                    <div className={classes.control}>
                                        <label htmlFor={`RoutineActivityReps-${index}`}>Activity In Reps:</label>
                                        <input
                                            type="number"
                                            id={`RoutineActivityReps-${index}`}
                                            value={plan.RoutineActivityReps}
                                            onChange={(e) => handlePlanInputChange(e, index, 'RoutineActivityReps')}
                                            placeholder="Enter Activity Reps"
                                        />
                                    </div>
                                    <button type="button" onClick={() => handleRemovePlan(index)}>Remove</button>
                                </div>
                            </div>
                        ))}
                        {/* Add more button */}
                        <button type="button" onClick={handleAddPlan}>Add More</button>
                    </div>
                    {!isLoading && <Button type="submit">Add</Button>}
                    {isLoading && <p>Sending request...</p>}
                </form>
            </Card>
        </div>
    );
};

export default AddRoutinePlans;

